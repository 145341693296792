import styled from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 0.5rem 1rem;
  width: 100%;
  color: var(--purple02);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & + div {
    margin-top: 0.75rem;
  }

  input {
    background: transparent;
    border: 0;
    color: var(--purple02);
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-color: var(--purple02);

    &:checked {
      background-color: var(--purple02);
      color: var(--purple02);
    }
  }

  svg {
    margin-right: 1.5rem;
  }
`;

export const AlertError = styled(Tooltip)`
  height: 2rem;
  margin-left: 1.5rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--error);
    color: #fff;

    &::before {
      border-color: var(--error) transparent;
    }
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
