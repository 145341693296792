/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-bind */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import ImagePicker from '../../components/ImagePicker';
import Input from '../../components/Input';
import { IAdmin, ISettings } from '../../types';
import { uploadPhotoAdmin } from '../../utils/uploadPhotoAdmin';

import {
  Container,
  Content,
  // ImagePicker
} from './styles';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import Chips from '../../components/Chips';
import getValidationError from '../../utils/getValidationError';

function AtleticaProfile() {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { admin, updateAdmin } = useAuth();
  const [profileTemp, setProfileTemp] = useState({} as IAdmin);
  const [image, setImage] = useState('');

  useEffect(() => {
    if (admin) {
      setImage(admin.avatar);
      setProfileTemp(admin);
    }
  }, [admin]);

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setImage(response);
        return response;
      }
      return '';
    });
  }

  const handleChange = useCallback(
    (value: any, item: string) => {
      setProfileTemp({
        ...profileTemp,
        [item]: value,
      });
    },
    [profileTemp],
  );

  const handleChangeNotification = useCallback(
    (value: any) => {
      setProfileTemp({
        ...profileTemp,
        notification: value.map((item: any) => item.value),
      });
    },
    [profileTemp],
  );

  const handleSubmit = useCallback(
    async (data: ISettings) => {
      formRef.current?.setErrors({});

      try{
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Apelido obrigatório'),
          fullName: Yup.string().required('Nome completo obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          notification: Yup.array(Yup.string().email('Digite um email valido')),
        });
  
        await schema.validate(data, {
          abortEarly: false,
        });
  
        updateAdmin({
          ...profileTemp,
          ...data,
          avatar: image,
        })
  
        toast.success('Profile atualizado com sucesso.');
        navigate('../')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);
          
          if(Object.keys(errors).filter(error => error.includes('notification')).length > 0){
            errors.notification = 'Digite um email valido'
          }

          formRef.current?.setErrors(errors);
          return;
        }
        toast.error('Erro ao editar profile');
      }

      
    },
    [updateAdmin, profileTemp, image, navigate],
  );

  return (
    <Container>
      <h1>Perfil</h1>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={profileTemp}>
        <div className="content">
          <Content>
            <Input
              label="Nome completo:"
              name="fullName"
              placeholder="Nome completo"
              onChange={value => handleChange(value.target.value, 'fullName')}
            />
            <Input
              label="Nome:"
              name="name"
              placeholder="Nome"
              onChange={value => handleChange(value.target.value, 'name')}
            />
            <Input
              label="Apelido:"
              name="nickname"
              placeholder="Apelido"
              onChange={value => handleChange(value.target.value, 'nickname')}
            />
            <Input
              label="Cidade:"
              name="city"
              placeholder="Cidade"
              onChange={value => handleChange(value.target.value, 'city')}
            />
            <Input
              label="E-mail:"
              name="email"
              placeholder="Email"
              disabled
              onChange={value => handleChange(value.target.value, 'email')}
            />
            <Chips
              name="notification"
              label="Adicione até 4 emails para notificações:"
              placeholder="Ex.: atletica@aaa.com"
              value={profileTemp?.notification?.map(item => ({label: item, value: item})) || []}
              options={[]}
              onChange={(value: any) => handleChangeNotification(value)}
            />
            <Input
              label="Senha atual:"
              name="old_password"
              placeholder="Senha atual"
              type="password"
              onChange={value =>
                handleChange(value.target.value, 'old_password')
              }
            />
            <Input
              label="Nova senha:"
              name="password"
              placeholder="Nova senha"
              type="password"
              onChange={value => handleChange(value.target.value, 'password')}
            />
            <Input
              label="Confirmação da senha:"
              name="confirmation_password"
              placeholder="Confirmação da senha"
              type="password"
              onChange={value =>
                handleChange(value.target.value, 'confirmation_password')
              }
            />
          </Content>
          <div className="image">
            <div>Foto:</div>
            <ImagePicker uploadPhoto={handlePhoto} image={image} />
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 570,
            minWidth: 400,
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button buttonStyle="danger">Cancelar</Button>
          <Button type="submit" buttonStyle="primary">
            Salvar
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default AtleticaProfile;
