import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAtletica, IFine, IPenalty } from "../../types";


export async function auxUpdateFine(
  fine: IFine,
  setFines: SetterOrUpdater<IFine[]>,
  state: IFine[],
  atleticas: IAtletica[],
  penalties: IPenalty[]
) {
  try {
    api.put(`/fine/${fine.id}`, fine).then(response => {
      const index = state.findIndex(item => item.id === fine.id);
      const updatedFine = state.slice();
      const findAtleticaIndex = atleticas.findIndex(item => item.id === response.data.atletica_id)
      const atletica = atleticas[findAtleticaIndex];
      const findPenaltyIndex = penalties.findIndex(item => item.id === response.data.penalty_id)
      const penalty = penalties[findPenaltyIndex];
      const newFine = {
        ...response.data,
        atletica: [atletica],
        penalty: [penalty]
      }
      updatedFine[index] = newFine;
      setFines(updatedFine);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemoveFine(fine_id: string, setFines: SetterOrUpdater<IFine[]>, state: IFine[]) {
  try {
    api.delete(`/fine/${fine_id}`).then(response => {
      const filteredFines = state.filter(fine => fine.id !== fine_id)
      setFines(filteredFines);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddFine(
  fine: Omit<IFine, 'id'>,
  setFines: SetterOrUpdater<IFine[]>,
  state: IFine[],
  atleticas: IAtletica[],
  penalties: IPenalty[]
) {
  try {
    api.post('/fine', fine).then(response => {
      const findAtleticaIndex = atleticas.findIndex(item => item.id === response.data.atletica_id)
      const atletica = atleticas[findAtleticaIndex];
      const findPenaltyIndex = penalties.findIndex(item => item.id === response.data.penalty_id)
      const penalty = penalties[findPenaltyIndex];
      const newFine = {
        ...response.data,
        atletica: [atletica],
        penalty: [penalty]
      }
      setFines([newFine, ...state]);
    })
  } catch (e) {
    console.log(e)
  }
}