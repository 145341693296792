import { checkWinner } from "../Atoms/modules/individualMatch";
import { IAtletica, ICategory, IIndividualMatch, IIndividuals } from "../types";

interface IAAA {
    atletica: IAtletica & {sideId: string},
    score: number,
    matchList: number[]
}

interface IMatchList extends IIndividualMatch {
    atletica_id1_side?: string,
    atletica_id2_side?: string
}

export function individualPlayoffClass (category: ICategory, individual: IIndividuals) {

    const leftSide = [1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 25, 26, 29]
    const matches: IMatchList[] = category?.matches?.map(m => {
        if(m.number === 31) {
            return {
                ...m,
                atletica_id1_side: `${m.atletica_id1}_a`,
                atletica_id2_side: `${m.atletica_id2}_b`,
            } 
        }
        return {
            ...m,
            atletica_id1_side: m.atletica_id1 ? `${m.atletica_id1}_${leftSide.includes(m.number) ? 'a' : 'b'}` : undefined,
            atletica_id2_side: m.atletica_id2 ? `${m.atletica_id2}_${leftSide.includes(m.number) ? 'a' : 'b'}` : undefined,
        }
    })

    let allAtleticas = matches?.filter(m => m?.number <= 16)?.reduce((acc, m) => {
        if(m.atletica_id1){
            const findAtletica = individual?.atleticas.findIndex(aaa => aaa.id === m.atletica_id1)
            if(findAtletica > -1){
                acc.push({
                    atletica: {
                        ...individual?.atleticas[findAtletica],
                        sideId: `${m.atletica_id1}_${leftSide.includes(m.number) ? 'a' : 'b'}`,
                    },
                    ...getMatches(`${m.atletica_id1}_${leftSide.includes(m.number) ? 'a' : 'b'}`, matches)
                })
            }
        }
        if(m.atletica_id2){
            const findAtletica = individual?.atleticas.findIndex(aaa => aaa.id === m.atletica_id2)
            if(findAtletica > -1){
                acc.push({
                    atletica: {
                        ...individual?.atleticas[findAtletica],
                        sideId: `${m.atletica_id2}_${leftSide.includes(m.number) ? 'a' : 'b'}`
                    },
                    ...getMatches(`${m.atletica_id2}_${leftSide.includes(m.number) ? 'a' : 'b'}`, matches)
                })
            }
        }
        return acc
    }, [] as IAAA[])

    const getWinnerNumber = checkWinner(matches[30]);
    if(getWinnerNumber !== 0){

        const winner = matches[30][`atletica_id${getWinnerNumber === 1 ? 1 : 2}_side`];
        const vice = matches[30][`atletica_id${getWinnerNumber === 1 ? 2 : 1}_side`];

        const findWinnerIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === winner);
        const findViceIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === vice);

        const winnerMatches = allAtleticas[findWinnerIndex]?.matchList || []
        const viceMatches = allAtleticas[findViceIndex]?.matchList || []

        if(findWinnerIndex > -1) allAtleticas[findWinnerIndex].score = allAtleticas[findWinnerIndex].score + 1

        const third = matches[winnerMatches[3] - 1]?.atletica_id1_side === winner ? matches[winnerMatches[3] - 1]?.atletica_id2_side : matches[winnerMatches[3] - 1]?.atletica_id1_side;
        const fourth = matches[viceMatches[3] - 1]?.atletica_id1_side === vice ? matches[viceMatches[3] - 1]?.atletica_id2_side : matches[viceMatches[3] - 1]?.atletica_id1_side;

        const findThirdIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === third);
        const findFourthIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === fourth);

        const thirdMatches = allAtleticas[findThirdIndex]?.matchList || []
        const fourthMatches = allAtleticas[findFourthIndex]?.matchList || []

        if(findThirdIndex > -1) allAtleticas[findThirdIndex].score = allAtleticas[findThirdIndex].score + 1

        const fifth =  matches[winnerMatches[2] - 1]?.atletica_id1_side === winner ? matches[winnerMatches[2] - 1]?.atletica_id2_side : matches[winnerMatches[2] - 1]?.atletica_id1_side;
        const sixth =  matches[viceMatches[2] - 1]?.atletica_id1_side === vice ? matches[viceMatches[2] - 1]?.atletica_id2_side : matches[viceMatches[2] - 1]?.atletica_id1_side;
        const seventh =  matches[thirdMatches[2] - 1]?.atletica_id1_side === third ? matches[thirdMatches[2] - 1]?.atletica_id2_side : matches[thirdMatches[2] - 1]?.atletica_id1_side;
        const eighth =  matches[fourthMatches[2] - 1]?.atletica_id1_side === fourth ? matches[fourthMatches[2] - 1]?.atletica_id2_side : matches[fourthMatches[2] - 1]?.atletica_id1_side;


        const findFifthIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === fifth);
        const findSixthIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === sixth);
        const findSeventhIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === seventh);
        const findEighthIndex = allAtleticas.findIndex(aaa => aaa.atletica.sideId === eighth);

        if(findFifthIndex > -1) allAtleticas[findFifthIndex].score = allAtleticas[findFifthIndex].score + 3
        if(findSixthIndex > -1) allAtleticas[findSixthIndex].score = allAtleticas[findSixthIndex].score + 2
        if(findSeventhIndex > -1) allAtleticas[findSeventhIndex].score = allAtleticas[findSeventhIndex].score + 1

        const fifthMatches = findFifthIndex > -1 ? allAtleticas[findFifthIndex]?.matchList : [];
        const sixthMatches = findSixthIndex > -1 ? allAtleticas[findSixthIndex]?.matchList : [];
        const seventhMatches = findSeventhIndex > -1 ? allAtleticas[findSeventhIndex]?.matchList : [];
        const eighthMatches = findEighthIndex > -1 ? allAtleticas[findEighthIndex]?.matchList : [];

        let p9: string | undefined = '';
        let p10: string | undefined = '';
        let p11: string | undefined = '';
        let p12: string | undefined = '';
        let p13: string | undefined = '';
        let p14: string | undefined = '';
        let p15: string | undefined = '';
        let p16: string | undefined = '';
        
        if(winnerMatches.length > -1){
            p9 = matches[winnerMatches[1] - 1]?.atletica_id1_side === winner ? matches[winnerMatches[1] - 1]?.atletica_id2_side : matches[winnerMatches[1] - 1]?.atletica_id1_side;
        }
        if(viceMatches.length > -1){
            p10 =  matches[viceMatches[1] - 1]?.atletica_id1_side === vice ? matches[viceMatches[1] - 1]?.atletica_id2_side : matches[viceMatches[1] - 1]?.atletica_id1_side;
        }
        if(thirdMatches.length > -1){
            p11 =  matches[thirdMatches[1] - 1]?.atletica_id1_side === third ? matches[thirdMatches[1] - 1]?.atletica_id2_side : matches[thirdMatches[1] - 1]?.atletica_id1_side;
        }
        if(fourthMatches.length > -1){
            p12 =  matches[fourthMatches[1] - 1]?.atletica_id1_side === fourth ? matches[fourthMatches[1] - 1]?.atletica_id2_side : matches[fourthMatches[1] - 1]?.atletica_id1_side;
        }
        if(fifthMatches.length > -1){
            p13 =  matches[fifthMatches[1] - 1]?.atletica_id1_side === fifth ? matches[fifthMatches[1] - 1]?.atletica_id2_side : matches[fifthMatches[1] - 1]?.atletica_id1_side;
        }
        if(sixthMatches.length > -1){
            p14 =  matches[sixthMatches[1] - 1]?.atletica_id1_side === sixth ? matches[sixthMatches[1] - 1]?.atletica_id2_side : matches[sixthMatches[1] - 1]?.atletica_id1_side;
        }
        if(seventhMatches.length > -1){
            p15 =  matches[seventhMatches[1] - 1]?.atletica_id1_side === seventh ? matches[seventhMatches[1] - 1]?.atletica_id2_side : matches[seventhMatches[1] - 1]?.atletica_id1_side;
        }
        if(eighthMatches.length > -1){
            p16 =  matches[eighthMatches[1] - 1]?.atletica_id1_side === eighth ? matches[eighthMatches[1] - 1]?.atletica_id2_side : matches[eighthMatches[1] - 1]?.atletica_id1_side;
        }

        const findP9Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p9);
        const findP10Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p10);
        const findP11Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p11);
        const findP12Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p12);
        const findP13Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p13);
        const findP14Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p14);
        const findP15Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p15);
        const findP16Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p16);

        if(findP9Index > -1) allAtleticas[findP9Index].score = allAtleticas[findP9Index].score + 8
        if(findP10Index > -1) allAtleticas[findP10Index].score = allAtleticas[findP10Index].score + 7
        if(findP11Index > -1) allAtleticas[findP11Index].score = allAtleticas[findP11Index].score + 6
        if(findP12Index > -1) allAtleticas[findP12Index].score = allAtleticas[findP12Index].score + 5
        if(findP13Index > -1) allAtleticas[findP13Index].score = allAtleticas[findP13Index].score + 4
        if(findP14Index > -1) allAtleticas[findP14Index].score = allAtleticas[findP14Index].score + 3
        if(findP15Index > -1) allAtleticas[findP15Index].score = allAtleticas[findP15Index].score + 2
        if(findP16Index > -1) allAtleticas[findP16Index].score = allAtleticas[findP16Index].score + 1

        
        const ninthMaatches = findP9Index > -1 ? allAtleticas[findP9Index]?.matchList : [];
        const tenthMatches = findP10Index > -1 ? allAtleticas[findP10Index]?.matchList : [];
        const eleventhMatches = findP11Index > -1 ? allAtleticas[findP11Index]?.matchList : [];
        const twelfthMatches = findP12Index > -1 ? allAtleticas[findP12Index]?.matchList : [];
        const thirteenthMatches = findP13Index > -1 ? allAtleticas[findP13Index]?.matchList : [];
        const fourteenthMatches = findP14Index > -1 ? allAtleticas[findP14Index]?.matchList : [];
        const fifteenthMatches = findP15Index > -1 ? allAtleticas[findP15Index]?.matchList : [];
        const sixteenthMatches = findP16Index > -1 ? allAtleticas[findP16Index]?.matchList : [];

        let p17: string | undefined = '';
        let p18: string | undefined = '';
        let p19: string | undefined = '';
        let p20: string | undefined = '';
        let p21: string | undefined = '';
        let p22: string | undefined = '';
        let p23: string | undefined = '';
        let p24: string | undefined = '';
        let p25: string | undefined = '';
        let p26: string | undefined = '';
        let p27: string | undefined = '';
        let p28: string | undefined = '';
        let p29: string | undefined = '';
        let p30: string | undefined = '';
        let p31: string | undefined = '';
        let p32: string | undefined = '';

        if(winnerMatches.length > -1){
            p17 = matches[winnerMatches[0] - 1]?.atletica_id1_side === winner ? matches[winnerMatches[0] - 1]?.atletica_id2_side : matches[winnerMatches[0] - 1]?.atletica_id1_side;
        }
        if(viceMatches.length > -1){
            p18 =  matches[viceMatches[0] - 1]?.atletica_id1_side === vice ? matches[viceMatches[0] - 1]?.atletica_id2_side : matches[viceMatches[0] - 1]?.atletica_id1_side;
        }
        if(thirdMatches.length > -1){
            p19 =  matches[thirdMatches[0] - 1]?.atletica_id1_side === third ? matches[thirdMatches[0] - 1]?.atletica_id2_side : matches[thirdMatches[0] - 1]?.atletica_id1_side;
        }
        if(fourthMatches.length > -1){
            p20 =  matches[fourthMatches[0] - 1]?.atletica_id1_side === fourth ? matches[fourthMatches[0] - 1]?.atletica_id2_side : matches[fourthMatches[0] - 1]?.atletica_id1_side;
        }
        if(fifthMatches.length > -1){
            p21 =  matches[fifthMatches[0] - 1]?.atletica_id1_side === fifth ? matches[fifthMatches[0] - 1]?.atletica_id2_side : matches[fifthMatches[0] - 1]?.atletica_id1_side;
        }
        if(sixthMatches.length > -1){
            p22 =  matches[sixthMatches[0] - 1]?.atletica_id1_side === sixth ? matches[sixthMatches[0] - 1]?.atletica_id2_side : matches[sixthMatches[0] - 1]?.atletica_id1_side;
        }
        if(seventhMatches.length > -1){
            p23 =  matches[seventhMatches[0] - 1]?.atletica_id1_side === seventh ? matches[seventhMatches[0] - 1]?.atletica_id2_side : matches[seventhMatches[0] - 1]?.atletica_id1_side;
        }
        if(eighthMatches.length > -1){
            p24 =  matches[eighthMatches[0] - 1]?.atletica_id1_side === eighth ? matches[eighthMatches[0] - 1]?.atletica_id2_side : matches[eighthMatches[0] - 1]?.atletica_id1_side;
        }
        if(ninthMaatches.length > -1){
            p25 =  matches[ninthMaatches[0] - 1]?.atletica_id1_side === p9 ? matches[ninthMaatches[0] - 1]?.atletica_id2_side : matches[ninthMaatches[0] - 1]?.atletica_id1_side;
        }
        if(tenthMatches.length > -1){
            p26 =  matches[tenthMatches[0] - 1]?.atletica_id1_side === p10 ? matches[tenthMatches[0] - 1]?.atletica_id2_side : matches[tenthMatches[0] - 1]?.atletica_id1_side;
        }
        if(eleventhMatches.length > -1){
            p27 =  matches[eleventhMatches[0] - 1]?.atletica_id1_side === p11 ? matches[eleventhMatches[0] - 1]?.atletica_id2_side : matches[eleventhMatches[0] - 1]?.atletica_id1_side;
        }
        if(twelfthMatches.length > -1){
            p28 =  matches[twelfthMatches[0] - 1]?.atletica_id1_side === p12 ? matches[twelfthMatches[0] - 1]?.atletica_id2_side : matches[twelfthMatches[0] - 1]?.atletica_id1_side;
        }
        if(thirteenthMatches.length > -1){
            p29 =  matches[thirteenthMatches[0] - 1]?.atletica_id1_side === p13 ? matches[thirteenthMatches[0] - 1]?.atletica_id2_side : matches[thirteenthMatches[0] - 1]?.atletica_id1_side;
        }
        if(fourteenthMatches.length > -1){
            p30 =  matches[fourteenthMatches[0] - 1]?.atletica_id1_side === p14 ? matches[fourteenthMatches[0] - 1]?.atletica_id2_side : matches[fourteenthMatches[0] - 1]?.atletica_id1_side;
        }
        if(fifteenthMatches.length > -1){
            p31 =  matches[fifteenthMatches[0] - 1]?.atletica_id1_side === p15 ? matches[fifteenthMatches[0] - 1]?.atletica_id2_side : matches[fifteenthMatches[0] - 1]?.atletica_id1_side;
        }
        if(sixteenthMatches.length > -1){
            p32 =  matches[sixteenthMatches[0] - 1]?.atletica_id1_side === p16 ? matches[sixteenthMatches[0] - 1]?.atletica_id2_side : matches[sixteenthMatches[0] - 1]?.atletica_id1_side;
        }


        const findP17Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p17);
        const findP18Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p18);
        const findP19Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p19);
        const findP20Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p20);
        const findP21Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p21);
        const findP22Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p22);
        const findP23Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p23);
        const findP24Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p24);
        const findP25Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p25);
        const findP26Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p26);
        const findP27Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p27);
        const findP28Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p28);
        const findP29Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p29);
        const findP30Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p30);
        const findP31Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p31);
        const findP32Index = allAtleticas.findIndex(aaa => aaa.atletica.sideId === p32);

        if(findP17Index > -1) allAtleticas[findP17Index].score = allAtleticas[findP17Index].score + 15
        if(findP18Index > -1) allAtleticas[findP18Index].score = allAtleticas[findP18Index].score + 14
        if(findP19Index > -1) allAtleticas[findP19Index].score = allAtleticas[findP19Index].score + 13
        if(findP20Index > -1) allAtleticas[findP20Index].score = allAtleticas[findP20Index].score + 12
        if(findP21Index > -1) allAtleticas[findP21Index].score = allAtleticas[findP21Index].score + 11
        if(findP22Index > -1) allAtleticas[findP22Index].score = allAtleticas[findP22Index].score + 10
        if(findP23Index > -1) allAtleticas[findP23Index].score = allAtleticas[findP23Index].score + 9
        if(findP24Index > -1) allAtleticas[findP24Index].score = allAtleticas[findP24Index].score + 8
        if(findP25Index > -1) allAtleticas[findP25Index].score = allAtleticas[findP25Index].score + 7
        if(findP26Index > -1) allAtleticas[findP26Index].score = allAtleticas[findP26Index].score + 6
        if(findP27Index > -1) allAtleticas[findP27Index].score = allAtleticas[findP27Index].score + 5
        if(findP28Index > -1) allAtleticas[findP28Index].score = allAtleticas[findP28Index].score + 4
        if(findP29Index > -1) allAtleticas[findP29Index].score = allAtleticas[findP29Index].score + 3
        if(findP30Index > -1) allAtleticas[findP30Index].score = allAtleticas[findP30Index].score + 2
        if(findP31Index > -1) allAtleticas[findP31Index].score = allAtleticas[findP31Index].score + 1
        if(findP32Index > -1) allAtleticas[findP32Index].score = allAtleticas[findP32Index].score + 0




        const sortedAtleticas = allAtleticas.sort((a, b) => b.score - a.score)

        const formattedRank = sortedAtleticas.map((aaa, index) => (
            {
                ...aaa,
                point: category.points.length >= (index + 1) ? category.points[index] : 0
            }
        ))

        return formattedRank
    } else {
        return []
    }
}


function getMatches(id: string, matches: IMatchList[]){
    let list: number[] = [];
    matches.forEach(m => {
        if(m.atletica_id1_side === id || m.atletica_id2_side === id){
            list.push(m.number)
        }
    })
    return {
        score: getBasePoint(list.length),
        matchList: list
    }
}

function getBasePoint(matchesLength: number){
    switch (matchesLength) {
        case 5:
            return 31
        case 4:
            return 29
        case 3:
            return 25
        case 2:
            return 16   
        default:
            return 0
    }
}