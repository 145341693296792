import styled, { keyframes } from 'styled-components';

interface OptionProps {
  selected: boolean;
}
export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 340px;
    text-align: center;
    gap: 0.5rem;

    h1 {
      margin-bottom: 2rem;
    }

    > svg {
      color: var(--purple03);
    }

    > button {
      margin-top: 2rem;
    }

    a {
      color: var(--color-title-in-background);
      display: block;
      margin-top: 2rem;
      transition: color 0.2s;

      &:hover {
        color: var(--color-text-in-background);
      }
    }
  }
`;

export const SelectedButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 1px solid var(--purple02);
  width: 100%;
  background-color: var(--color-background);
  gap: 1rem;
  margin: 2rem 0;
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${props => (props.selected ? 'var(--purple02)' : 'white')};
  color: ${props => (props.selected ? 'white' : 'var(--purple02)')};
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  transition: 2ms;

  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.selected ? 'var(--purple01)' : 'var(--purple03)'};
  }
`;
