/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable no-return-assign */
/* eslint-disable operator-assignment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import { Form } from '@unform/web';
import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Avatar } from '../../../../components/Avatar';
import Select from '../../../../components/Select';
import SimpleTable from '../../../../components/SimpleTable';
import { useAuth } from '../../../../hooks/auth';
import { useData } from '../../../../hooks/context';
import { IAtletica } from '../../../../types';
import { individualClass } from '../../../../utils/individualClass';
import { qualifierClass } from '../../../../utils/qualifierClass';
import { Header } from '../../styles';

interface ClassificacaoProps {
  atletica: IAtletica;
  point: number;
  [key: string]: number | IAtletica;
}

interface PenaltiesProps {
  atletica: IAtletica;
  point: number;
  penalties: {
    [key: string]: number;
  };
}

interface RepProps {
  atletica: IAtletica;
  total: number;
  rep: {
    [key: string]: number;
  };
  div: number;
}

export function Tables() {
  const { admin } = useAuth();
  const {
    useQualifier,
    useIndividual,
    useAtleticas,
    usePenalties,
    useFines,
    useSettings,
  } = useData();
  const [typeTable, setTypeTable] = useState({
    label: 'Atléticas x Modalidades',
    value: 'mod',
  });
  const [mod, setMod] = useState<ClassificacaoProps[]>([]);
  const [pen, setPen] = useState<PenaltiesProps[]>([]);
  const [rep, setRep] = useState<RepProps[]>([]);

  useEffect(() => {
    const allAtleticas: ClassificacaoProps[] = useAtleticas.atleticas.map(
      aaa => ({
        atletica: aaa,
        point: 0,
      }),
    );

    if (
      useSettings?.settings?.showClassification?.includes(admin?.responsibility) || admin?.responsibility === 'admin' || admin?.responsibility === 'presidente'
    ) {
      const allQualifier = [];
      const allIndividual = [];

      for (const qualifier of useQualifier.qualifiers) {
        allQualifier.push({
          qualifier,
          classification: qualifierClass(qualifier),
        });
      }
      for (const individual of useIndividual.individuals) {
        if(individual.finished){
          allIndividual.push({
            individual,
            classification: individualClass(individual),
          });
        }
      }

      for (const quali of allQualifier) {
        quali.classification.forEach(position => {
          const getIndexAAA = allAtleticas.findIndex(
            item => item.atletica.id === position.atletica.id,
          );
          if (getIndexAAA > -1) {
            allAtleticas[getIndexAAA].point =
              allAtleticas[getIndexAAA].point + position.point;
            allAtleticas[getIndexAAA][quali.qualifier.id] = position.point;
          }
        });
      }

      for (const indi of allIndividual) {
        indi.classification.forEach(position => {
          const getIndexAAA = allAtleticas.findIndex(
            item => item.atletica.id === position.atletica.id,
          );
          if (getIndexAAA > -1) {
            allAtleticas[getIndexAAA].point =
              allAtleticas[getIndexAAA].point + position.point;
            allAtleticas[getIndexAAA][indi.individual.id] = position.point;
          }
        });
      }
    }
    setMod(allAtleticas.sort((a, b) => b.point - a.point));
  }, [
    useAtleticas.atleticas,
    useIndividual.individuals,
    useQualifier.qualifiers,
    admin?.responsibility,
    useSettings.settings.showClassification,
  ]);

  useEffect(() => {
    const qualifiers_id = useQualifier.qualifiers
      .map(qualifier => qualifier.id)
      .reduce(
        (acc: { [key: string]: number }, curr) => ((acc[curr] = 0), acc),
        {},
      );
    const allAtleticas: RepProps[] = useAtleticas.atleticas.map(aaa => ({
      atletica: aaa,
      total: 0,
      rep: qualifiers_id,
      div: 0,
    }));

    const allQualifier: any[] = [];

    for (const qualifier of useQualifier.qualifiers) {
      qualifier.matches.forEach(item => {
        allQualifier.push({
          qualifier: qualifier.id,
          rep1: item.rep_id1 || '',
          rep2: item.rep_id2 || '',
        });
      });
    }

    let total = 0;
    const getAllAtleticas = produce(allAtleticas, draft => {
      for (const quali of allQualifier) {
        const getIndexRep1 = allAtleticas.findIndex(
          item => item.atletica.id === quali.rep1,
        );
        const getIndexRep2 = allAtleticas.findIndex(
          item => item.atletica.id === quali.rep2,
        );
        if (getIndexRep1 > -1) {
          draft[getIndexRep1].total = draft[getIndexRep1].total + 1;
          draft[getIndexRep1].rep[quali.qualifier] =
            draft[getIndexRep1].rep[quali.qualifier] + 1;
          total += 1;
        }
        if (getIndexRep2 > -1) {
          draft[getIndexRep2].total = draft[getIndexRep2].total + 1;
          draft[getIndexRep2].rep[quali.qualifier] =
            draft[getIndexRep2].rep[quali.qualifier] + 1;
          total += 1;
        }
      }
      return draft;
    });

    const formattedAllAtleticas = getAllAtleticas.slice().map(item => ({
      ...item,
      div: Math.round((item.total / total) * 10000) / 100,
    }));

    setRep(formattedAllAtleticas.slice().sort((a, b) => b.total - a.total));
  }, [useAtleticas.atleticas, useQualifier.qualifiers]);

  useEffect(() => {
    const penalties_id = usePenalties.penalties
      .map(penalty => penalty.id)
      .reduce(
        (acc: { [key: string]: number }, curr) => ((acc[curr] = 0), acc),
        {},
      );
    const allAtleticas: PenaltiesProps[] = useAtleticas.atleticas.map(aaa => ({
      atletica: aaa,
      point: 0,
      penalties: penalties_id,
    }));

    const getAllAtleticas = produce(allAtleticas, draft => {
      for (const fine of useFines.fines) {
        const getIndexAAA = allAtleticas.findIndex(
          item => item.atletica.id === fine.atletica_id,
        );
        if (getIndexAAA > -1) {
          draft[getIndexAAA].point =
            draft[getIndexAAA].point +
            parseInt(fine?.penalty[0]?.point?.toString());
          draft[getIndexAAA].penalties[fine.penalty_id] =
            draft[getIndexAAA].penalties[fine.penalty_id] + 1;
        }
      }
      return draft;
    });

    setPen(getAllAtleticas.slice().sort((a, b) => b.point - a.point));
  }, [useAtleticas.atleticas, usePenalties.penalties, useFines.fines]);

  const columnsMod: Column<any>[] = useMemo(() => {
    const addQualifiers = useQualifier.qualifiers.map(qualifier => ({
      Header: `${qualifier.name} ${qualifier.type}`,
      accessor: qualifier.id,
    }));
    const addIndividuals = useIndividual.individuals.map(individual => ({
      Header: `${individual.name} ${individual.type}`,
      accessor: individual.id,
    }));

    return [
      {
        Header: 'Atlética',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 16,
                marginLeft: 40,
              }}
            >
              <div>{`${row.index + 1}º`}</div>
              <Avatar
                name={row.original?.atletica?.nickname}
                avatar={row.original?.atletica?.avatar}
              />
              <div>{row.original.atletica.name}</div>
            </div>
          );
        },
      },
      {
        Header: 'Pontos (sem penalidades)',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.original?.point}
            </div>
          );
        },
      },
      ...addQualifiers,
      ...addIndividuals,
    ];
  }, [useQualifier.qualifiers, useIndividual.individuals]);

  const columnsRep: Column<any>[] = useMemo(() => {
    const addQualifiers = useQualifier.qualifiers.map(qualifier => ({
      Header: `${qualifier.name} ${qualifier.type}`,
      Cell: ({ row }: any) => {
        return <div>{row.original?.rep[qualifier.id]}</div>;
      },
    }));

    return [
      {
        Header: 'Atlética',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 16,
                marginLeft: 40,
              }}
            >
              <div>{`${row.index + 1}º`}</div>
              <Avatar
                name={row.original?.atletica?.nickname}
                avatar={row.original?.atletica?.avatar}
              />
              <div>{row.original.atletica.name}</div>
            </div>
          );
        },
      },
      {
        Header: 'Total',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.original?.total}
            </div>
          );
        },
      },
      {
        Header: '%',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.original?.div}
            </div>
          );
        },
      },
      ...addQualifiers,
    ];
  }, [useQualifier.qualifiers]);

  const columnsPen: Column<any>[] = useMemo(() => {
    const addPenalidades = usePenalties.penalties.map(penalty => ({
      Header: `${penalty.name} (-${penalty.point})`,
      Cell: ({ row }: any) => {
        return <div>{row.original?.penalties[penalty.id]}</div>;
      },
    }));

    return [
      {
        Header: 'Atlética',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 16,
                marginLeft: 40,
              }}
            >
              <div>{`${row.index + 1}º`}</div>
              <Avatar
                name={row.original?.atletica?.nickname}
                avatar={row.original?.atletica?.avatar}
              />
              <div>{row.original.atletica.name}</div>
            </div>
          );
        },
      },
      {
        Header: 'Pontos (sem penalidades)',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.original?.point}
            </div>
          );
        },
      },
      ...addPenalidades,
    ];
  }, [usePenalties.penalties]);

  const getTable = useCallback(() => {
    if (typeTable.value === 'mod') {
      return {
        column: columnsMod,
        data: mod,
      };
    }
    if (typeTable.value === 'pen') {
      return {
        column: columnsPen,
        data: pen,
      };
    }
    if (typeTable.value === 'rep') {
      return {
        column: columnsRep,
        data: rep,
      };
    }
  }, [typeTable, columnsMod, columnsPen, columnsRep, pen, mod, rep]);

  return (
    <>
      <Header>
        <div>
          <h1>Tabelas</h1>
        </div>
      </Header>
      <div style={{ width: '100%', flex: 1, flexDirection: 'column' }}>
        <Form onSubmit={() => {}}>
          <Select
            name="type"
            label="Tabelas:"
            labelPosition="left"
            value={typeTable}
            options={[
              { label: 'Atléticas x Modalidades', value: 'mod' },
              { label: 'Atléticas x Penalidades', value: 'pen' },
              { label: 'Atléticas x Representação', value: 'rep' },
            ]}
            onChange={e => setTypeTable(e)}
          />
        </Form>
        <SimpleTable
          columns={getTable()?.column || []}
          data={getTable()?.data || []}
        />
      </div>
    </>
  );
}
