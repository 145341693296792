import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 10rem);
  margin: 4rem auto;
  padding-right: 3rem;

  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
    height: 100%;
    padding-right: 0rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2rem 0 1rem;

  @media (max-width: 820px) {
    margin: 0rem 0 1rem;
    align-items: flex-start;

    > .button {
      margin-top: 1.5rem;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 820px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  img {
    max-height: 5.5rem;
    margin-right: 1rem;
  }

  .button {
    color: var(--purple02);
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;

    svg {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &:hover {
      color: var(--purple01);
    }
  }
`;
export const ModalidadeTable = styled.div`
  > .label {
    margin-left: 1rem;
  }

  > .icons {
    display: flex;
    height: 4rem;
    width: 4rem;
    align-items: center;
    justify-content: center;

    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const AddNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border: 2px solid var(--purple02);
  color: var(--purple02);
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;

  svg {
    margin-right: 0.5rem;
  }

  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  overflow: auto;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 820px) {
    flex-direction: column;
    padding: 1rem 0;
  }
`;

export const Oitavas = styled.div`
  /* display: grid;
  height: 100%;
  gap: 1rem;
  grid-template-rows: repeat(4, 1fr); */
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`;

export const Quartas = styled.div`
  /* display: grid;
  height: 100%;
  gap: 1rem;
  grid-template-rows: repeat(2, 1fr); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  flex: 1;

  > div {
    height: 100%;
  }
`;

export const Semi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  flex: 1;

  > div {
    height: 100%;
  }
`;

export const Final = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  flex: 1;

  > div {
    height: 100%;
  }
`;
