import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiLock } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/Input';
import getValidationError from '../../utils/getValidationError';
import { Container, AnimationContainer, Content } from './styles';
import logo from '../../assets/logo.svg';
import api from '../../services/api';

interface SignInFormData {
  email: string;
  password: string;
}

function ResetPassword() {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectValue, setSelectValue] = useState<string>('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const pathname = location.pathname.split('/');
    const selectedToken = location.search.split('?token=');
    setSelectValue(pathname[1]);
    setToken(selectedToken[1]);
  }, [location]);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'No mínimo 6 digitos'),
          password_confirmation: Yup.string()
            .min(6, 'No mínimo 6 digitos')
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const url = selectValue === 'admin' ? 'passwordadm' : 'password';

        await api.post(`${url}/reset`, {
          password: data.password,
          token,
        });

        setLoading(false);

        toast.success('Recuperação de senha com sucesso.');

        navigate('login');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);

        toast.error('Ocorreu um erro ao tentar recurperar a senha.');
      }
    },
    [selectValue, token, navigate],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img src={logo} alt="" />
            <div style={{ margin: 16 }}>Recuperação de senha</div>
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="Senha"
              label="Senha"
            />
            <Input
              icon={FiLock}
              name="password_confirmation"
              type="password"
              placeholder="Confirmação da senha"
              label="Confirmação da senha"
            />
            <Button loading={loading} buttonStyle="primary" type="submit">
              Enviar
            </Button>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
}

export default ResetPassword;
