import styled from 'styled-components';

interface BoxProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

export const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${props => (props.selected ? 'var(--purple02)' : 'var(--purple03)')};
  transition: 2ms;

  width: 6rem;
  height: 6rem;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    border-color: var(--purple01);
    padding: 0.25rem;
    cursor: pointer;
  }
`;
