/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { Container, AlertError, ContainerInput } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: any;
  style?: any;
  icon?: React.ComponentType<IconBaseProps>;
  type?: string;
  label?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  containerStyle = {},
  style = {},
  type,
  label,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { registerField, defaultValue, error, fieldName } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <ContainerInput style={style}>
      {label && <div className="label">{label}</div>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        {Icon && <Icon size={20} />}
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          {...rest}
        />

        {error && (
          <AlertError title={error}>
            <FiAlertCircle color="#C53038" size={20} />
          </AlertError>
        )}
        {type === 'password' && (
          <div className="secret-button" onClick={toggleShowPassword}>
            {!showPassword ? (
              <FiEye color="#666" size={16} />
            ) : (
              <FiEyeOff color="#666" size={16} />
            )}
          </div>
        )}
      </Container>
    </ContainerInput>
  );
};

export default Input;
