/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent-props */
import { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../hooks/context';
import Modal from '../Modal';

import {
  Container,
  Header,
  Content,
//  SelectedButton,
//  Option,
  List,
} from './styles';

import athletics from '../../assets/sports-icons/athletics.svg';
import basketball from '../../assets/sports-icons/basketball.svg';
import beachVolleyball from '../../assets/sports-icons/beach-volleyball.svg';
import football from '../../assets/sports-icons/football.svg';
import futsal from '../../assets/sports-icons/futsal.svg';
import handball from '../../assets/sports-icons/handball.svg';
import judo from '../../assets/sports-icons/judo.svg';
import swimming from '../../assets/sports-icons/swimming.svg';
import tableTennis from '../../assets/sports-icons/table-tennis.svg';
import tennis from '../../assets/sports-icons/tennis.svg';
import volleyball from '../../assets/sports-icons/volleyball.svg';
import chess from '../../assets/sports-icons/chess.svg';
import logo from '../../assets/logo_s.svg';
import csgo from '../../assets/sports-icons/csgo.svg';
import clash from '../../assets/sports-icons/clash-royale.svg';
import fifa from '../../assets/sports-icons/fifa.svg';
import valorant from '../../assets/sports-icons/valorant.svg';
import lol from '../../assets/sports-icons/lol.svg';

const icons = [
  [athletics, 'athletics'],
  [basketball, 'basketball'],
  [beachVolleyball, 'beachVolleyball'],
  [football, 'football'],
  [futsal, 'futsal'],
  [handball, 'handball'],
  [judo, 'judo'],
  [swimming, 'swimming'],
  [tableTennis, 'tableTennis'],
  [tennis, 'tennis'],
  [volleyball, 'volleyball'],
  [chess, 'chess'],
  [csgo, 'csgo'],
  [clash, 'clash'],
  [fifa, 'fifa'],
  [valorant, 'valorant'],
  [lol, 'lol'],
];

interface SelectModalidadeProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  initialPage?: 'Qualifiers' | 'Individual';
}
function SelectModalidade({
  showModalOpen,
  toggleShowModalOpen,
  initialPage = 'Qualifiers',
}: SelectModalidadeProps) {
  const navigate = useNavigate();
  const { useQualifier, useIndividual } = useData();
  /* const options: Array<'Qualifiers' | 'Individual'> = [
    'Qualifiers',
    'Individual',
  ]; */
  const [selectValue, setSelectValue] = useState<'Qualifiers' | 'Individual'>(
    initialPage,
  );

  useEffect(() => {
    setSelectValue(initialPage)
  }, [initialPage])

  function getIcons(value: string) {
    const icon = icons.find((item: any[]) => item[1] === value);
    return icon ? icon[0] : undefined;
  }

  return (
    <Modal
      isOpen={showModalOpen}
      setIsOpen={toggleShowModalOpen}
      maxWidth="480px"
    >
      <Container>
        <Header>
          <h2>Modalidades</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          {/* <SelectedButton>
            {options.map(item => (
              <Option
                onClick={() => setSelectValue(item)}
                selected={item === selectValue}
              >
                {item}
              </Option>
            ))}
          </SelectedButton> */}
          <List>
            {selectValue === 'Qualifiers'
              ? useQualifier.qualifiers.map(qualifier => (
                  <div
                    className="item"
                    onClick={() => navigate(`/qualifiers/${qualifier.id}`)}
                  >
                    <img src={getIcons(qualifier.icon) || logo} alt="icon" />
                    {`${qualifier.name} ${qualifier.type}`}
                  </div>
                ))
              : useIndividual.individuals.map(individual => (
                  <div
                    className="item"
                    onClick={() => navigate(`/individuais/${individual.id}`)}
                  >
                    <img src={getIcons(individual.icon) || logo} alt="icon" />
                    {`${individual.name} ${individual.type}`}
                  </div>
                ))}
          </List>
        </Content>
      </Container>
    </Modal>
  );
}

export default SelectModalidade;
