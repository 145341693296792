import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAtletica, IMatch, IPlace, IQualifier } from "../../types";
import { produce } from 'immer'

type matchNumber = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14'

const sorteio = [
  [0, 1, 16],
  [1, 8, 9],
  [2, 5, 12],
  [3, 4, 13],
  [4, 2, 15],
  [5, 7, 10],
  [6, 6, 11],
  [7, 3, 14]
]

export async function auxUpdateMatch(
  match: IMatch,
  setQualifier: SetterOrUpdater<IQualifier[]>,
  state: IQualifier[],
  atleticas: IAtletica[],
  places: IPlace[],
) {
  try {
    const response = await api.put(`/match/${match.id}`, match)
    const index = state.findIndex(item => item.id === match.qualifier_id);
    const indexMatch = state[index].matches_ids.findIndex(item => item === match.id);

    const updatedQualifier = produce(state, (draft) => {
      let aaa1 = undefined;
      let aaa2 = undefined;
      let rep1 = undefined;
      let rep2 = undefined;
      let findPlace = undefined;
      if(response.data.atletica_id1){
        aaa1 = atleticas.find(item => item.id === response.data.atletica_id1);
      }
      if(response.data.atletica_id2){
        aaa2 = atleticas.find(item => item.id === response.data.atletica_id2);
      }
      if(response.data.rep_id1){
        rep1 = atleticas.find(item => item.id === response.data.rep_id1);
      }
      if(response.data.rep_id2){
        rep2 = atleticas.find(item => item.id === response.data.rep_id2);
      }
      if(response.data.place_id){
        findPlace = places.find(item => item.id === response.data.place_id);
      }
      const newMatch: IMatch = {
        ...response.data,
        atletica1: [aaa1],
        atletica2: [aaa2],
        rep1: [rep1],
        rep2: [rep2],
        place: [findPlace]
      }
      draft[index].matches[indexMatch] = newMatch
    })
    
    // setQualifier(updatedQualifier);
    return updatedQualifier[index].matches[indexMatch]
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddAllAtleticas(
  allAtleticas: string[],
  qualifier_id: string,
  setQualifier: SetterOrUpdater<IQualifier[]>,
  state: IQualifier[],
  atleticas: IAtletica[],
  places: IPlace[],
) {
  try {

    const qualifierIndex = state.findIndex(item => item.id === qualifier_id);

    if(qualifierIndex < 0) {
      return
    }


    const updatedQualifier = await produce(state, async (draft) => {

      const matches = draft[qualifierIndex].matches;

      for(const m of sorteio) {
        const updatedMatch = produce(matches[m[0]], (draft) => {
          draft.atletica_id1 = allAtleticas[m[1] - 1]
          draft.atletica_id2 = allAtleticas[m[2] - 1]
          draft.qualifier_id = qualifier_id
          return draft
        })
  
        const matchResponse = await auxUpdateMatch(
          updatedMatch,
          setQualifier,
          state,
          atleticas,
          places
        )
        if(!matchResponse) throw new Error("match did not exists");
        
        draft[qualifierIndex].matches[m[0]] = matchResponse ;
      }

    })
    
    setQualifier(updatedQualifier)
    return state[qualifierIndex]
  } catch (e) {
    console.log(e)
  }
}

export async function updateAllMatches(
  match: IMatch,
  setQualifier: SetterOrUpdater<IQualifier[]>,
  state: IQualifier[],
  atleticas: IAtletica[],
  places: IPlace[],
) {

  try{
    const matchResponse1 = await auxUpdateMatch(
      match,
      setQualifier,
      state,
      atleticas,
      places,
    )
    if(!matchResponse1) throw new Error("match did not exists");

    const index = state.findIndex(item => item.id === match.qualifier_id);
    const indexMatch = state[index].matches_ids.findIndex(item => item === match.id);

    const updatedQualifier = produce(state, (draft) => {
      draft[index].matches[indexMatch] = matchResponse1
    })

    if(match.number === 15){
      setQualifier(updatedQualifier)
      return matchResponse1
    }

    const matches = state[index].matches.slice();

  
    const check1 = checkWinner(matchResponse1)

    const nextMatchNumber = getNextMatch(matchResponse1.number.toString());
    const atleticaIndex = match.number % 2 === 0 ? 2 : 1;
    const nextMatch = matches.find(item => item.number === nextMatchNumber);
    if(!nextMatch) throw new Error("match did not exists");

    const updateNextMatch = produce(nextMatch, (draft) => {
      if(check1 === 1){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex}`]: matchResponse1.atletica_id1
        }
      }
      
      if(check1 === -1){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex}`]: matchResponse1.atletica_id2
        }
      }
  
      if(check1 === 0){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex}`]: undefined
        }
      }
    })

    const matchResponse2 = await auxUpdateMatch(
      updateNextMatch,
      setQualifier,
      state,
      atleticas,
      places,
    )
    if(!matchResponse2) throw new Error("match did not exists");

    const indexMatch2 = state[index].matches_ids.findIndex(item => item === matchResponse2.id);
    const updatedQualifier1 = produce(updatedQualifier, (draft) => {
      draft[index].matches[indexMatch2] = matchResponse2
    })

    if([13, 14].includes(match.number)){
      setQualifier(updatedQualifier1)
      return matchResponse1
    }
  
    const check2 = checkWinner(matchResponse2)

    const nextMatchNumber2 = getNextMatch(matchResponse2.number.toString());
    const atleticaIndex2 = matchResponse2.number % 2 === 0 ? 2 : 1;
    const nextMatch2 = matches.find(item => item.number === nextMatchNumber2);
    if(!nextMatch2) throw new Error("match did not exists");

    const updateNextMatch2 = produce(nextMatch2, (draft) => {
      if(check2 === 1){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex2}`]: matchResponse2.atletica_id1
        }
      }
      
      if(check2 === -1){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex2}`]: matchResponse2.atletica_id2
        }
      }
  
      if(check2 === 0){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex2}`]: undefined
        }
      }
    })

    const matchResponse3 = await auxUpdateMatch(
      updateNextMatch2,
      setQualifier,
      state,
      atleticas,
      places,
    )
    if(!matchResponse3) throw new Error("match did not exists");
    const indexMatch3 = state[index].matches_ids.findIndex(item => item === matchResponse3.id);
    const updatedQualifier2 = produce(updatedQualifier1, (draft) => {
      draft[index].matches[indexMatch3] = matchResponse3
    })

    if([9, 10, 11, 12].includes(match.number)){
      setQualifier(updatedQualifier2)
      return matchResponse1
    }

  
    const check3 = checkWinner(matchResponse3)

    const nextMatchNumber3 = getNextMatch(matchResponse3.number.toString());
    const atleticaIndex3 = matchResponse3.number % 2 === 0 ? 2 : 1;
    const nextMatch3 = matches.find(item => item.number === nextMatchNumber3);
    if(!nextMatch3) throw new Error("match did not exists");

    const updateNextMatch3 = produce(nextMatch3, (draft) => {
      if(check3 === 1){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex3}`]: matchResponse3.atletica_id1
        }
      }
      
      if(check3 === -1){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex3}`]: matchResponse3.atletica_id2
        }
      }
  
      if(check3 === 0){
        return {
          ...draft,
          qualifier_id: match.qualifier_id,
          [`atletica_id${atleticaIndex3}`]: undefined
        }
      }
    })

    const matchResponse4 = await auxUpdateMatch(
      updateNextMatch3,
      setQualifier,
      state,
      atleticas,
      places,
    )

    if(!matchResponse4) throw new Error("match did not exists");
    const indexMatch4 = state[index].matches_ids.findIndex(item => item === matchResponse4.id);
    const updatedQualifier3 = produce(updatedQualifier2, (draft) => {
      draft[index].matches[indexMatch4] = matchResponse4
    })
    
    setQualifier(updatedQualifier3)
    return matchResponse1

  } catch (e) {
    console.log(e)
  }

}

export async function updateAMatch(
  match: IMatch,
  setQualifier: SetterOrUpdater<IQualifier[]>,
  state: IQualifier[],
  atleticas: IAtletica[],
  places: IPlace[],
) {

  try{
    const matchResponse = await auxUpdateMatch(
      match,
      setQualifier,
      state,
      atleticas,
      places,
    )
    if(!matchResponse) throw new Error("match did not exists");

    const index = state.findIndex(item => item.id === match.qualifier_id);
    const indexMatch = state[index].matches_ids.findIndex(item => item === match.id);

    const updatedQualifier = produce(state, (draft) => {
      draft[index].matches[indexMatch] = matchResponse
    })

    setQualifier(updatedQualifier)
    return matchResponse

  } catch (e) {
    console.log(e)
  }

}

export function checkWinner(match: IMatch) {
  let score1 = fixScore(match).score1;
  let score2 = fixScore(match).score2;

  let point1 = 0;
  let point2 = 0;

  score1.forEach((score, index) => {
    const result = score - score2[index];
    if(result > 0){
      point1 = point1 + 1;
    }
    if (result < 0) {
      point2 = point2 + 1;
    }
  })

  // return 1 to team1 winner
  // return -1 to team2 winner
  // return 0 to draw

  if(point1 > point2){
    return 1
  }
  if(point2 > point1){
    return -1
  }
  return 0  
}

function fixScore(match: IMatch){
        
    const count1 = match.score1.length;
    const count2 = match.score2.length;

    if (count1 === 0 && count2 === 0) {
        return {
          score1: [0],
          score2: [0]
        }
      }
  
      if (count1 > count2) {
        let offset = count1 - count2;
        return {
          score1: match.score1,
          score2: [...match.score2, ...Array(offset).fill(0)]
        }
      }
  
      if (count2 > count1) {
        let offset = count2 - count1;
        return {
          score1: [...match.score1, ...Array(offset).fill(0)],
          score2: match.score2,
        }
      }
  
      return {
        score1: match.score1,
        score2: match.score2
      }
}

export function getNextMatch(number: any){
  const match = {
    '0' : 0,
    '1': 9,
    '2': 9,
    '3': 10,
    '4': 10,
    '5': 11,
    '6': 11,
    '7': 12,
    '8': 12,
    '9': 13,
    '10': 13,
    '11': 14,
    '12': 14,
    '13': 15,
    '14': 15
  }

  return match[number as matchNumber]
}
