/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { ICategory, IIndividuals } from '../../../../types';

import { Container, Header, Content, Class } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import Select from '../../../../components/Select';
import Input from '../../../../components/Input';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import FormContainer from '../../../../components/FormContainer';

interface ModalCategoryProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  individual: IIndividuals;
  category: ICategory;
}

interface IFormData {
  name: string;
  option: string;
}

interface SelectProps {
  label: string;
  value: string;
}

function ModalEditCategory({
  showModalOpen,
  toggleShowModalOpen,
  individual,
  category,
}: ModalCategoryProps) {
  const formRef = useRef<FormHandles>(null);
  const { useAtleticas, useIndividual } = useData();
  const { atleticas } = useAtleticas;

  const [pointsTemp, setPointsTemp] = useState<number[]>(Array(16).fill(0));
  const [optionsValueTemp, setOptionsValueTemp] = useState<any[]>(
    Array(32).fill(''),
  );
  const [nameValueTemp, setNameValueTemp] = useState<any[]>(
    Array(32).fill(''),
  );
  const [classTemp, setClassTemp] = useState<SelectProps[]>(
    Array(32).fill({} as SelectProps),
  );
  const [optionsTemp, setOptionsTemp] = useState<string>('');

  useEffect(() => {
    if (category.points) {
      setPointsTemp(category.points);
    }
    if (category.options) {
      setNameValueTemp(category.options[0].value);
    }
    if (category.options.length > 1) {
      setOptionsTemp(category.options[1].label);
      setOptionsValueTemp(category.options[1].value);
    }
    if (category.classification) {
      const formattedClass = category.classification.map(item => {
        if (item) {
          const findAAA = atleticas.find(aaa => aaa.id === item);
          if (findAAA) {
            return {
              value: findAAA.id,
              label: findAAA.name,
            };
          }
          return {} as SelectProps;
        }
        return {} as SelectProps;
      });
      setClassTemp(formattedClass);
    }
  }, [atleticas, category]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          option: Yup.string().max(6, 'Maximo de 6 caracteres')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        useIndividual.updateCategory({
          ...category,
          individual_id: individual.id,
          name: data.name,
          options: [
            {
              label: 'Atleta',
              value: nameValueTemp,
            },
            {
              label: optionsTemp,
              value: optionsValueTemp,
            },
          ],
          points: pointsTemp,
          classification: classTemp.map(item => item?.value || ''),
          id: category.id,
        });

        toast.success('Categoria editado com sucesso');
        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar local');
      }
    },
    [useIndividual, category, individual.id, nameValueTemp, optionsTemp, optionsValueTemp, pointsTemp, classTemp, toggleShowModalOpen],
  );

  const handleChangePoints = useCallback(
    (value: number, index: number) => {
      const points = produce(pointsTemp, draft => {
        draft[index] = value;
      });
      setPointsTemp(points);
    },
    [pointsTemp],
  );

  const handleChangeOptionsValue = useCallback(
    (value: any, index: number) => {
      const values = produce(optionsValueTemp, draft => {
        draft[index] = value;
      });
      setOptionsValueTemp(values);
    },
    [optionsValueTemp],
  );

  const handleChangeNamesValue = useCallback(
    (value: any, index: number) => {
      const values = produce(nameValueTemp, draft => {
        draft[index] = value;
      });
      setNameValueTemp(values);
    },
    [nameValueTemp],
  );

  const handleChangeClassification = useCallback(
    (value: SelectProps, index: number) => {
      const classi = classTemp;
      classi[index] = value;
      setClassTemp(classi);
    },
    [classTemp],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>{`Classificação - ${category.name}`}</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={category}>
            <FormContainer line="1fr 1fr">
              <Input name="name" label="Nome" placeholder="Nome" />
              <Input
                name="option"
                value={optionsTemp}
                onChange={(e) => setOptionsTemp(e.target.value)}
                label="Opção adicional"
                placeholder="Ex.: Tempo (s), altura (m)..."
              />
            </FormContainer>
            <div className="classification">
              {Array(32)
                .fill(0)
                .map((item, index) => (
                  <Class key={item}>
                    <p>{`${index + 1}º`}</p>
                    <Select
                      name={`classification-${index + 1}`}
                      options={atleticas.map(aaa => ({
                        value: aaa.id,
                        label: aaa.name,
                      }))}
                      onChange={value =>
                        handleChangeClassification(value, index)
                      }
                      value={classTemp[index]}
                      label={index === 0 ? 'Atlética' : ''}
                    />
                    <Input
                      name={`point-${index + 1}`}
                      label={index === 0 ? 'Pontuação' : ''}
                      value={pointsTemp[index]}
                      onChange={e =>
                        handleChangePoints(parseInt(e.target.value), index)
                      }
                    />
                    <Input
                      name={`name-value-${index + 1}`}
                      placeholder="Atleta"
                      label={index === 0 ? 'Atleta' : ''}
                      value={nameValueTemp[index]}
                      onChange={e =>
                        handleChangeNamesValue(e.target.value, index)
                      }
                    />
                    {!!optionsTemp && (
                      <Input
                        name={`option-value-${index + 1}`}
                        placeholder={optionsTemp}
                        label={index === 0 ? optionsTemp : ''}
                        value={optionsValueTemp[index]}
                        onChange={e =>
                          handleChangeOptionsValue(e.target.value, index)
                        }
                      />
                    )}
                  </Class>
                ))}
            </div>
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button type="submit" buttonStyle="primary" theme="solid">
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}

export default ModalEditCategory;
