import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 8rem);
  margin: 4rem auto;
  overflow-y: auto;

  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 3rem 0 1rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .button {
    color: var(--purple02);
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;

    svg {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &:hover {
      color: var(--purple01);
    }
  }
`;

export const AddNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border: 2px solid var(--purple02);
  color: var(--purple02);
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;

  svg {
    margin-right: 0.5rem;
  }

  cursor: pointer;
`;

export const ClassiBox = styled.div`
  border-radius: 1rem;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
  margin: 0 0.5rem 1rem;
  padding: 2rem;
  height: 14rem;

  > .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    > .possi {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      > img {
        width: 3rem;
        height: 3rem;
        border-radius: 0.25rem;
      }

      > p {
        font-size: 1.5rem;
      }
    }

    > .pts {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;
      gap: 0.25rem;

      > .point {
        font-size: 2.5rem;
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 1rem;

    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      > div {
        &:first-child {
          font-weight: 500;
        }
      }
    }
  }
`;
