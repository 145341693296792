import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 3rem;

  table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 1rem;

    thead tr th {
      border-bottom: 2px solid var(--purple02);
      border-collapse: separate;
      border-spacing: 5px 5px;
      font-weight: 600;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
    }

    tbody tr {
      border-radius: 1rem;
      -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
    }

    tbody tr td {
      border-radius: 1rem;
      padding: 1rem 0.5rem;
      vertical-align: center;
      text-align: center;
    }
  }
`;
