import styled from 'styled-components';

interface FormContainerProps {
  line: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 10rem);
  margin: 4rem auto;
  overflow-y: auto;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 50vh;
    height: 100%;
    margin-top: 2rem;

    .image {
      width: 20rem;
      height: 20rem;
    }

    .select-all-aaa {
      font-size: 1rem;
      margin: 0;
      text-decoration: underline;
      color: var(--purple02);

      cursor: pointer;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin-right: 1rem;
  justify-content: center;
  align-items: flex-start;
  color: var(--color-text-in-background);
`;

export const FormContainer = styled.div<FormContainerProps>`
  display: grid;
  grid-template-columns: ${props => props.line};
  gap: 1rem;
  margin-bottom: 1rem;

  > div {
    margin-top: 0;
  }
`;
