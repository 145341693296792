import styled, { css, keyframes } from 'styled-components';

interface ItemMenuProps {
  selected: boolean;
  showMenu: boolean;
}

interface ItemSubMenuProps {
  selected: boolean;
  showMenu: boolean;
  isSubMenu: boolean;
}

interface MenuProps {
  showMenu: boolean;
  menuOpened: boolean;
  initialState: boolean;
}
interface ProfileProps {
  showMenu: boolean;
}

interface ChildrenProps {
  showMenu: boolean;
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(100%);
  }
  to{
    opacity: 0;
    transform: translateX(0);
  }
`;

export const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Menu = styled.div<MenuProps>`
  position: relative;
  display: flex;
  height: 100vh;
  width: ${props => (props.showMenu ? '8rem' : '25rem')};
  background: var(--purple01);
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  color: var(--color-background);
  padding: 0.75rem 1rem;

  .menuIcon {
    display: none;
  }

  .items {
      overflow-y: auto;
      padding-right: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #9e9e9e;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #636363;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #3d3d3d;
      }
      ::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #e6e6e6;
      }
      ::-webkit-scrollbar-track:active {
        background: #e0e0e0;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
  }

  @media (max-width: 820px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    z-index: 2;
    height: 5rem;
    padding: 1rem;

    .menuIcon {
      display: flex;
      position: absolute;
      right: 3rem;
      top: 2rem;
      font-size: 1.5rem;
      z-index: 5;
    }

    > .items {
      position: absolute;
      top: 4.99rem;
      height: calc(100vh - 5rem);
      padding: 1rem 0.5rem;
      align-items: center;
      width: 100%;
      background: var(--purple01);
      overflow-y: auto;
      left: ${props => (props.menuOpened ? '0' : '-100%')};
      ${props =>
        props.menuOpened
          ? css`
              animation: ${appearFromLeft} 0.25s;
            `
          : !props.initialState &&
            css`
              animation: ${appearFromRight} 0.25s;
            `};
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  margin: 0.75rem 0 1rem;
  padding: 3rem 1rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  cursor: pointer;

  @media (max-width: 820px) {
    width: 100%;
    padding: 0.5rem 1rem;
    height: 5rem;
    margin: 0;
  }
`;

export const Logo = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  padding: 0.2rem;

  @media (max-width: 820px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const WithoutLogo = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  padding: 0.2rem;

  @media (max-width: 820px) {
    width: 2.5rem;
    height: 2.5rem;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    background-color: var(--purple03);
    color: var(--purple01);
  }
`;

export const Logout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  font-size: 1.25rem;
  margin: 1rem auto;
  text-decoration: underline;
  cursor: pointer;
`;

export const Title = styled.div``;

export const ItemMenu = styled.div<ItemMenuProps>`
  display: flex;
  margin: 1rem 0rem;
  padding: 0.75rem 0;
  border-radius: 1rem;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.15rem;

  .title-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
    white-space: nowrap;

    > hr {
      width: 100%;
      height: 0.25px;
      background-color: white;
      opacity: 0.2 5;
    }
  }
`;

export const ItemSubMenu = styled.div<ItemSubMenuProps>`
  display: flex;
  margin: 0rem;
  padding: 0.75rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.35rem;
  gap: 1rem;

  &:hover {
    ${props =>
    !props.isSubMenu &&
    css`
      background-color: var(--purple01-lighten-hover);
    `}
    cursor: pointer;
  }

  ${props =>
    props.isSubMenu ? (
      css`
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      `
    ) : (
      css`
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      `
    )
  }

  ${props =>
    props.showMenu &&
    css`
      justify-content: center;
    `}
`;

export const Profile = styled.div<ProfileProps>`
  width: 100%;
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  background-color: var(--purple01-lighten);
  border-radius: 1rem;
  align-self: flex-end;

  &:hover {
    background-color: var(--purple01-lighten-hover);
    cursor: pointer;
  }

  ${props =>
    props.showMenu &&
    css`
      height: 5rem;
      justify-content: center;
      padding: 0.5rem;
      width: 5rem;
    `}
`;

export const Responsibility = styled.div`
  font-size: 0.95rem;
  text-transform: capitalize;
`;

export const Children = styled.div<ChildrenProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: ${props =>
    props.showMenu ? 'calc(100vw - 5rem)' : 'calc(100vw - 25rem)'};
  height: 100vh;
  overflow: hidden;
  padding: 1rem;

  @media (max-width: 820px) {
    width: 100vw;
    height: calc(100vh - 5rem);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 5rem;
    z-index: 0;
    margin: 0 1rem;
    max-width: 100vw;
    padding: 0.5rem 1rem;
  }
`;

export const ToggleButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  top: 3rem;
  right: -1.5rem;
  border-radius: 50%;
  background-color: #fff;
  padding: 0.25rem;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--purple02);

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 820px) {
    display: none;
  }
`;
