/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import produce from 'immer';
import { toast } from 'react-toastify';
import { useData } from '../../../../hooks/context';
import Modal from '../../../../components/Modal';

import { Container, Header, Content } from './styles';
import { IIndividuals } from '../../../../types';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';

interface ModalDesempateProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  individual: IIndividuals;
}

function ModalDesempate({
  showModalOpen,
  toggleShowModalOpen,
  individual,
}: ModalDesempateProps) {
  const { useIndividual } = useData();
  const [categoriesList, setCategoriesList] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (individual?.draw) {
      const formattedCategoriesList = individual.draw.map(item => {
        const category = individual?.categories?.find(cat => cat.id === item);
        if (category) {
          return { label: category.name, value: category.id };
        }
        return { label: 'Nenhuma categoria', value: '' };
      });
      setCategoriesList(formattedCategoriesList);
    } else {
      setCategoriesList([]);
    }
  }, [individual]);

  const handleChange = useCallback(
    (value: { value: string; label: string }, index: number) => {
      const newList = produce(categoriesList, draft => {
        draft[index] = value;
        return draft;
      });
      setCategoriesList(newList);
    },
    [categoriesList],
  );

  const getOptions = useCallback(() => {
    if (individual?.categories) {
      const allOptions = individual?.categories?.map(item => ({
        value: item.id,
        label: item.name,
      }));
      const filteredOptions = allOptions?.filter(
        item => categoriesList.findIndex(cat => cat.value === item.value) < 0,
      );
      return [{ label: 'Nenhuma categoria', value: '' }, ...filteredOptions];
    }
    return [];
  }, [categoriesList, individual]);

  const handleSubmit = useCallback(async () => {
    try {
      useIndividual.updateIndividual({
        ...individual,
        draw: categoriesList.map(item => item.value),
      });

      toggleShowModalOpen();
    } catch (err) {
      toast.error('Algo deu errado!');
    }
  }, [categoriesList, individual, toggleShowModalOpen, useIndividual]);

  return (
    <Modal
      isOpen={showModalOpen}
      setIsOpen={toggleShowModalOpen}
      maxWidth="480px"
    >
      <Container>
        <Header>
          <h2>Critério de desempate</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        {individual && (
          <Content>
            <Form onSubmit={handleSubmit}>
              {individual?.categories && individual?.categories?.length > 0 ? (
                <>
                  {individual?.categories.map((item, index) => (
                    <Select
                      name={`cat-${index}`}
                      onChange={value => handleChange(value, index)}
                      value={categoriesList[index]}
                      options={getOptions()}
                      label={`${index + 1}º`}
                      labelPosition="left"
                    />
                  ))}
                  <div className="buttons">
                    <Button
                      buttonStyle="primary"
                      theme="hollow"
                      onClick={toggleShowModalOpen}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" buttonStyle="primary" theme="solid">
                      Salvar
                    </Button>
                  </div>
                </>
              ) : (
                <div>Sem categorias</div>
              )}
            </Form>
          </Content>
        )}
      </Container>
    </Modal>
  );
}

export default ModalDesempate;
