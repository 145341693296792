/* eslint-disable radix */
/* eslint-disable operator-assignment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useMemo, useState, useEffect, useCallback } from 'react';
import { Column } from 'react-table';
import { Avatar } from '../../../../components/Avatar';
import Table from '../../../../components/Table';
import { Header, ClassiBox, AddNew } from '../../styles';

import medal1 from '../../../../assets/medal1.svg';
import medal2 from '../../../../assets/medal2.svg';
import medal3 from '../../../../assets/medal3.svg';
import { IAtletica } from '../../../../types';

import { useData } from '../../../../hooks/context';
import { qualifierClass } from '../../../../utils/qualifierClass';
import { individualClass } from '../../../../utils/individualClass';
import { useAuth } from '../../../../hooks/auth';
import { BsTrophy } from 'react-icons/bs';
import { FaMedal } from 'react-icons/fa';
import { ModalTrophy } from '../ModalTrophy';
import { ModalMedal } from '../ModalMedal';

interface ClassificacaoProps {
  atletica: IAtletica;
  point: number;
  fine: number;
  [key: number]: number;
}

export function Ranking() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const { useIndividual, useQualifier, useAtleticas, useFines, useSettings } =
    useData();
  const [classificacao, setClassificacao] = useState<ClassificacaoProps[]>([]);

  const [showTrophy, setShowTrophy] = useState(false);
  const [showMedal, setShowMedal] = useState(false);

  const toggleShowTrophy = useCallback(() => {
    setShowTrophy(!showTrophy);
  }, [showTrophy]);

  const toggleShowMedal = useCallback(() => {
    setShowMedal(!showMedal);
  }, [showMedal]);

  useEffect(() => {
    const responsibility = admin?.responsibility;

    if (
      useSettings?.settings?.showClassification?.includes(responsibility) ||
      responsibility === 'admin' ||
      responsibility === 'presidente'
    ) {
      const allAtleticas: ClassificacaoProps[] = useAtleticas?.atleticas?.map(
        aaa => ({
          atletica: aaa,
          point: 0,
          fine: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          17: 0,
          position: []
        }),
      );

      const allQualifier = [];
      const allIndividual = [];

      for (const qualifier of useQualifier?.qualifiers) {
        allQualifier.push(qualifierClass(qualifier));
      }
      for (const individual of useIndividual?.individuals) {
        if(individual.finished){
          allIndividual.push(individualClass(individual));
        }
      }

      for (const quali of allQualifier) {
        quali.forEach((position, index) => {
          const getIndexAAA = allAtleticas.findIndex(
            item => item.atletica.id === position.atletica.id,
          );
          if (getIndexAAA > -1) {
            allAtleticas[getIndexAAA].point =
              allAtleticas[getIndexAAA].point + position.point;
            const positionOfQualifier =
              index + 1 > 16 && index + 1 < 1 ? 17 : index + 1;
            allAtleticas[getIndexAAA][positionOfQualifier] =
              allAtleticas[getIndexAAA][positionOfQualifier] + 1;
          }
        });
      }

      for (const indi of allIndividual) {
        indi.forEach((position, index) => {
          const getIndexAAA = allAtleticas.findIndex(
            item => item.atletica.id === position.atletica.id,
          );
          if (getIndexAAA > -1) {
            allAtleticas[getIndexAAA].point =
              allAtleticas[getIndexAAA].point + position.point;
            const positionOfQualifier =
              index + 1 > 16 && index + 1 < 1 ? 17 : index + 1;
            allAtleticas[getIndexAAA][positionOfQualifier] =
              allAtleticas[getIndexAAA][positionOfQualifier] + 1;
          }
        });
      }

      for (const fine of useFines?.fines) {
        const getIndexAAA = allAtleticas.findIndex(
          item => item.atletica.id === fine.atletica_id,
        );
        if (getIndexAAA > -1) {
          allAtleticas[getIndexAAA].point =
            allAtleticas[getIndexAAA].point -
            parseInt(fine?.penalty[0]?.point?.toString());
          allAtleticas[getIndexAAA].fine =
            allAtleticas[getIndexAAA].fine +
            parseInt(fine?.penalty[0]?.point?.toString());
        }
      }
      let clas = allAtleticas.map(aa => ({
        ...aa,
        position: [aa['1'], aa['2'], aa['3'], aa['4'], aa['5'], aa['6'], aa['7'], aa['8'], aa['9'], aa['10'], aa['11'], aa['12'], aa['13'], aa['13'], aa['14'], aa['15'], aa['16']]
      }))

      clas = clas.slice().sort((a, b) => {
        const [numberA, numberB] = arrayToNumber(a.position, b.position);
        return numberB - numberA
    }).sort((a, b) => b.point - a.point)    

      setClassificacao(clas);
    }
  }, [
    useAtleticas.atleticas,
    useIndividual.individuals,
    useQualifier.qualifiers,
    useFines.fines,
    useSettings.settings,
    admin?.responsibility,
  ]);

  const columnsRanking: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Atlética',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 16,
                marginLeft: 40,
              }}
            >
              <div>{`${row.index + 1}º`}</div>
              <Avatar
                name={row.original?.atletica?.nickname}
                avatar={row.original?.atletica?.avatar}
              />
              <div>{row.original.atletica.name}</div>
            </div>
          );
        },
      },
      {
        Header: 'Pontos',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.original?.point}
            </div>
          );
        },
      },
      {
        Header: 'Penalidades',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.original?.fine > 0 ? `-${row.original?.fine}` : 0}
            </div>
          );
        },
      },
      {
        Header: () => {
          return (
            <img alt="medal1" src={medal1} style={{ width: 24, height: 24 }} />
          );
        },
        accessor: '1',
      },
      {
        Header: () => {
          return (
            <img alt="medal2" src={medal2} style={{ width: 24, height: 24 }} />
          );
        },
        accessor: '2',
      },
      {
        Header: () => {
          return (
            <img alt="medal3" src={medal3} style={{ width: 24, height: 24 }} />
          );
        },
        accessor: '3',
      },
      {
        Header: '4º',
        accessor: '4',
      },
      {
        Header: '5º',
        accessor: '5',
      },
      {
        Header: '6º',
        accessor: '6',
      },
      {
        Header: '7º',
        accessor: '7',
      },
      {
        Header: '8º',
        accessor: '8',
      },
    ],
    [],
  );

  function arrayToNumber(array1: any[] = [], array2: any[] = []) {
    let a = array1.slice();
    let b = array2.slice();
    let length1 = array1.length
    let length2 = array2.length

    if(length1 > length2) {
        b = [...b, Array(length1 - length2).fill(0)]
    } else if (length2 > length1) {
        a = [...a, Array(length1 - length2).fill(0)]
    }

    return [parseInt(a.join('0')), parseInt(b.join('0'))]

}

  return (
    <>
      <Header>
        <div>
          <h1>Classificação</h1>
        </div>
        <div>
          <AddNew onClick={toggleShowTrophy}>
            <BsTrophy />
            Trofeus
          </AddNew>
          <AddNew onClick={toggleShowMedal}>
            <FaMedal />
            Medalhas
          </AddNew>
        </div>
      </Header>
      {widthScreen <= 820 ? (
        <div style={{ marginTop: 32 }}>
          {classificacao.map((item, index) => (
            <ClassiBox>
              <div className="header">
                <div className="possi">
                  <p style={{ fontSize: '2rem' }}>{`${index + 1}º`}</p>
                  {item?.atletica?.avatar && (
                    <img src={item?.atletica.avatar} alt="icon" />
                  )}
                  <p>{item.atletica.name}</p>
                </div>
                <div className="pts">
                  <p className="point">{item.point}</p>
                  pts
                </div>
              </div>
              <div className="content">
                <div className="box">
                  <div>P.</div>
                  <div>{item?.fine > 0 ? `-${item?.fine}` : 0}</div>
                </div>
                <div className="box">
                  <div>1º</div>
                  <div>{item[1]}</div>
                </div>
                <div className="box">
                  <div>2º</div>
                  <div>{item[2]}</div>
                </div>
                <div className="box">
                  <div>3º</div>
                  <div>{item[3]}</div>
                </div>
                <div className="box">
                  <div>4º</div>
                  <div>{item[4]}</div>
                </div>
                <div className="box">
                  <div>5º</div>
                  <div>{item[5]}</div>
                </div>
                <div className="box">
                  <div>6º</div>
                  <div>{item[6]}</div>
                </div>
                <div className="box">
                  <div>7º</div>
                  <div>{item[7]}</div>
                </div>
                <div className="box">
                  <div>8º</div>
                  <div>{item[8]}</div>
                </div>
              </div>
            </ClassiBox>
          ))}
        </div>
      ) : (
        <div style={{ maxWidth: 1100 }}>
          <Table columns={columnsRanking} data={classificacao} />
        </div>
      )}
      <ModalTrophy
        showModalOpen={showTrophy}
        toggleShowModalOpen={toggleShowTrophy}
      />
      <ModalMedal 
        showModalOpen={showMedal}
        toggleShowModalOpen={toggleShowMedal}
      />
    </>
  );
}
