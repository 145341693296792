/* eslint-disable @typescript-eslint/no-empty-function */
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { IIndividualMatch } from '../../../../types';

import { Container, Header, Body, Title, Score } from './styles';

interface IMatchBox {
  match: IIndividualMatch;
  onChange?: () => void;
}

function MatchBox({ match, onChange = () => {} }: IMatchBox) {
  const getScore = useCallback(() => {
    const count1 = match.score1.length;
    const count2 = match.score2.length;

    if (count1 === 0 && count2 === 0) {
      return {
        score1: ['-'],
        score2: ['-'],
      };
    }

    if (count1 > count2) {
      const offset = count1 - count2;
      return {
        score1: match.score1,
        score2: [...match.score2, ...Array(offset).fill(0)],
      };
    }

    if (count2 > count1) {
      const offset = count2 - count1;
      return {
        score1: [...match.score1, ...Array(offset).fill(0)],
        score2: match.score2,
      };
    }

    return {
      score1: match.score1,
      score2: match.score2,
    };
  }, [match]);

  const getSide = useCallback(() => {
    const left = [1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 25, 26, 29, 31];

    const isLeft = left.includes(match.number);

    return isLeft;
  }, [match]);

  if (getSide()) {
    return (
      <Container onClick={() => onChange()}>
        <Header isLeft>
          <div className="location">{match?.place?.length > 0 ? match?.place[0]?.name : ' - '}</div>
          <div className="date">
            {match?.startDate
              ? format(new Date(match.startDate), 'dd/MM')
              : ' - '}
          </div>
        </Header>
        <Body>
          <Title isLeft>
            {match?.atletica1 ? (
              <div>
                {match?.atletica1[0]?.avatar && (
                  <img src={match?.atletica1[0]?.avatar} alt="icon" />
                )}
                <div>{match?.atletica1[0]?.nickname || ' - '}</div>
              </div>
            ) : (
              <div>{' - '}</div>
            )}
            {match?.atletica2 ? (
              <div>
                {match?.atletica2[0]?.avatar && (
                  <img src={match?.atletica2[0]?.avatar} alt="icon" />
                )}
                <div>{match?.atletica2[0]?.nickname || ' - '}</div>
              </div>
            ) : (
              <div>{' - '}</div>
            )}
            <div id={`dot-${match.number}-in`} className="dot-in-left" />
          </Title>
          <Score>
            <div>
              {getScore().score1.map(score => (
                <div>{score}</div>
              ))}
            </div>
            <div>
              {getScore().score2.map(score => (
                <div>{score}</div>
              ))}
            </div>
            <div id={`dot-${match.number}-out`} className="dot-out-left" />
          </Score>
        </Body>
      </Container>
    );
  }
  return (
    <Container onClick={() => onChange()}>
      <Header isLeft={false}>
        <div className="date">
          {match?.startDate
            ? format(new Date(match.startDate), 'dd/MM')
            : ' - '}
        </div>
        <div className="location">{match?.place?.length > 0 ? match?.place[0]?.name : ' - '}</div>
      </Header>
      <Body>
        <Score>
          <div>
            {getScore().score1.map(score => (
              <div>{score}</div>
            ))}
          </div>
          <div>
            {getScore().score2.map(score => (
              <div>{score}</div>
            ))}
          </div>
          <div id={`dot-${match.number}-out`} className="dot-out-right" />
        </Score>
        <Title isLeft={false}>
          {match?.atletica1 ? (
            <div>
              {match?.atletica1[0]?.avatar && (
                <img src={match?.atletica1[0]?.avatar} alt="icon" />
              )}
              <div>{match?.atletica1[0]?.nickname || ' - '}</div>
            </div>
          ) : (
            <div>{' - '}</div>
          )}
          {match?.atletica2 ? (
            <div>
              {match?.atletica2[0]?.avatar && (
                <img src={match?.atletica2[0]?.avatar} alt="icon" />
              )}
              <div>{match?.atletica2[0]?.nickname || ' - '}</div>
            </div>
          ) : (
            <div>{' - '}</div>
          )}
          <div id={`dot-${match.number}-in`} className="dot-in-right" />
        </Title>
      </Body>
    </Container>
  );
}

export default MatchBox;
