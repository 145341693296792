import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IPlace } from "../../types";


export async function auxUpdatePlace(place: IPlace, setPlaces: SetterOrUpdater<IPlace[]>, state: IPlace[]) {
  try {
    api.put(`/place/${place.id}`, place).then(response => {
      const index = state.findIndex(item => item.id === place.id);
      const updatedPlace = state.slice();
      updatedPlace[index] = response.data;
      setPlaces(updatedPlace);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemovePlace(place_id: string, setPlaces: SetterOrUpdater<IPlace[]>, state: IPlace[]) {
  try {
    api.delete(`/place/${place_id}`).then(response => {
      const filteredPlaces = state.filter(place => place.id !== place_id)
      setPlaces(filteredPlaces);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddPlace(place: Omit<IPlace, 'id'>, setPlaces: SetterOrUpdater<IPlace[]>, state: IPlace[]) {
  try {
    api.post('/place', place).then(response => {
      setPlaces([...state, response.data]);
    })
  } catch (e) {
    console.log(e)
  }
}