import styled from 'styled-components';

interface TitleProps {
  isLeft: boolean;
}

interface HeaderProps {
  isLeft: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border: 1px solid var(--purple02);
  border-radius: 0.5rem;
  color: var(--purple01);
  width: 18rem;
  height: 9rem;
  min-height: 9rem;
  max-height: 9rem;
  font-size: 1.25rem;
  transition: 2ms;

  &:hover {
    cursor: pointer;
    transform: scale(1.005);
  }
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% + 1px);
  justify-content: space-between;
  border-bottom: 1px solid var(--purple02);
  height: 3rem;
  min-height: 3rem;
  max-height: 3rem;

  > .location {
    padding: 0.25rem 0.75rem 0.25rem 0.5rem;
    background-color: var(--purple02);
    color: #fff;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 3rem;
    border-radius: ${props =>
      props.isLeft ? '0.45rem 0 0 0' : '0 0.45rem 0 0'};
  }

  > .date {
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
    display: flex;
    min-width: 4rem;
    max-width: 6rem;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
`;
export const Body = styled.div`
  display: flex;
  width: calc(100% + 1px);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  min-height: 6rem;
  max-height: 6rem;
`;
export const Title = styled.div<TitleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  img {
    height: 2rem;
    margin-right: 0.5rem;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.isLeft ? 'flex-start' : 'flex-end')};
    padding: 0.5rem 1rem;
    height: 3rem;
  }

  > .dot-in-left {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 0;
    height: 0;
    padding: 0;
  }

  > .dot-in-right {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 0;
    height: 0;
    padding: 0;
  }

  > div:first-child {
    border-bottom: 1px solid var(--purple02);
  }
`;
export const Score = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    height: 3rem;
    gap: 0.5rem;
  }

  > .dot-out-left {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    padding: 0;
  }

  > .dot-out-right {
    position: absolute;
    top: 50%;
    left: -1px;
    width: 0;
    height: 0;
    padding: 0;
  }

  > div:first-child {
    border-bottom: 1px solid var(--purple02);
  }
`;
