import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import { FiLock, FiMail } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useAuth } from '../../hooks/auth';
import getValidationError from '../../utils/getValidationError';
import {
  Container,
  AnimationContainer,
  Content,
} from './styles';
import logo from '../../assets/logo.svg';

interface SignInFormData {
  email: string;
  password: string;
}

function SignInCaster() {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(4, 'Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
          loginAs: 'caster',
        });

        navigate('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Ocorreu um erro ao fazer login, cheque as credenciais.');
      }
    },
    [signIn, navigate],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img src={logo} alt="" />
            <h3>Login caster</h3>
            <Input
              icon={FiMail}
              name="email"
              placeholder="E-mail"
              label="E-mail"
            />
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="Senha"
              label="Senha"
            />
            <Button buttonStyle="primary" type="submit">
              Entrar
            </Button>
            <Link to="/forgot-password">Esqueci minha senha</Link>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
}

export default SignInCaster;
