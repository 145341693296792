/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import Modal from '../../../../components/Modal';

import { Container, Header, Content } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import { useData } from '../../../../hooks/context';

import Button from '../../../../components/Button';
import Chips from '../../../../components/Chips';
import api from '../../../../services/api';

interface ModalConfigProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  setSelectedPlaces: any;
  selectedPlaces: any;
}

interface IFormData {
  places_id: string[];
}
interface SelectValue {
  value: string;
  label: string;
}

function ModalConfig({
  showModalOpen,
  toggleShowModalOpen
}: ModalConfigProps) {
  const formRef = useRef<FormHandles>(null);
  const { usePlace } = useData();
  const { places, setPlaces } = usePlace;
  const [loading, setLoading] = useState(false);
  const [selectedPlaces, setSelectedPlaces] = useState<SelectValue[]>([]);
  useEffect(() => {
    setSelectedPlaces(places.filter(place => place.isPriority).map(place => ({
      value: place.id,
      label: place.name,
    })))
  }, [places])
  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true)
        const { places_id } = data
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put('/place/priority', {
          places_id
        })

        let updatedPlaces = places.map(place => places_id.includes(place.id) ? ({ ...place, isPriority: true }) : ({ ...place, isPriority: false }))
        setPlaces(updatedPlaces);

        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }
      } finally {
        setLoading(false)
      }
    },
    [toggleShowModalOpen, places, setPlaces],
  );

  const handleSelectedPlaces = useCallback((values: SelectValue[]) => {
    setSelectedPlaces(values);
  }, [])

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Configuração</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Chips
              name="places_id"
              label="Praças esportivas:"
              options={places.map(place => ({
                value: place.id,
                label: place.name,
              }))}
              onChange={handleSelectedPlaces}
              value={selectedPlaces}
              containerStyle={{ minHeight: '100px', alignItems: 'flex-start', marginBottom: '100px'}}
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button type="submit" buttonStyle="primary" theme="solid" loading={loading}>
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}

export default ModalConfig;
