import styled from 'styled-components';

interface FormContainerProps {
  line: string;
}

export const Container = styled.div<FormContainerProps>`
  display: grid;
  grid-template-columns: ${props => props.line};
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  > div {
    margin-top: 0;
  }

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
