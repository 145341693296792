import React, { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';

import { AuthProvider } from './auth';
import { DataProvider } from './context';
import { EditionProvider } from './edition';

interface AppProviderProps {
  children: ReactNode;
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <AuthProvider>
      <RecoilRoot>
        <EditionProvider>
          <DataProvider>{children}</DataProvider>
        </EditionProvider>
      </RecoilRoot>
    </AuthProvider>
  );
}

export default AppProvider;
