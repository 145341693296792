import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem 0 3rem;
  padding: 0 1rem;

  > .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    cursor: pointer;
  }
`;
