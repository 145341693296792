/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react/jsx-curly-newline */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { /* FiPlusCircle, */ FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import { ptBR } from 'date-fns/locale';
import { startOfDay } from 'date-fns';
import Modal from '../../../../components/Modal';
import { ICategory, IIndividualMatch, IIndividuals, IMatch } from '../../../../types';

import { Container, Header, Content, Team } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import Select from '../../../../components/Select';
import Input from '../../../../components/Input';
import { useData } from '../../../../hooks/context';
import DatePicker from '../../../../components/DatePicker';

import Button from '../../../../components/Button';
import FormContainer from '../../../../components/FormContainer';

interface ModalMatchBoxProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  category: ICategory;
  individual: IIndividuals;
  match: IIndividualMatch;
}

function ModalMatchBox({
  showModalOpen,
  toggleShowModalOpen,
  category,
  individual,
  match,
}: ModalMatchBoxProps) {
  const formRef = useRef<FormHandles>(null);
  const { usePlace, useAtleticas, useIndividual } = useData();
  const { places } = usePlace;
  const { atleticas } = useAtleticas;
  const [matchTemp, setMatchTemp] = useState(match);

  useEffect(() => {
    setMatchTemp(match);
  }, [match]);

  const handleSubmit = useCallback(
    async (data: IMatch) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        useIndividual.updateIndividualMatch({
          ...matchTemp,
          category_id: category.id,
          startDate: data?.startDate || null,
        }, individual.id);

        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Place');
      }
    },
    [matchTemp, individual.id, toggleShowModalOpen, useIndividual, category.id],
  );

  const getScore = useCallback(() => {
    const count1 = matchTemp.score1.length;
    const count2 = matchTemp.score2.length;

    if (count1 === 0 && count2 === 0) {
      return {
        score1: [0],
        score2: [0],
      };
    }

    if (count1 > count2) {
      const offset = count1 - count2;
      return {
        score1: matchTemp.score1,
        score2: [...matchTemp.score2, ...Array(offset).fill(0)],
      };
    }

    if (count2 > count1) {
      const offset = count2 - count1;
      return {
        score1: [...matchTemp.score1, ...Array(offset).fill(0)],
        score2: matchTemp.score2,
      };
    }

    return {
      score1: matchTemp.score1,
      score2: matchTemp.score2,
    };
  }, [matchTemp]);

  const handleChangeAtletica = useCallback(
    (value: string, field: string) => {
      const findAtletica = atleticas.find(aaa => aaa.id === value);
      if (findAtletica) {
        setMatchTemp({
          ...matchTemp,
          [`atletica_id${field}`]: findAtletica.id,
          [`atletica${field}`]: [findAtletica],
        });
      }
    },
    [matchTemp, atleticas],
  );

  const handleChangePlace = useCallback(
    (value: string) => {
      const findPlace = places.find(place => place.id === value);
      if (findPlace) {
        setMatchTemp({
          ...matchTemp,
          place_id: findPlace.id,
          place: [findPlace],
        });
      }
    },
    [matchTemp, places],
  );

  const handleChangeInput = useCallback(
    (value: string, score: 'score1' | 'score2', index: number) => {
      if (matchTemp[score].length === 0) {
        setMatchTemp({
          ...matchTemp,
          [score]: [parseInt(value)],
        });
      } else {
        const updateMatch = produce(matchTemp, draft => {
          draft[score][index] = parseInt(value);
        });
        setMatchTemp(updateMatch);
      }
    },
    [matchTemp],
  );

  console.log({matchTemp})
  
  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        {matchTemp.id && (
          <>
            <Header>
              <h2>{`${individual.name} ${individual.type}: ${category.name} - #${matchTemp?.number}`}</h2>
              <div onClick={toggleShowModalOpen}>
                <FiX />
              </div>
            </Header>
            <Content>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={matchTemp}
              >
                <div className="atleticas">
                  <div className="team">
                    <Team>
                      <Select
                        label="Atlética 1"
                        name="atletica_id1"
                        value={
                          matchTemp.atletica_id1
                            ? {
                                value: matchTemp?.atletica_id1,
                                label: matchTemp?.atletica1[0]?.nickname,
                              }
                            : undefined
                        }
                        options={individual?.atleticas?.map((item: any) => ({
                          value: item.id,
                          label: item.nickname,
                        }))}
                        onChange={value =>
                          handleChangeAtletica(value.value, '1')
                        }
                        isDisabled={matchTemp.number > 16}
                      />
                      {getScore().score1.map((item, index) => (
                        <Input
                          key={item}
                          name={`score1-${index}`}
                          label="Score"
                          style={{ maxWidth: 40 }}
                          value={item}
                          onChange={e =>
                            handleChangeInput(e.target.value, 'score1', index)
                          }
                        />
                      ))}
                    </Team>
                    <Team>
                      <Select
                        label="Atlética 2"
                        name="atletica_id2"
                        value={
                          matchTemp.atletica_id2
                            ? {
                                value: matchTemp?.atletica_id2,
                                label: matchTemp?.atletica2[0]?.nickname,
                              }
                            : undefined
                        }
                        options={individual?.atleticas?.map((item: any) => ({
                          value: item.id,
                          label: item.nickname,
                        }))}
                        onChange={value =>
                          handleChangeAtletica(value.value, '2')
                        }
                        isDisabled={matchTemp.number > 16}
                      />
                      {getScore().score2.map((item, index) => (
                        <Input
                          key={item}
                          name={`score2-${index}`}
                          label="Score"
                          style={{ maxWidth: 40 }}
                          value={item}
                          onChange={e =>
                            handleChangeInput(e.target.value, 'score2', index)
                          }
                        />
                      ))}
                    </Team>
                  </div>
                </div>
                <FormContainer line="3fr 1fr">
                  <Select
                    label="Praça esportiva:"
                    name="place"
                    options={places.map(item => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    value={
                      matchTemp?.place_id
                        ? {
                            value: matchTemp?.place_id,
                            label: matchTemp?.place[0]?.name,
                          }
                        : undefined
                    }
                    onChange={value => handleChangePlace(value.value)}
                  />
                  <DatePicker
                    label="Data e horário"
                    defaultDate={
                      matchTemp?.startDate
                        ? new Date(matchTemp.startDate)
                        : startOfDay(new Date())
                    }
                    locale={ptBR}
                    timeFormat="HH:mm"
                    name="startDate"
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="HH:mm - dd/MM/yyyy"
                  />
                </FormContainer>
                <div className="buttons">
                  <Button
                    buttonStyle="primary"
                    theme="hollow"
                    onClick={toggleShowModalOpen}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" buttonStyle="primary" theme="solid">
                    Salvar
                  </Button>
                </div>
              </Form>
            </Content>
          </>
        )}
      </Container>
    </Modal>
  );
}

export default ModalMatchBox;
