import styled from 'styled-components';

interface OptionProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  height: 440px;
  overflow-y: hidden;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem 0 3rem;
  padding: 0 1rem;
  overflow-y: auto;

  > .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    cursor: pointer;
  }
`;

export const SelectedButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 1px solid var(--purple02);
  width: 100%;
  background-color: var(--color-background);
  gap: 1rem;
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${props => (props.selected ? 'var(--purple02)' : 'white')};
  color: ${props => (props.selected ? 'white' : 'var(--purple02)')};
  padding: 0.5rem 2rem;
  border-radius: 0.4rem;
  transition: 2ms;

  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.selected ? 'var(--purple01)' : 'var(--purple03)'};
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;
  gap: 1rem;
  margin: 2rem 0 4rem;

  > .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
    border-radius: 1rem;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
    transition: 2ms;
    padding: 1rem;

    > img {
      height: 4.5rem;
      width: 4.5rem;
    }

    &:hover {
      transform: scale(1.01);
      cursor: pointer;
    }
  }
`;
