import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAtletica, IIndividualMatch, IIndividuals, IPlace } from "../../types";
import { produce } from 'immer'

type matchNumber = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '25' | '26' | '27' | '28' | '29' | '30'

const sorteio = [
  [0, 1, 32],
  [1, 16, 17],
  [2, 9, 24],
  [3, 8, 25],
  [4, 5, 28],
  [5, 12, 21],
  [6, 13, 20],
  [7, 4, 29],
  [8, 2, 31],
  [9, 15, 18],
  [10, 10, 23],
  [11, 7, 26],
  [12, 6, 27],
  [13, 11, 22],
  [14, 14, 19],
  [15, 3, 30],
]

export async function auxUpdateIndividualMatch(
  match: IIndividualMatch,
  individual_id: string,
  state: IIndividuals[],
  atleticas: IAtletica[],
  places: IPlace[],
) {
  try {
    const response = await api.put(`/category-match/${match.id}`, match)
    const index = state.findIndex(item => item.id === individual_id);
    const indexCategory = state[index]?.categories.findIndex(item => item.id === match.category_id);
    const indexMatch = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === match.id) || -1;

    const updateIndividual = produce(state, (draft) => {
      let aaa1 = undefined;
      let aaa2 = undefined;
      let findPlace = undefined;
      if(response.data.atletica_id1){
        aaa1 = atleticas.find(item => item.id === response.data.atletica_id1);
      }
      if(response.data.atletica_id2){
        aaa2 = atleticas.find(item => item.id === response.data.atletica_id2);
      }
      if(response.data.place_id){
        findPlace = places.find(item => item.id === response.data.place_id);
      }
      const newMatch: IIndividualMatch = {
        ...response.data,
        atletica1: [aaa1],
        atletica2: [aaa2],
        place: [findPlace]
      }
      draft[index].categories[indexCategory].matches[indexMatch] = newMatch
    })
    
    return updateIndividual[index].categories[indexCategory].matches[indexMatch]
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddAllAtleticasIndividual(
  allAtleticas: string[],
  category_id: string,
  individual_id: string,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  state: IIndividuals[],
  atleticas: IAtletica[],
  places: IPlace[],
) {
  try {

    const individualIndex = state.findIndex(item => item.id === individual_id);

    if(individualIndex < 0) {
      return
    }

    const categoryIndex = state[individualIndex]?.categories?.findIndex(item => item.id === category_id);

    if(categoryIndex < 0) {
      return
    }

    const updatedIndividual = await produce(state, async (draft) => {
  
      const matches = draft[individualIndex]?.categories[categoryIndex]?.matches?.slice();

      if(matches) {
        for(const m of sorteio) {
          const updatedMatch = produce(matches[m[0]], (draft) => {
            draft.atletica_id1 = allAtleticas[m[1] - 1]
            draft.atletica_id2 = allAtleticas[m[2] - 1]
            draft.category_id = category_id
            return draft
          })
    
          const matchResponse = await auxUpdateIndividualMatch(
            updatedMatch,
            individual_id,
            state,
            atleticas,
            places
          )
          if(!matchResponse) throw new Error("match did not exists");
          draft[individualIndex].categories[categoryIndex].matches[m[0]] = updatedMatch;
        }
      }


    })
    
    setIndividual(updatedIndividual)
    return state[individualIndex]
    
  } catch (e) {
    console.log(e)
  }
}

export async function updateAllIndividualMatches(
  match: IIndividualMatch,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  individual_id: string,
  state: IIndividuals[],
  atleticas: IAtletica[],
  places: IPlace[],
) {

  try{
    const matchResponse1 = await auxUpdateIndividualMatch(
      match,
      individual_id,
      state,
      atleticas,
      places,
    )
    if(!matchResponse1) throw new Error("match did not exists");

    const index = state.findIndex(item => item.id === individual_id);
    const indexCategory = state[index]?.categories.findIndex(item => item.id === match.category_id);
    const indexMatch = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === match.id) || -1;

    const updatedQualifier = produce(state, (draft) => {
      draft[index].categories[indexCategory].matches[indexMatch] = matchResponse1
    })

    if(match.number === 31){
      setIndividual(updatedQualifier)
      return matchResponse1
    }

    const matches = state[index].categories[indexCategory].matches;

  
    const check1 = checkWinner(matchResponse1)

    const nextMatchNumber = getNextMatch(matchResponse1.number.toString());
    const atleticaIndex = match.number % 2 === 0 ? 2 : 1;
    const nextMatch = matches.find(item => item.number === nextMatchNumber);
    if(!nextMatch) throw new Error("match did not exists");

    const updateNextMatch = produce(nextMatch, (draft) => {
      if(check1 === 1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex}`]: matchResponse1.atletica_id1
        }
      }
      
      if(check1 === -1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex}`]: matchResponse1.atletica_id2
        }
      }
  
      if(check1 === 0){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex}`]: undefined
        }
      }
    })

    const matchResponse2 = await auxUpdateIndividualMatch(
      updateNextMatch,
      individual_id,
      state,
      atleticas,
      places,
    )
    if(!matchResponse2) throw new Error("match did not exists");

    const indexMatch2 = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === matchResponse2.id) || -1;
    const updatedQualifier1 = produce(updatedQualifier, (draft) => {
      draft[index].categories[indexCategory].matches[indexMatch2] = matchResponse2
    })

    if([29, 30].includes(match.number)){
      setIndividual(updatedQualifier1)
      return matchResponse1
    }
  
    const check2 = checkWinner(matchResponse2)

    const nextMatchNumber2 = getNextMatch(matchResponse2.number.toString());
    const atleticaIndex2 = matchResponse2.number % 2 === 0 ? 2 : 1;
    const nextMatch2 = matches.find(item => item.number === nextMatchNumber2);
    if(!nextMatch2) throw new Error("match did not exists");

    const updateNextMatch2 = produce(nextMatch2, (draft) => {
      if(check2 === 1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex2}`]: matchResponse2.atletica_id1
        }
      }
      
      if(check2 === -1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex2}`]: matchResponse2.atletica_id2
        }
      }
  
      if(check2 === 0){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex2}`]: undefined
        }
      }
    })

    const matchResponse3 = await auxUpdateIndividualMatch(
      updateNextMatch2,
      individual_id,
      state,
      atleticas,
      places,
    )
    if(!matchResponse3) throw new Error("match did not exists");
    const indexMatch3 = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === matchResponse3.id) || -1;
    const updatedIndividual2 = produce(updatedQualifier1, (draft) => {
      draft[index].categories[indexCategory].matches[indexMatch3] = matchResponse3
    })

    if([25, 26, 27, 28].includes(match.number)){
      setIndividual(updatedIndividual2)
      return matchResponse1
    }

  
    const check3 = checkWinner(matchResponse3)

    const nextMatchNumber3 = getNextMatch(matchResponse3.number.toString());
    const atleticaIndex3 = matchResponse3.number % 2 === 0 ? 2 : 1;
    const nextMatch3 = matches.find(item => item.number === nextMatchNumber3);
    if(!nextMatch3) throw new Error("match did not exists");

    const updateNextMatch3 = produce(nextMatch3, (draft) => {
      if(check3 === 1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex3}`]: matchResponse3.atletica_id1
        }
      }
      
      if(check3 === -1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex3}`]: matchResponse3.atletica_id2
        }
      }
  
      if(check3 === 0){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex3}`]: undefined
        }
      }
    })

    const matchResponse4 = await auxUpdateIndividualMatch(
      updateNextMatch3,
      individual_id,
      state,
      atleticas,
      places,
    )

    if(!matchResponse4) throw new Error("match did not exists");
    const indexMatch4 = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === matchResponse4.id) || -1;
    const updatedIndividual3 = produce(updatedIndividual2, (draft) => {
      draft[index].categories[indexCategory].matches[indexMatch4] = matchResponse4
    })
    

    if([24, 23, 22, 21, 20, 19, 18, 17].includes(match.number)){
      setIndividual(updatedIndividual2)
      return matchResponse1
    }

    const check4 = checkWinner(matchResponse4)

    const nextMatchNumber4 = getNextMatch(matchResponse4.number.toString());
    const atleticaIndex4 = matchResponse4.number % 2 === 0 ? 2 : 1;
    const nextMatch4 = matches.find(item => item.number === nextMatchNumber4);
    if(!nextMatch4) throw new Error("match did not exists");

    const updateNextMatch4 = produce(nextMatch4, (draft) => {
      if(check4 === 1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex4}`]: matchResponse4.atletica_id1
        }
      }
      
      if(check4 === -1){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex4}`]: matchResponse4.atletica_id2
        }
      }
  
      if(check4 === 0){
        return {
          ...draft,
          category_id: match.category_id,
          [`atletica_id${atleticaIndex4}`]: undefined
        }
      }
    })

    const matchResponse5 = await auxUpdateIndividualMatch(
      updateNextMatch4,
      individual_id,
      state,
      atleticas,
      places,
    )

    if(!matchResponse5) throw new Error("match did not exists");
    const indexMatch5 = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === matchResponse5.id) || -1;
    const updatedIndividual4 = produce(updatedIndividual3, (draft) => {
      draft[index].categories[indexCategory].matches[indexMatch5] = matchResponse5
    })

    setIndividual(updatedIndividual4)
    return matchResponse1

  } catch (e) {
    console.log(e)
  }

}

export async function updateAIndividualMatch(
  match: IIndividualMatch,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  individual_id: string,
  state: IIndividuals[],
  atleticas: IAtletica[],
  places: IPlace[],
) {

  try{
    const matchResponse = await auxUpdateIndividualMatch(
      match,
      individual_id,
      state,
      atleticas,
      places,
    )
    if(!matchResponse) throw new Error("match did not exists");

    const index = state.findIndex(item => item.id === individual_id);
    const indexCategory = state[index]?.categories.findIndex(item => item.id === match.category_id);
    const indexMatch = state[index].categories[indexCategory]?.matches?.findIndex(item => item.id === match.id) || -1;

    const updatedIndividual = produce(state, (draft) => {
      draft[index].categories[indexCategory].matches[indexMatch] = matchResponse
    })

    setIndividual(updatedIndividual)
    return matchResponse

  } catch (e) {
    console.log(e)
  }

}

export function checkWinner(match: IIndividualMatch) {
  if(!match) return 0
  let score1 = fixScore(match).score1;
  let score2 = fixScore(match).score2;

  let point1 = 0;
  let point2 = 0;

  score1.forEach((score, index) => {
    const result = score - score2[index];
    if(result > 0){
      point1 = point1 + 1;
    }
    if (result < 0) {
      point2 = point2 + 1;
    }
  })

  // return 1 to team1 winner
  // return -1 to team2 winner
  // return 0 to draw

  if(point1 > point2){
    return 1
  }
  if(point2 > point1){
    return -1
  }
  return 0  
}

function fixScore(match: IIndividualMatch): {score1: number[], score2: number[]} {
        
    const count1 = match.score1.length;
    const count2 = match.score2.length;

    if (count1 === 0 && count2 === 0) {
        return {
          score1: [0],
          score2: [0]
        }
      }
  
      if (count1 > count2) {
        let offset = count1 - count2;
        return {
          score1: match.score1,
          score2: [...match.score2, ...Array(offset).fill(0)]
        }
      }
  
      if (count2 > count1) {
        let offset = count2 - count1;
        return {
          score1: [...match.score1, ...Array(offset).fill(0)],
          score2: match.score2,
        }
      }
  
      return {
        score1: match.score1,
        score2: match.score2
      }
}

export function getNextMatch(number: any){
  const match = {
    '0' : 0,
    '1': 17,
    '2': 17,
    '3': 18,
    '4': 18,
    '5': 19,
    '6': 19,
    '7': 20,
    '8': 20,
    '9': 21,
    '10': 21,
    '11': 22,
    '12': 22,
    '13': 23,
    '14': 23,
    '15': 24,
    '16': 24,
    '17': 25,
    '18': 25,
    '19': 26,
    '20': 26,
    '21': 27,
    '22': 27,
    '23': 28,
    '24': 28,
    '25': 29,
    '26': 29,
    '27': 30,
    '28': 30,
    '29': 31,
    '30': 31,
  }

  return match[number as matchNumber]
}
