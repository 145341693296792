import styled from 'styled-components';

interface AvatarProfileProps {
  width?: string;
  height?: string;
}

export const AvatarProfile = styled.div<AvatarProfileProps>`
  display: flex;
  font-size: 2rem;
  width: ${props => props.width || '4.5rem'};
  height: ${props => props.height || '4.5rem'};
  border-radius: 50%;
  border: 1px solid var(--purple03);
  align-items: center;
  justify-content: center;
`;

export const WithoutAvatar = styled.div`
  display: flex;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: var(--purple03);
  color: #fff;
  align-items: center;
  justify-content: center;
`;
export const WithAvatar = styled.img`
  display: flex;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  background-color: var(--purple03);
  align-items: center;
  justify-content: center;
`;
