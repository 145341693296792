import { FormHandles } from '@unform/core';
import 'leaflet/dist/leaflet.css';
import { Form } from '@unform/web';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { IPlace } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import { useData } from '../../../../hooks/context';
import FormContainer from '../../../../components/FormContainer';
import Select from '../../../../components/Select';

interface ModalPlaceProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  place: IPlace;
  closeSelectedPlace: () => void;
}

export function ModalPlace({
  showModalOpen,
  toggleShowModalOpen,
  place,
  closeSelectedPlace,
}: ModalPlaceProps) {
  const [placeTemp, setPlaceTemp] = useState(place);
  const { usePlace } = useData();
  const { addPlace, updatePlace } = usePlace;

  useEffect(() => {
    setPlaceTemp(place);
  }, [place]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IPlace) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          address: Yup.string().required('Endereço obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (placeTemp.id !== '') {
          updatePlace({
            ...placeTemp,
            ...data,
          });
        } else {
          addPlace({
            address: data.address,
            name: data.name,
            coordinates: placeTemp.coordinates,
            type: data.type
          });
          closeSelectedPlace();
        }

        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Place');
      }
    },
    [addPlace, closeSelectedPlace, placeTemp, toggleShowModalOpen, updatePlace],
  );

  const handleType = useCallback((value: any) => {
    setPlaceTemp({
      ...placeTemp,
      type: value.value
    })
  }, [placeTemp])

  const options = useMemo(() => [
    {label: 'Praça esportiva', value: 'field'},
    {label: 'Alojamento', value: 'home'},
    {label: 'QG', value: 'qg'},
  ], [])

  const getValue = useCallback(() => {
    const value = options.filter(item => item.value === placeTemp.type)
    return value.length > 0 ? value[0] : undefined
  }, [placeTemp, options])

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Praças Esportivas</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={placeTemp}>
            <FormContainer line="3fr 1fr">
              <Input
                name="name"
                placeholder="Ginasio Costa Calvacante"
                label="Nome:"
              />
              <Select
                name="type"
                options={options}
                label="Tipo:"
                onChange={handleType}
                value={getValue()}
              />
            </FormContainer>
            <Input name="address" placeholder="Endereço" label="Endereço:" />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button type="submit" buttonStyle="primary" theme="solid">
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
