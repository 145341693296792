/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Map, { Marker, Popup } from 'react-map-gl';
import { useData } from '../../hooks/context';
import { ModalPlace } from './components/ModalPlaces';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useSearchParams } from 'react-router-dom';

import { Container, Header, MapContainer, FormContainer } from './styles';

import { IPlace } from '../../types';
import { ModalWaring } from '../../components/ModalWaring';
import { FiEdit, FiMapPin, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import Table from '../../components/Table';
import { Column } from 'react-table';
import Checkbox from '../../components/Checkbox';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

// const style = "mapbox://styles/mapbox/basic-v9";
const ACCESS_TOKEN_MAP_BOX = `access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`;

const colors = {
  field: '#522888',
  qg: '#007bff',
  home: '#28a745',
  default: '#ccc'
}

function Places() {
  const { usePlace } = useData();
  const { places, removePlace } = usePlace;
  const formRef = useRef<FormHandles>(null);
  const [search, setSearch] = useSearchParams();
  
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [selected, setSelected] = useState<any>(undefined);
  const [center, setCenter] = useState<[number, number]>([
    -54.5781457, -25.528462,
  ]);
  const [editPlace, setEditPlace] = useState({} as IPlace);
  const [deletePlace, setDeletePlace] = useState({} as IPlace);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [showPlaceModalDelete, setShowPlaceModalDelete] = useState(false);
  const [showList, setShowList] = useState(false);
  const filters = useMemo(() => {
    return search.get('filter')?.split(',') || ['qg', 'field', 'home']
  }, [search]);
  const [filteredPlaces, setFilteredPlaces] = useState<IPlace[]>(places)

  useEffect(() => {
    setFilteredPlaces(places.filter(place => filters.includes(place?.type || '')))
  }, [places, filters])


  const toggleMode = useCallback(() => {
    setShowList(!showList)
  }, [showList])

  const toggleShowModalOpen = useCallback(() => {
    setShowModalOpen(!showModalOpen);
  }, [showModalOpen]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowPlaceModalDelete(!showPlaceModalDelete);
  }, [showPlaceModalDelete]);

  async function fetchAddress(value: string) {
    const data = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?${ACCESS_TOKEN_MAP_BOX}`,
    )
      .then(response => response.json())
      .then(data => {
        if (data.features.length > 0) {
          const newOptions = data?.features?.map((item: any) => ({
            value: item.id,
            label: item.place_name,
            ...item,
          }));
          return newOptions;
        }
        return [];
      });
    return data || [];
  }

  const handleChange = useCallback((value: any) => {
    setCenter(value.center);
    setSelected(value);
  }, []);

  const handleEditPlace = useCallback(
    (edit: IPlace) => {
      setEditPlace(edit);
      toggleShowModalOpen();
    },
    [toggleShowModalOpen],
  );

  const handleAddPlace = useCallback(
    async (item: any) => {
      if (item?.place_name) {
        setEditPlace({
          coordinates: item.center,
          id: '',
          address: item?.properties?.address || item.label,
          name: '',
        });
      } else {
        const data = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${item.center[0]},${item.center[1]}.json?${ACCESS_TOKEN_MAP_BOX}`,
        )
          .then(response => response.json())
          .then(data => {
            if (data.features.length > 0) {
              const newOptions = data?.features?.map((item: any) => ({
                value: item.id,
                label: item.place_name,
                ...item,
              }));
              return newOptions;
            }
            return [];
          });
        const address = data.filter((item: any) =>
          item.value.includes('address'),
        );
        setEditPlace({
          coordinates: item.center,
          id: '',
          address: address[0]?.place_name || '',
          name: '',
        });
      }
      toggleShowModalOpen();
    },
    [toggleShowModalOpen],
  );

  const handleClick = useCallback((value: any) => {
    const coordinates = value.lngLat;
    setSelected({
      center: [coordinates.lng, coordinates.lat],
    });
  }, []);

  const handleDeletePlace = useCallback(
    (place: IPlace) => {
      setDeletePlace(place);
      toggleShowModalOpenDelete();
    },
    [toggleShowModalOpenDelete],
  );

  const delPlace = useCallback(() => {
    removePlace(deletePlace.id);
    toggleShowModalOpenDelete();
  }, [deletePlace.id, removePlace, toggleShowModalOpenDelete]);

  const closeSelectedPlace = useCallback(() => {
    setSelected(undefined);
  }, []);

  const options = useMemo(() => [
    {label: 'Praça esportiva', value: 'field'},
    {label: 'Alojamento', value: 'home'},
    {label: 'QG', value: 'qg'},
  ], [])

  const getValue = useCallback((type: string) => {
    const value = options.filter(item => item.value === type)
    return value.length > 0 ? value[0] : undefined
  }, [options])

  const columns: Column<any>[] = useMemo(() => 
    [
      {
        Header: 'Nome',
        accessor: 'name',
        label: 'Nome'
      },
      {
        Header: 'Tipo',
        label: 'Tipo',
        Cell : ({ row }) => {
          return (
            <div>
              {getValue(row.original.type)?.label || ' '}
            </div>
          )
        }
      },
      {
        Header: 'Endereço',
        accessor: 'address',
        label: 'Endereço'
      },
      {
        Header: 'Link',
        Cell: ({ row }) => {
          return (
            <div>
              <a rel="noreferrer" target={'_blank'} href={`https://www.google.com.br/maps/@${row.original.coordinates[1]},${row.original.coordinates[0]},19z`}>Abrir no maps</a>
            </div>
          )
        },
      },
    ], [getValue]);

    const handleFilter = useCallback((name: string) => {
      console.log(name, filters)
      if (filters.includes(name)) {
        search.set('filter', filters.filter(item => item !== name).join(','))
        setSearch(search)
      } else {
        search.set('filter', [...filters, name].join(','))
        setSearch(search)
      }
    }, [search, setSearch, filters]);

  return (
    <Container>
      <Header>
        <h1>Praças esportivas</h1>
        <div className="show" onClick={toggleMode}>{`Visualizar ${showList ? 'Mapa' : 'Lista'}`}</div>
        <div>
        <Form ref={formRef} onSubmit={() => {}} className='flex flex-row items-center justify-start gap-2'>
          <Checkbox
            name='field'
            checked={filters.includes('field')}
            onChange={() => handleFilter('field')}
            containerStyle={{ marginTop: 0}}
            containerClassname='flex flex-row items-center justify-start flex-nowrap whitespace-nowrap'
          >
            <FiMapPin color={colors.field} />
            Praça esportiva
          </Checkbox>
          <Checkbox
            name='home'
            containerStyle={{ marginTop: 0}}
            checked={filters.includes('home')}
            onChange={() => handleFilter('home')}
            containerClassname='flex flex-row items-center justify-start flex-nowrap'
          >
            <FiMapPin color={colors.home} />
            Alojamento
          </Checkbox>
          <Checkbox
            name='qg'
            containerStyle={{ marginTop: 0}}
            checked={filters.includes('qg')}
            onChange={() => handleFilter('qg')}
            containerClassname='flex flex-row items-center justify-start flex-nowrap'
          >
            <FiMapPin color={colors.qg} />
            QG
          </Checkbox>
        </Form>
        </div>
      </Header>
      {showList ? (
        <div style={{ width: '100%', height: '100%'}}>
          <Table
            columns={columns}
            data={filteredPlaces}
          />
        </div>
      ) : (
      <MapContainer>
        {!isAtletica && (
          <FormContainer>
            <p>Procurar:</p>
            <AsyncSelect
              defaultOptions
              cacheOptions
              loadOptions={fetchAddress}
              onChange={handleChange}
            />
          </FormContainer>
        )}
        <Map
          mapboxAccessToken={process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX?.toString()}
          initialViewState={{
            longitude: center[0],
            latitude: center[1],
            zoom: 12,
          }}
          onClick={isAtletica ? () => {} : handleClick}
          mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          {filteredPlaces.filter(place => !!place?.name && !!place?.coordinates).map(item => (
            <>
              <Marker
                longitude={item.coordinates[0]}
                latitude={item.coordinates[1]}
                color={colors[item?.type || 'default']}
              />
              <Popup
                longitude={item.coordinates[0]}
                latitude={item.coordinates[1]}
                anchor="bottom"
                offset={24}
                closeOnMove={false}
                closeButton={false}
                closeOnClick={false}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {isAtletica ? (
                    <div>{item.name}</div>
                  ) : (
                    <>
                      <div
                        onClick={() => handleEditPlace(item)}
                        style={{
                          width: 14,
                          height: 14,
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <FiEdit color="var(--purple01)" size={14} />
                      </div>
                      <div
                        onClick={() => handleDeletePlace(item)}
                        style={{
                          width: 14,
                          height: 14,
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          marginLeft: 4,
                        }}
                      >
                        <FiTrash2 color="var(--error)" size={14} />
                      </div>
                    </>
                  )}
                </div>
              </Popup>
            </>
          ))}
          {selected && (
            <>
              <Marker
                longitude={selected.center[0]}
                latitude={selected.center[1]}
                color="var(--error)"
              />
              <Popup
                longitude={selected.center[0]}
                latitude={selected.center[1]}
                anchor="bottom"
                offset={24}
                closeOnMove={false}
                closeButton={false}
              >
                <div
                  onClick={() => handleAddPlace(selected)}
                  style={{
                    width: 14,
                    height: 14,
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <FiPlus color="var(--success)" size={14} />
                </div>
              </Popup>
            </>
          )}
        </Map>
      </MapContainer>
      )}
      <ModalPlace
        showModalOpen={showModalOpen}
        toggleShowModalOpen={toggleShowModalOpen}
        place={editPlace}
        closeSelectedPlace={closeSelectedPlace}
      />
      <ModalWaring
        showModalOpen={showPlaceModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar praça esportiva"
        message="Tem certeza que deseja deletar essa praça esportiva?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delPlace}
      />
    </Container>
  );
}

export default Places;
