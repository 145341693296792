import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAdmin } from "../../types";


export async function auxUpdateAdmin(admin: IAdmin, setAdmins: SetterOrUpdater<IAdmin[]>, state: IAdmin[]) {
    try {
        api.put(`/admins/update/${admin.id}`, {
            responsibility: admin?.responsibility
        }).then(response => {
            const index = state.findIndex(item => item.id === admin.id);
            const updatedAdmins = state.slice();
            updatedAdmins[index] = response.data;
            setAdmins(updatedAdmins);
        })
    } catch (e) {
        console.log(e)
    }
}

export async function auxRemoveAdmin(admin_id: string, setAdmins: SetterOrUpdater<IAdmin[]>, state: IAdmin[]) {
    try {
        api.delete(`/admins/${admin_id}`).then(response => {
            const filteredAdmins = state.filter(admin => admin.id !== admin_id)
            setAdmins(filteredAdmins);
        })
    } catch (e) {
        console.log(e)
    }
}

export async function auxAddAdmin(admin: Omit<IAdmin, 'id'>, setAdmins: SetterOrUpdater<IAdmin[]>, state: IAdmin[]) {
    try {
        api.post('/admins', admin).then(response => {
            setAdmins([...state, response.data]);
        })
    } catch (e) {
        console.log(e)
    }
}