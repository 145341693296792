/* eslint-disable import/order */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes';

import AppProvider from './hooks';
import { ToastContainer } from 'react-toastify';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      </AppProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
};

export default App;
