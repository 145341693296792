import styled from 'styled-components';

interface IconContainerProps {
  type: 'edit' | 'del';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem auto;
  height: calc(100vh - 10rem);
  overflow-y: auto;

  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & + & {
    margin-left: 1rem;
  }

  @media (max-width: 820px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
      props.type === 'del' ? 'var(--error)' : 'var(--purple02)'};
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    svg {
      margin-right: 0.5rem;
    }
  }
  color: ${props =>
    props.type === 'del' ? 'var(--error)' : 'var(--purple02)'};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
`;

export const AddNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border: 2px solid var(--purple02);
  color: var(--purple02);
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;

  svg {
    margin-right: 0.5rem;
  }

  cursor: pointer;
`;
