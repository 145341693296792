import MoonLoader from 'react-spinners/MoonLoader';
import { useData } from '../../hooks/context';

import { Page } from './styles';

export function LoaderPage() {
  const { loading } = useData();

  return loading ? (
    <Page>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          alignItems: 'center',
        }}
      >
        <MoonLoader color="#3B1467" loading speedMultiplier={0.75} />
        Carregando...
      </div>
    </Page>
  ) : (
    <></>
  );
}
