/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { IIndividuals } from '../../../../types';

import { Container, Header, Content } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import Input from '../../../../components/Input';
import { useData } from '../../../../hooks/context';

import Button from '../../../../components/Button';
import Points from '../../../../components/Points';
import produce from 'immer';
import Checkbox from '../../../../components/Checkbox';

interface ModalCategoryProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  individual: IIndividuals;
}

interface IFormData {
  name: string;
  option: string;
}

function ModalCategory({
  showModalOpen,
  toggleShowModalOpen,
  individual,
}: ModalCategoryProps) {
  const formRef = useRef<FormHandles>(null);
  const { useIndividual, useSettings } = useData();
  const { settings } = useSettings;

  const [pointsTemp, setPointsTemp] = useState<number[]>(Array(16).fill(0));
  const [hasMatchTemp, setHasMatchTemp] = useState<boolean>(false);

  useEffect(() => {
    if (settings.standard_points) {
      setPointsTemp(settings.standard_points);
    }
  }, [settings]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          option: Yup.string().max(6, 'Maximo de 6 caracteres')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let option = !!data.option ? [
          {
            label: 'Atleta',
            value: [],
          },
          {
            label: data.option,
            value: [],
          },
        ] : [
          {
            label: 'Atleta',
            value: [],
          }
        ]

        useIndividual.addCategory({
          individual_id: individual.id,
          name: data.name,
          options: option,
          points: pointsTemp,
          hasMatch: hasMatchTemp
        });
        toast.success('Categoria criada com sucesso!');

        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Place');
      }
    },
    [useIndividual, individual.id, pointsTemp, toggleShowModalOpen, hasMatchTemp],
  );

  const handleChangePoints = useCallback(
    (value: number, index: number) => {
      const points = produce(pointsTemp, draft => {
        draft[index] = value;
        return draft
      });
      setPointsTemp(points);
    },
    [pointsTemp],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Nova categoria</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="name" label="Nome" placeholder="Nome" />
            <Input
              name="option"
              label="Opção adicional"
              placeholder="Ex.: Tempo (s), altura (m)..."
            />
            <Checkbox
              name="hasMatch"
              value="hasMatch"
              containerStyle={{ marginTop: 8 }}
              checked={hasMatchTemp}
              onChange={e => setHasMatchTemp(e.target.checked)}
            >
              <div>Categoria com chaveamento</div>
            </Checkbox>
            <div>Pontuação:</div>
            <Points onChange={handleChangePoints} value={pointsTemp} />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button type="submit" buttonStyle="primary" theme="solid">
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}

export default ModalCategory;
