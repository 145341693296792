import styled, { css } from 'styled-components';

interface ContainerProps {
  buttonStyle: 'primary' | 'success' | 'danger';
  theme?: 'hollow' | 'solid';
}

const type = {
  primary: {
    color: 'var(--purple01)',
    colorShade: 'var(--purple01-shade)',
  },
  success: {
    color: 'var(--success)',
    colorShade: 'var(--success-shade)',
  },
  danger: {
    color: 'var(--error)',
    colorShade: 'var(--error-shade)',
  },
};

const buttonType = (
  typeColor: 'primary' | 'success' | 'danger',
  theme: 'hollow' | 'solid',
) => {
  const colorMain = type[typeColor].color;
  const { colorShade } = type[typeColor];

  if (theme === 'hollow') {
    return css`
      background: var(--color-button-text);
      border: 1px solid ${colorMain};
      color: ${colorShade};
      &:hover {
        background: var(--color-button-text);
        border: 1px solid ${colorShade};
      }
    `;
  }
  return css`
    background: ${colorMain};
    color: var(--color-button-text);
    &:hover {
      background: ${colorShade};
    }
  `;
};
export const Container = styled.button<ContainerProps>`
  min-height: 4rem;
  max-height: 6rem;
  border-radius: 0.75rem;
  border: 0;
  padding: 0 1rem;
  width: 100%;
  font-weight: 500;
  margin-top: 1rem;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: auto 0.5rem;
  }

  ${props => buttonType(props.buttonStyle, props.theme)}
`;
