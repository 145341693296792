/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { FiUpload, FiXCircle } from 'react-icons/fi';
import { Container } from './styles';
import { toast } from 'react-toastify';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: any;
  uploadPhoto: (picture: any) => Promise<string>;
  file?: string;
  handleRemoveFile: () => void;
}

const FilePicker: React.FC<InputProps> = ({
  containerStyle = {},
  uploadPhoto,
  file,
  handleRemoveFile,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handlePhoto = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        if(e.target.files[0].size <= 3 * 1024 * 1024) {
          setLoading(true);
          uploadPhoto(e.target.files[0]).then(() => {
            setLoading(false);
          });
        } else {
          toast.error('Arquivo muito grande. (máx 3Mb)')
        }
      }
    },
    [uploadPhoto],
  );

  const handleRemove = useCallback(() => {
    handleRemoveFile()
  }, [handleRemoveFile])

  return (
    <Container
      style={containerStyle}
      isErrored={!!false}
      isFilled={!!file}
      isFocused={false}
    >
      {file ? (
        <div>
          <a href={file} rel="noreferrer" download target="_blank">Download</a>
          <FiXCircle onClick={handleRemove} style={{marginLeft: 8}}/>
        </div>
      ) : (
        <label htmlFor="photo">
          <input onChange={handlePhoto} type="file" id="photo" {...rest} />
          Upload
          {loading ? (
            <div className="loading">
              <MoonLoader color="#3B1467" loading size={20} />
            </div>
          ) : (
            <FiUpload style={{marginLeft: 8}}/>
          )}
        </label>
      )}
    </Container>
  );
};

export default FilePicker;
