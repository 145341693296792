import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  buttonStyle: 'primary' | 'success' | 'danger';
  theme?: 'hollow' | 'solid';
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  buttonStyle,
  theme = 'solid',
  type='button',
  ...rest
}) => (
  <Container buttonStyle={buttonStyle} theme={theme} type={type} {...rest}>
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;
