import styled from 'styled-components';

export const Page = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(229, 222, 240, 0.7);
`;
