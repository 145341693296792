import React from 'react';
import { Tables } from './components/Tables';

import { Container } from './styles';

function Dashboard() {

  return (
    <Container>
      <Tables />
    </Container>
  );
}

export default Dashboard;
