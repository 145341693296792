/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FiChevronsLeft,
  FiChevronsRight,
  FiLogOut,
  FiMenu,
} from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import global from '../../config/global';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { Avatar } from '../Avatar';
import { LoaderPage } from '../LoaderPage';

import {
  Page,
  Menu,
  Header,
  Logo,
  Title,
  ItemMenu,
  Profile,
  Responsibility,
  Children,
  WithoutLogo,
  ToggleButton,
  Logout,
  ItemSubMenu
} from './styles';
import Select from '../Select';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { store } from '../../types/store';
import { useEdition } from '../../hooks/edition';

interface AdminMenuProps {
  children: ReactNode;
}

interface SelectValue {
  value: string;
  label: string;
}

export function AdminMenu({ children }: AdminMenuProps) {
  const { admin, signOut } = useAuth();
  const { useSettings } = useData();
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useSettings;
  const [menuType, setMenuType] = useState<'admin' | 'caster' | 'atletica' | null>(null);
  const formRef = useRef<FormHandles>(null)
  const { edition, setEdition } = useEdition()
  const [editionSelect, setEditionSelect] = useState<SelectValue>({} as SelectValue)

  const editionOptions = useMemo(() => {
    const jogos = ['JF', 'IM'].includes(process.env.REACT_APP_JOGOS || '') ? process.env.REACT_APP_JOGOS || "JF" : 'JF';
    return store.editions[jogos as 'JF' | 'IM']
  }, []);

  useEffect(() => {
    const findEdition = editionOptions.find(item => item.value === edition)
    if(findEdition){
      setEditionSelect(findEdition)
    } else {
      setEditionSelect({} as SelectValue)
    }
  }, [edition, editionOptions])

  useEffect(() => {
    if(admin.responsibility){
      switch (admin.responsibility) {
        case 'caster':
          setMenuType('caster')
          break;
        case 'atletica':
          setMenuType('atletica')
          break;
        case 'admin':
        case 'presidente':
        case 'vice-presidente':
        case 'tesouraria':
        case 'esportes':
        case 'secretaria':
        case 'eventos':
          setMenuType('admin')
          break;
        default:
          setMenuType(null)
          break;
      }
    }
  }, [admin])

  const handleEdition = useCallback((value: SelectValue) => {
    setEdition(value.value)
  }, [setEdition])


  const [showMenu, setShowMenu] = useState(false);

  const [menuOpened, setMenuOpened] = useState(false);
  const [initialState, setInitialState] = useState(true);

  const handleMenuOpen = () => {
    setMenuOpened(!menuOpened);
    setInitialState(false);
  };

  const handleClick = useCallback(
    (path: string) => {
      navigate(path);
      setMenuOpened(false);
      setInitialState(false);
    },
    [navigate],
  );

  return (
    <>
      <Page>
        <Menu
          initialState={initialState}
          showMenu={showMenu}
          menuOpened={menuOpened}
        >
          <ToggleButton>
            {showMenu ? (
              <div onClick={() => setShowMenu(false)}>
                <FiChevronsRight />
              </div>
            ) : (
              <div onClick={() => setShowMenu(true)}>
                <FiChevronsLeft />
              </div>
            )}
          </ToggleButton>
          <Header
            onClick={() =>
              menuType === 'admin' ? handleClick('/settings') : {}
            }
          >
            {settings.avatar ? (
              <Logo src={settings.avatar} />
            ) : (
              <WithoutLogo>
                <div>E</div>
              </WithoutLogo>
            )}
            {!showMenu && <Title>{settings?.title || 'Configurações'}</Title>}
          </Header>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <Form ref={formRef} onSubmit={() => {}}>
              <Select
                label='Edição'
                name='edition'
                options={editionOptions}
                value={editionSelect}
                onChange={handleEdition}
              />
            </Form>
          </div>
          <div className="menuIcon" onClick={handleMenuOpen}>
            <FiMenu />
          </div>
          <div className="items">
            <div className="menuItems">
              {menuType && global[menuType].map(item => item.subMenu.length > 0 ? (
                <ItemMenu
                  key={item.path + item.title}
                  selected={location.pathname === `/${item.path}`}
                  showMenu={showMenu}
                >
                  {!showMenu && (
                    <div className='title-menu'>
                      {item.title}
                      <hr />
                    </div>
                  )}
                  {item.subMenu.map((sub, i) => (
                    <ItemSubMenu
                      key={sub.path + i}
                      onClick={() => handleClick(sub.path)}
                      selected={location.pathname === `/${sub.path}`}
                      showMenu={showMenu}
                      isSubMenu={false}
                    >
                      <sub.icon />
                      {!showMenu && sub.title}
                    </ItemSubMenu>
                  ))}
                </ItemMenu>
              ) : (
                <ItemSubMenu
                  key={item.path}
                  onClick={() => handleClick(item.path)}
                  selected={location.pathname === `/${item.path}`}
                  showMenu={showMenu}
                  isSubMenu={false}
                >
                  <item.icon />
                  {!showMenu && item.title}
                </ItemSubMenu>
              ))}
            </div>
            <div className="profile">
              <Profile
                showMenu={showMenu}
                onClick={() =>
                  handleClick(
                    admin?.responsibility !== 'atletica' ? 'admin' : '/profile',
                  )
                }
              >
                <Avatar
                  name={admin.name}
                  avatar={admin.avatar}
                  width={showMenu ? '3rem' : '3.5rem'}
                  height={showMenu ? '3rem' : '3.5rem'}
                />
                {!showMenu && (
                  <div>
                    <Title>{admin.name}</Title>
                    {admin?.responsibility !== 'atletica' && (
                      <Responsibility>{admin?.responsibility}</Responsibility>
                    )}
                  </div>
                )}
              </Profile>
              <Logout onClick={() => signOut()}>
                Sair
                {!showMenu && <FiLogOut />}
              </Logout>
            </div>
          </div>
        </Menu>
        <Children showMenu={showMenu}>{children}</Children>
      </Page>
      <LoaderPage />
      </>
  );
}
