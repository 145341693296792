import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAtletica } from "../../types";


export async function auxUpdateAtletica(atletica: IAtletica, setAtleticas: SetterOrUpdater<IAtletica[]>, state: IAtletica[]) {
  try {
    api.put(`/atleticas/${atletica.id}`, atletica).then(response => {
      const index = state.findIndex(item => item.id === atletica.id);
      const updatedAtletica = state.slice();
      updatedAtletica[index] = response.data;
      setAtleticas(updatedAtletica);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemoveAtletica(atletica_id: string, setAtleticas: SetterOrUpdater<IAtletica[]>, state: IAtletica[]) {
  try {
    api.delete(`/atleticas/${atletica_id}`).then(response => {
      const filteredAtleticas = state.filter(atletica => atletica.id !== atletica_id)
      setAtleticas(filteredAtleticas);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddAtletica(atletica: Omit<IAtletica, 'id'>, setAtleticas: SetterOrUpdater<IAtletica[]>, state: IAtletica[]) {
  try {
    api.post('/atleticas', atletica).then(response => {
      setAtleticas([...state, response.data]);
    })
  } catch (e) {
    console.log(e)
  }
}