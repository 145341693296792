/* eslint-disable no-param-reassign */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { IMatch, IIndividuals, ICategory } from '../../../../types';

import { Container, Header, Content } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import Select from '../../../../components/Select';
import { useData } from '../../../../hooks/context';

import Button from '../../../../components/Button';

interface ModalSorteioProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  individual: IIndividuals;
  category: ICategory;
}

interface SelectProps {
  value: any;
  label: string;
}

const sorteio = [
  [1, 32],
  [16, 17],
  [9, 24],
  [8, 25],
  [5, 28],
  [12, 21],
  [13, 20],
  [4, 29],
  [2, 31],
  [15, 18],
  [10, 23],
  [7, 26],
  [6, 27],
  [11, 22],
  [14, 19],
  [3, 30],
]

function ModalSorteio({
  showModalOpen,
  toggleShowModalOpen,
  individual,
  category
}: ModalSorteioProps) {
  const formRef = useRef<FormHandles>(null);
  const { useIndividual } = useData();

  const [atleticasOrder, setAtleticasOrder] = useState<
    Array<SelectProps | undefined>
  >(Array(32).fill(undefined));

  useEffect(() => {
    if(category.matches){
      const matches = category?.matches.slice(0, 16)
      let atleticas: Array<SelectProps | undefined> = Array(32).fill(undefined);
      matches.forEach((match, index) => {
        atleticas[sorteio[index][0] - 1] = match?.atletica_id1 ? {label: match.atletica1[0]?.name, value: match.atletica_id1} : undefined
        atleticas[sorteio[index][1] - 1] = match?.atletica_id2 ? {label: match.atletica2[0]?.name, value: match.atletica_id2} : undefined
      })
      setAtleticasOrder(atleticas)
    }
  }, [category])

  const handleSubmit = useCallback(
    async (data: IMatch) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const allAtleticas = atleticasOrder.map(
          item => item?.value || undefined,
        );

        useIndividual.handleDraftIndividualMatch(allAtleticas, category.id, individual.id);

        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        console.log({err})
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Place');
      }
    },
    [atleticasOrder, toggleShowModalOpen, useIndividual, category.id, individual.id],
  );

  const handleChangeSelect = useCallback(
    (value: any, index: number) => {
      const updateAtleticas = produce(atleticasOrder, draft => {
        draft[index] = value;
        return draft;
      });
      setAtleticasOrder(updateAtleticas);
    },
    [atleticasOrder],
  );

  const getOptions = useCallback(() => {
    const aaa = individual.atleticas.map(aaa => ({
      value: aaa.id,
      label: aaa.name,
    }));
    return aaa.filter(
      item => atleticasOrder.filter(a => a?.value === item.value).length <= 1,
    );
  }, [atleticasOrder, individual]);


  const handleSetAtleticasAuto = useCallback((order: Array<SelectProps | undefined>) => {
    let tries = 0;

    while (tries < 10) {
      const newAtleticasOrder: Array<SelectProps | undefined> = order.slice();
      let freeNumbers = newAtleticasOrder.map((item, index) => !item ? index : undefined).filter(item => item !== undefined);
      const allNumbersDraw: Array<number> = [];

      const aaa = [...individual.atleticas, ...individual.atleticas].map(aaa => ({
        value: aaa.id,
        label: aaa.name,
      }));

      const countAAA: {[key: string]: number} = order.reduce((acc, item) => item ? {...acc, [item.value]: (acc[item.value] || 0) + 1 } : {...acc}, {} as {[key: string]: number});
      let aaaFiltered: Array<SelectProps> = [];
      for(var i = 0; i < aaa.length; i++){
        if(countAAA[aaa[i].value] && countAAA[aaa[i].value] > 0){
          countAAA[aaa[i].value] -= 1;
        } else {
          aaaFiltered.push(aaa[i])
        }
      }

      const aaaLength = aaaFiltered.length
      let leftAAA = aaaLength;
      const leftSide = [1, 32, 16, 17, 9, 24, 8, 25, 5, 28, 12, 21, 13, 20, 4, 29]

      let avoidLoop = 0;
      while (leftAAA > 0) {
        const numberDraw = Math.floor(Math.random() * aaaLength);
        if (!allNumbersDraw.includes(numberDraw)) {
          allNumbersDraw.push(numberDraw);
          if(freeNumbers[0] !== undefined){
            const checkIfHasAAA = newAtleticasOrder.findIndex(item => item?.value === aaaFiltered[numberDraw].value);
            if(checkIfHasAAA > -1){
              const isLeftSide = leftSide.includes(checkIfHasAAA + 1);
              let nextPosition = 0;
              while (nextPosition < 3) {
                if(freeNumbers[nextPosition] !== undefined){
                  const validPosition = isLeftSide ? !leftSide.includes((freeNumbers[nextPosition] || 0) + 1) : leftSide.includes((freeNumbers[nextPosition] || 0) + 1);
                  if(validPosition){
                    newAtleticasOrder[(freeNumbers[nextPosition] || 0)] = aaaFiltered[numberDraw];
                    leftAAA -= 1;
                    freeNumbers.splice(nextPosition, 1);
                    nextPosition = 4;
                  } else {
                    nextPosition += 1;
                  }
                }
              }
            } else {
              newAtleticasOrder[freeNumbers[0]] = aaaFiltered[numberDraw];
              leftAAA -= 1;
              freeNumbers.shift();
            }
          }
        }
        avoidLoop += 1;
        if(avoidLoop > 1000){
          tries = tries + 1
          break;
        }
      }

      if(leftAAA === 0){
        tries = 10;
      }
      
      if(aaaLength < 32){
        const leftOverAAA = 32 - newAtleticasOrder.length;
        for(var t = 0; t < leftOverAAA ; t++){
          newAtleticasOrder.push({label: '', value: undefined})
        }
      }

      setAtleticasOrder(newAtleticasOrder);
    }
  }, [individual]);

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      {individual.id && (
        <Container>
          <Header>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <h2>Sorteio</h2>
              <div
                style={{
                  marginLeft: '1rem',
                  color: 'var(--purple02)',
                  fontSize: '1.25rem',
                  textDecoration: 'underline',
                }}
                onClick={() => handleSetAtleticasAuto(atleticasOrder)}
              >
                gerar automaticamente
              </div>
            </div>
            <div onClick={toggleShowModalOpen}>
              <FiX />
            </div>
          </Header>
          <Content>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <>
                <div className="selects">
                  {atleticasOrder.map((item, index) => (
                    <Select
                      labelPosition="left"
                      label={`Atlética #${index + 1}`}
                      name={`atletica-${index}`}
                      options={getOptions()}
                      value={item?.value ? item : undefined}
                      onChange={value => handleChangeSelect(value, index)}
                      placeholder="Selecione"
                    />
                  ))}
                </div>
                <div className="buttons">
                  <Button
                    buttonStyle="primary"
                    theme="hollow"
                    onClick={toggleShowModalOpen}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" buttonStyle="primary" theme="solid">
                    Salvar
                  </Button>
                </div>
              </>
            </Form>
          </Content>
        </Container>
      )}
    </Modal>
  );
}

export default ModalSorteio;
