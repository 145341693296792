import styled from 'styled-components';

interface FormContainerProps {
  line: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 10rem);
  margin: 4rem auto;
  overflow-y: auto;

  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
    height: 100%;

    > form {
      max-width: 100%;
      margin: 0 !important;

      > .content {
        flex-direction: column-reverse !important;
        max-width: 100%;
      }

      .image {
        margin-bottom: 3rem;
      }

      .buttons {
        width: 100%;
        gap: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 50vh;
    height: 100%;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: 1110px;

    .buttons {
      margin-top: 24;
      margin-left: auto;
      margin-right: auto;
      max-width: 570;
      min-width: 400;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: center;
    }

    .image {
      width: 20rem;
      height: 20rem;
    }

    > .content {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin-right: 1rem;
  justify-content: center;
  align-items: flex-start;
  color: var(--color-text-in-background);

  @media (max-width: 820px) {
    margin-right: 0;
  }
`;

export const FormContainer = styled.div<FormContainerProps>`
  display: grid;
  grid-template-columns: ${props => props.line};
  gap: 1rem;
  margin-bottom: 1rem;

  > div {
    margin-top: 0;
  }
`;
