/* eslint-disable dot-notation */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */
import axios, { AxiosError } from 'axios';
const token = localStorage.getItem('@AdminControl:token');
let isRefreshing = false;
let failedRequestsQueue: any[] = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    authorization: `Bearer ${token}`,
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data?.code === 'token.expired') {
        const originalConfig = error.config;

        const refreshToken = localStorage.getItem('@AdminControl:refreshToken');
        const admin = JSON.parse(
          localStorage.getItem('@AdminControl:admin') || '',
        );

        if (!isRefreshing) {
          isRefreshing = true;

          let url;
          switch (admin.responsibility) {
            case 'atletica':
              url = '/sessions/user'
              break;
            case 'caster':
              url = '/sessions-caster/admin'
              break;
            default:
              url = '/sessionsadm/admin'
              break;
          }

          api
            .post(`${url}/refreshToken`, {
              refreshToken,
              admin_id: admin.id,
            })
            .then(response => {
              const { token, refreshToken } = response.data;

              localStorage.setItem('@AdminControl:token', token);
              localStorage.setItem('@AdminControl:refreshToken', refreshToken);

              api.defaults.headers.common.authorization = `Bearer ${token}`;

              failedRequestsQueue.forEach(request => request.onSuccess(token));
              failedRequestsQueue = [];
            })
            .catch(err => {
              failedRequestsQueue.forEach(request => request.onFailure(err));
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers = { authorization: `Bearer ${token}` };
              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }

      localStorage.removeItem('@AdminControl:token');
      localStorage.removeItem('@AdminControl:admin');
      localStorage.removeItem('@AdminControl:refreshToken');
    } else {
      return Promise.reject(error);
    }
  },
);

export default api;
