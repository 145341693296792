/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FiX } from 'react-icons/fi';
import Button from '../Button';
import Modal from '../Modal';

import { Container, Content, Header } from './styles';

interface ModalOrgProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  title: string;
  message?: string;
  handleLeftButton?: () => void;
  handleRightButton?: () => void;
  textLeftButton?: string;
  textRightButton?: string;
}

export function ModalWaring({
  showModalOpen,
  toggleShowModalOpen,
  title,
  message,
  handleLeftButton,
  handleRightButton,
  textLeftButton = '',
  textRightButton = '',
}: ModalOrgProps) {
  return (
    <Modal
      isOpen={showModalOpen}
      setIsOpen={toggleShowModalOpen}
      maxWidth="480px"
    >
      <Container>
        <Header>
          <h2>{title}</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          {message && <div>{message}</div>}
          <div className="buttons">
            {handleLeftButton && (
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={handleLeftButton}
              >
                {textLeftButton}
              </Button>
            )}
            {handleRightButton && (
              <Button
                onClick={handleRightButton}
                buttonStyle="primary"
                theme="solid"
              >
                {textRightButton}
              </Button>
            )}
          </div>
        </Content>
      </Container>
    </Modal>
  );
}
