/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/require-default-props */
import { AvatarProfile, WithoutAvatar, WithAvatar } from './styles';

interface AvatarProps {
  avatar?: string;
  name: string;
  width?: string;
  height?: string;
}

export function Avatar({ avatar, name, width, height }: AvatarProps) {
  return (
    <AvatarProfile width={width} height={height}>
      {avatar ? (
        <WithAvatar src={avatar} alt="avatar" />
      ) : (
        <WithoutAvatar>{name ? name[0] : 'E'}</WithoutAvatar>
      )}
    </AvatarProfile>
  );
}
