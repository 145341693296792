/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { IAccommodation } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import FormContainer from '../../../../components/FormContainer';
import Select from '../../../../components/Select';
import Chips from '../../../../components/Chips';

interface ModalAccommodationProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
}
interface SelectValue {
  value: string;
  label: string;
}

export function ModalAccommodation({
  showModalOpen,
  toggleShowModalOpen,
}: ModalAccommodationProps) {
  const formRef = useRef<FormHandles>(null);
  const { useAtleticas, usePlace, loading, useAccommodations } = useData();

  const { places } = usePlace;
  const { atleticas } = useAtleticas;
  const [atleticaTemp, setAtleticaTemp] = useState<SelectValue[]>([]);
  const [placeTemp, setPlaceTemp] = useState<SelectValue>({} as SelectValue);

  const handleSubmit = useCallback(
    async (data: IAccommodation) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          place_id: Yup.string().required('Alojamento obrigatório'),
          room: Yup.number().required('Sala obrigatório'),
          security: Yup.number().required('Segurança obrigatório'),
          bathroom: Yup.number().required('Banheiros obrigatório'),
          available: Yup.number().required('Total obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,

        });
        useAccommodations.addAccommodation(data)
        toast.success('Alojamento criado com sucesso!');
        formRef.current?.reset();
        setAtleticaTemp([]);
        setPlaceTemp({} as SelectValue)
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Alojamento');
      }
    },
    [toggleShowModalOpen, useAccommodations],
  );

  const handleChangeAtletica = useCallback((value: SelectValue[]) => {
    setAtleticaTemp(value);
  }, []);

  const handleChange = useCallback((value: SelectValue) => {
    setPlaceTemp(value);
  }, []);

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Alojamento</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              name="place_id"
              label='Alojamento:'
              placeholder='Unioeste'
              options={places.map(place => (
                {
                  value: place.id,
                  label: place.name
                }
              ))}
              onChange={handleChange}
              value={placeTemp}
            />
            <FormContainer line='1fr 1fr'>
              <Input type="number" name="room" placeholder='4' label='N. de quartos' />
              <Input type="number" name="available" placeholder='400' label='N. de alojados' />
            </FormContainer>
            <FormContainer line='1fr 1fr'>
              <Input type="number" name="security" placeholder='2' label='N. de seguranças' />
              <Input type="number" name="bathroom" placeholder='5' label='N. de banheiros' />
            </FormContainer>
            <Input name="description" placeholder="Descrição" label="Descrição" />
            <Chips
              name="atleticas"
              label="Atléticas:"
              options={atleticas.map(aaa => ({
                value: aaa.id,
                label: aaa.name,
              }))}
              value={atleticaTemp}
              onChange={handleChangeAtletica}
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
