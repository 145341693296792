/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useData } from '../../../../hooks/context';
import { uploadPhotoAdmin } from '../../../../utils/uploadPhotoAdmin';
import Button from '../../../../components/Button';
import ImagePicker from '../../../../components/ImagePicker';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { IAtletica } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import FormContainer from '../../../../components/FormContainer';

interface ModalAtleticaProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
}

interface AtleticaData extends IAtletica {
  password_confirmation: string;
}

export function ModalAtletica({
  showModalOpen,
  toggleShowModalOpen,
}: ModalAtleticaProps) {
  const formRef = useRef<FormHandles>(null);
  const [image, setImage] = useState('');
  const { useAtleticas, loading } = useData();

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setImage(response);
        return response;
      }
      return '';
    });
  }
  const handleSubmit = useCallback(
    async (data: AtleticaData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Apelido obrigatório'),
          fullName: Yup.string().required('Nome completo obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 digitos'),
          password_confirmation: Yup.string()
            .min(6, 'No mínimo 6 digitos')
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        useAtleticas.addAtletica({
          ...data,
          avatar: image,
        });

        toast.success('Atlética criada com sucesso!');
        formRef.current?.reset();
        setImage('');
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Atletica');
      }
    },
    [image, toggleShowModalOpen, useAtleticas],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Atlética</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer line="1fr 3fr">
              <div style={{ paddingBottom: '1rem' }}>
                <ImagePicker uploadPhoto={handlePhoto} image={image} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  alignItems: 'start',
                }}
              >
                <Input
                  name="fullName"
                  placeholder="A. A. A. Exatas - Unioeste Foz do Iguaçu"
                  label="Nome completo:"
                />
                <Input name="name" placeholder="Extas Unioeste" label="Nome:" />
                <FormContainer line="1fr 1fr">
                  <Input name="nickname" placeholder="Rino" label="Apelido:" />
                  <Input
                    name="city"
                    placeholder="Foz do Iguaçu"
                    label="Cidade:"
                  />
                </FormContainer>
              </div>
            </FormContainer>
            <Input name="email" placeholder="E-mail" label="E-mail:" />
            <FormContainer line="1fr 1fr">
              <Input name="password" placeholder="Senha" label="Senha:" />
              <Input
                name="password_confirmation"
                placeholder="Confirmação da Senha"
                label="Confirmação da Senha:"
              />
            </FormContainer>
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
