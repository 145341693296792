import styled from 'styled-components';

interface IconContainerProps {
  type: 'edit' | 'del';
  theme?: 'hollow' | 'solid';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 8rem);
  margin: 4rem auto;
  overflow-y: auto;

  
  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
  }

`;

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  & + & {
    margin-left: 2rem;
  }

  @media (max-width: 820px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.type === 'del' ? 'var(--error)' : 'var(--purple02)'};
    background-color: ${props => props.theme === 'hollow' ? 'transparent' : props.type === 'del' ? 'var(--error)' : 'var(--purple02)'};
    color: ${props => props.theme !== 'hollow' ? 'white' : props.type === 'del' ? 'var(--error)' : 'var(--purple02)'};;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    & + & {
      margin-left: 1rem;
    }
    svg {
      margin: 0 0.5rem;
    }
  }
  color: ${props => props.type === 'del' ? 'var(--error)' : 'var(--purple02)'};

`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
`;

export const AddNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border: 2px solid var(--purple02);
  color: var(--purple02);
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;

  svg {
    margin-right: 0.5rem;
  }

  cursor: pointer;
`;