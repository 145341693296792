import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';
import { AlertError, Container, ContainerInput } from './styles';
import { FiAlertCircle } from 'react-icons/fi';

interface Props extends SelectProps {
  name: string;
  containerStyle?: any;
  label?: string;
  placeholder?: string;
  value?: { value: any; label: string };
  onChange?: (value: any) => void;
  labelPosition?: 'top' | 'left';
  width?: string;
}

const Select: React.FC<Props> = ({
  name,
  containerStyle = {},
  width,
  label,
  value = {},
  onChange,
  placeholder = 'Selecione uma opção',
  labelPosition = 'top',
  ...rest
}) => {
  const customStyles = {
    container: () => ({
      width: '100%',
    }),

    control: () => ({
      width: '100%',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 999,
    }),
  };

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [valueTemp, setValueTemp] = useState(value);
  const selectRef = useRef<any>(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state?.selectValue) {
            return [];
          }
          return ref.state?.selectValue.map((option: any) => option.value);
        }
        if (!ref.state?.selectValue) {
          return '';
        }
        return ref.state?.selectValue[0]?.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    setValueTemp(value);
  }, [value]);

  const handleChange = useCallback(
    (value: any) => {
      setValueTemp(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.state.selectValue);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <ContainerInput width={width} labelPosition={labelPosition}>
      {label && <div className="label">{label}</div>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <ReactSelect
          styles={customStyles}
          ref={selectRef}
          value={valueTemp || undefined}
          onChange={e => handleChange(e)}
          classNamePrefix="react-select"
          menuPortalTarget={document.body}
          placeholder={placeholder}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          isClearable
          {...rest}
        />
        {error && (
          <AlertError title={error}>
            <FiAlertCircle color="#C53038" size={20} />
          </AlertError>
        )}
      </Container>
    </ContainerInput>
  );
};
export default Select;
