import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef, useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/Input';
import getValidationError from '../../utils/getValidationError';
import {
  Container,
  AnimationContainer,
  Content,
  Option,
  SelectedButton,
} from './styles';
import logo from '../../assets/logo.svg';
import api from '../../services/api';

interface SignInFormData {
  email: string;
  password: string;
}

function ForgotPassword() {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const options: Array<'C.O.' | 'Atléticas'> = ['C.O.', 'Atléticas'];
  const [selectValue, setSelectValue] = useState<'C.O.' | 'Atléticas'>('C.O.');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const url = selectValue === 'C.O.' ? 'passwordadm' : 'password';

        await api.post(`${url}/forgot`, {
          email: data.email,
        });

        setLoading(false);

        toast.success('Sucesso, confira o seu email.');

        navigate('login');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);

        toast.error('Ocorreu um erro ao tentar recurperar a senha.');
      }
    },
    [navigate, selectValue],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img src={logo} alt="" />
            <div style={{ margin: 16 }}>Recuperação de senha</div>
            <SelectedButton>
              {options.map(item => (
                <Option
                  onClick={() => setSelectValue(item)}
                  selected={item === selectValue}
                  key={item}
                >
                  {item}
                </Option>
              ))}
            </SelectedButton>
            <Input
              icon={FiMail}
              name="email"
              placeholder="E-mail"
              label="E-mail"
            />
            <Button loading={loading} buttonStyle="primary" type="submit">
              Enviar
            </Button>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
}

export default ForgotPassword;
