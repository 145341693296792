import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  border: 1px solid var(--purple02);
  border-radius: 1rem;
  height: calc(100% - 2px);
  overflow: hidden;
  width: calc(46rem - 2px);
  

  @media (max-width: 820px) {
    width: 100%;
    max-width: 100%;
    min-height: 60vh;
  }

  @media (max-width: 500px) {
    .name {
      display: none !important;
    }
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--purple02);
  min-height: 5.5rem;

  > .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
    color: white;
    cursor: pointer;
  }

  > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 1.5rem;
    width: 100%;
    background-color: var(--purple02);
    color: white;
    min-height: 2.25rem;
    height: 50%;
  }

  > .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 0.1rem 1rem;
    min-height: 2.25rem;
    width: 100%;
    height: 50%;

    @media (max-width: 820px) {
      gap: 0;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .class {
      width: 3rem;
    }

    > .aaa {
      min-width: 15rem;
    }

    > .name {
      width: 15rem;
    }

    > .pts {
      width: 5rem;
    }

    > .extras {
      width: 8rem;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 0.1rem 1rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .class {
      width: 3rem;
    }

    > .aaa {
      min-width: 15rem;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        img {
          height: 2rem;
          margin-right: 0.25rem;
        }

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: 'flex-start';
          padding: 0.5rem 1rem;
          height: 3rem;
        }
      }
    }

    > .name {
      width: 15rem;
    }

    > .pts {
      width: 5rem;
    }

    > .extras {
      width: 8rem;
    }
  }
`;
