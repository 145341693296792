import { IAtletica, IMatch, IQualifier } from "../types";

export function getRestriction(match: IMatch, atleticas: IAtletica[], qualifiers: IQualifier[]): string[] {

    let restrictions: string[] = [match.id];

    const atletica1 = atleticas.find(atletica => atletica.id === match.atletica_id1);
    const atletica2 = atleticas.find(atletica => atletica.id === match.atletica_id2);

    if(atletica1){
        const restriction1 = findRestriction(match, atletica1, qualifiers);
        if(restriction1 !== ''){
            restrictions.push(restriction1);
        }
    }

    if(atletica2){
        const restriction2 = findRestriction(match, atletica2, qualifiers);
        if(restriction2 !== ''){
            restrictions.push(restriction2);
        }
    }
    
  return restrictions;
}

function findRestriction(match: IMatch, atletica: IAtletica, qualifiers: IQualifier[]) {
    let restriction = '';
    if(match.qualifier.type === 'Feminino' || match.qualifier.type === 'Masculino'){
        if(atletica && atletica.restrictions[match.qualifier.type]){
            const findRestriction = atletica.restrictions[match.qualifier.type]?.find(restriction => restriction && restriction.includes(match.qualifier.id));
            if(findRestriction){
                const leftQualifier = findRestriction.filter(restriction => restriction !== match.qualifier.id);
                const findQualifier = qualifiers.find(qualifier => leftQualifier.includes(qualifier.id));
                if(findQualifier){
                    const findMatch = findQualifier.matches.find(match => match.atletica_id1 === atletica?.id || match.atletica_id2 === atletica?.id);
                    if(findMatch){
                        restriction = findMatch.id
                    }
                }
            }
        }
    }
    return restriction;
}