/* eslint-disable react/jsx-curly-newline */
import { useCallback, useMemo, useState } from 'react';
import { FiChevronRight, FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Column } from 'react-table';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Individuals } from '../../Atoms';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { ModalIndividual } from './components/ModalIndividuals';

import {
  Container,
  IconContainer,
  Header,
  AddNew,
  ModalidadeTable,
} from './styles';

import athletics from '../../assets/sports-icons/athletics.svg';
import basketball from '../../assets/sports-icons/basketball.svg';
import beachVolleyball from '../../assets/sports-icons/beach-volleyball.svg';
import football from '../../assets/sports-icons/football.svg';
import futsal from '../../assets/sports-icons/futsal.svg';
import handball from '../../assets/sports-icons/handball.svg';
import judo from '../../assets/sports-icons/judo.svg';
import swimming from '../../assets/sports-icons/swimming.svg';
import tableTennis from '../../assets/sports-icons/table-tennis.svg';
import tennis from '../../assets/sports-icons/tennis.svg';
import volleyball from '../../assets/sports-icons/volleyball.svg';
import chess from '../../assets/sports-icons/chess.svg';
import logo from '../../assets/logo_s.svg';
import { individualClass } from '../../utils/individualClass';
import { IIndividuals } from '../../types';
import { ModalEditIndividual } from './components/ModalEditIndividuals';
import csgo from '../../assets/sports-icons/csgo.svg';
import clash from '../../assets/sports-icons/clash-royale.svg';
import fifa from '../../assets/sports-icons/fifa.svg';
import valorant from '../../assets/sports-icons/valorant.svg';
import lol from '../../assets/sports-icons/lol.svg';

const icons = [
  [athletics, 'athletics'],
  [basketball, 'basketball'],
  [beachVolleyball, 'beachVolleyball'],
  [football, 'football'],
  [futsal, 'futsal'],
  [handball, 'handball'],
  [judo, 'judo'],
  [swimming, 'swimming'],
  [tableTennis, 'tableTennis'],
  [tennis, 'tennis'],
  [volleyball, 'volleyball'],
  [chess, 'chess'],
  [csgo, 'csgo'],
  [clash, 'clash'],
  [fifa, 'fifa'],
  [valorant, 'valorant'],
  [lol, 'lol'],
];

function PageIndividuals() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const navigate = useNavigate();
  const [showIndividualModal, setShowIndividualModal] = useState(false);
  const [showEditIndividualModal, setShowEditIndividualModal] = useState(false);
  const [showIndividualModalDelete, setShowIndividualModalDelete] =
    useState(false);
  const [deleteIndividual, setDeleteIndividual] = useState('');
  const [editIndividual, setEditIndividual] = useState({} as IIndividuals);
  const { useIndividual, useSettings } = useData();
  const { settings } = useSettings;
  const individuals = useRecoilValue(Individuals);

  const toggleShowModalOpen = useCallback(() => {
    setShowIndividualModal(!showIndividualModal);
  }, [showIndividualModal]);

  const toggleShowEditModalOpen = useCallback(() => {
    setShowEditIndividualModal(!showEditIndividualModal)
  }, [showEditIndividualModal]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowIndividualModalDelete(!showIndividualModalDelete);
  }, [showIndividualModalDelete]);

  const handleEditQualifier = useCallback((edit: IIndividuals) => {
    setEditIndividual(edit)
    toggleShowEditModalOpen()
  }, [toggleShowEditModalOpen])

  const handleDeleteIndividual = useCallback(
    (id: string) => {
      setDeleteIndividual(id);
      toggleShowModalOpenDelete();
    },
    [toggleShowModalOpenDelete],
  );

  const getType = useCallback(
    (value: string) => {
      const type = settings.type.find(item => item.value === value);
      return type?.label || '';
    },
    [settings.type],
  );

  function getIcons(value: string) {
    const icon = icons.find((item: any[]) => item[1] === value);
    return icon ? icon[0] : logo;
  }

  const delIndividual = useCallback(() => {
    useIndividual.removeIndividual(deleteIndividual);
    toggleShowModalOpenDelete();
  }, [deleteIndividual, toggleShowModalOpenDelete, useIndividual]);

  const columns: Column<any>[] = useMemo(
    () =>
      isAtletica
        ? [
            {
              Header: 'Modalidade',
              Cell: ({ row }) => {
                return (
                  <ModalidadeTable
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="icons">
                      <img
                        src={getIcons(row.original.icon) || logo}
                        alt="icon"
                      />
                    </div>
                    <div className="label">{row.original.name}</div>
                  </ModalidadeTable>
                );
              },
            },
            {
              Header: 'Tipo',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {getType(row.original.type)}
                  </div>
                );
              },
            },
            {
              Header: 'Atleticas',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.atletica_ids?.length || 0}
                  </div>
                );
              },
            },
            {
              Header: 'Campeão',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.finished
                      ? individualClass(row.original)[0]?.atletica?.name
                      : 'Em andamento'}
                  </div>
                );
              },
            },
            {
              Header: 'Categorias',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconContainer
                      theme="hollow"
                      type="edit"
                      onClick={() =>
                        navigate(`/individuais/${row.original.id}`)
                      }
                    >
                      {widthScreen <= 820 && 'Categorias'}
                      <FiChevronRight />
                    </IconContainer>
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'Modalidade',
              Cell: ({ row }) => {
                return (
                  <ModalidadeTable
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="icons">
                      <img
                        src={getIcons(row.original.icon) || logo}
                        alt="icon"
                      />
                    </div>
                    <div className="label">{row.original.name}</div>
                  </ModalidadeTable>
                );
              },
            },
            {
              Header: 'Tipo',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {getType(row.original.type)}
                  </div>
                );
              },
            },
            {
              Header: 'Atleticas',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.atletica_ids?.length || 0}
                  </div>
                );
              },
            },
            {
              Header: 'Campeão',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.finished
                      ? individualClass(row.original)[0]?.atletica?.name
                      : 'Em andamento'}
                  </div>
                );
              },
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => {
                        handleEditQualifier(row.original)
                      }}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeleteIndividual(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
            {
              Header: 'Categorias',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconContainer
                      theme="hollow"
                      type="edit"
                      onClick={() =>
                        navigate(`/individuais/${row.original.id}`)
                      }
                    >
                      {widthScreen <= 820 && 'Categorias'}
                      <FiChevronRight />
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [getType, handleDeleteIndividual, handleEditQualifier, isAtletica, navigate, widthScreen],
  );

  return (
    <Container>
      <Header>
        <h1>Individuais</h1>
        {!isAtletica && (
          <AddNew onClick={toggleShowModalOpen}>
            <FiPlus />
            New
          </AddNew>
        )}
      </Header>
      <Table columns={columns} data={individuals} />
      <ModalIndividual
        showModalOpen={showIndividualModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalEditIndividual
        showModalOpen={showEditIndividualModal}
        toggleShowModalOpen={toggleShowEditModalOpen}
        individual={editIndividual}
      />
      <ModalWaring
        showModalOpen={showIndividualModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar individual"
        message="Tem certeza que deseja deletar esse individual?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delIndividual}
      />
    </Container>
  );
}

export default PageIndividuals;
