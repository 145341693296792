/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {  useCallback, useMemo } from 'react';
import { FiX } from 'react-icons/fi';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { useData } from '../../../../hooks/context';
import { qualifierClass } from '../../../../utils/qualifierClass';
import { individualPlayoffClass } from '../../../../utils/individualPlayoffClass';

interface ModalMedalProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
}

export function ModalMedal({
  showModalOpen,
  toggleShowModalOpen,
}: ModalMedalProps) {

  const { useQualifier, useIndividual, useAtleticas } = useData();
  const { individuals } = useIndividual;
  const { qualifiers } = useQualifier;
  const { atleticas } = useAtleticas

  const qualiRanking = useMemo(() => qualifiers.map(qualifier => (
    {
      id: qualifier.id,
      name: qualifier.name,
      type: qualifier.type,
      ranking: qualifierClass(qualifier)
    }
  )), [qualifiers]);


  const getAtletica = useCallback((id: string) => {
    const aaa = atleticas.find(item => item.id === id);
    return aaa || undefined;
  }, [atleticas])

  const individualRanking = useMemo(() => {
    let categories: any[] = []
    individuals.forEach(individual => {individual.categories.forEach(
      category => {
        categories.push({...category, individual: individual})
      }
    )})
    return categories?.map(category => (
      {
        id: category.id,
        name: `${category.individual.name} - ${category.name}`,
        type: category.individual.type,
        ranking: category.hasMatch ? 
          individualPlayoffClass(category, category.individual).slice(0, 3) :
          category.classification.slice(0, 3).map((item: string) => ({atletica: getAtletica(item)}))
      }
    ))
  }, [individuals, getAtletica]);
  

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen} maxWidth='95%'>
      <Container>
        <Header>
          <h2>Trofeus</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          {individualRanking.map((ranking: any) => (
            <TrophyTable key={ranking.id} ranking={ranking} />
          ))}
          {qualiRanking.map((ranking: any) => (
            <TrophyTable key={ranking.id} ranking={ranking} />
          ))}
        </Content>
      </Container>
    </Modal>
  );
}

const TrophyTable = ({ranking}: any) => {

  const rank = useMemo(() => {
    let newRank = ranking.ranking.slice(0, 3)
    console.log(newRank)
    return newRank
  }, [ranking.ranking])

  console.log(rank)

  return (
    <div className='w-1/5 border-r-4 border border-ea-purple-400'>
      <div className="w-full bg-ea-purple-400 text-white py-1 px-2">{`${ranking.name} ${ranking.type}`}</div>
      {rank.map((rank: { id: string | null | undefined; name: any, atletica: any; }, index: number) => (
        <div key={rank.id} className="w-full flex justify-between px-2 p-1">
          <div className="w-1/2">{`${index + 1}o ${rank?.atletica?.nickname}`}</div>
        </div>
      ))}
    </div>
  )
}