/* eslint-disable no-underscore-dangle */
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { formatISO } from 'date-fns';
import { FormHandles } from '@unform/core';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import TextArea from '../../../../components/TextArea';
import Select from '../../../../components/Select';
import DatePicker from '../../../../components/DatePicker';
import { IFine } from '../../../../types';
import FormContainer from '../../../../components/FormContainer';
import { toast } from 'react-toastify';
import getValidationError from '../../../../utils/getValidationError';

interface ModalFineProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  fine: any;
}

interface SelectProps {
  value: any;
  label: string;
}

export function ModalFineEdit({
  showModalOpen,
  toggleShowModalOpen,
  fine,
}: ModalFineProps) {
  const formRef = useRef<FormHandles>(null);
  const { useFines, useAtleticas, usePenalties, loading } = useData();

  const { atleticas } = useAtleticas;
  const { penalties } = usePenalties;

  const [tempFine, setTempFine] = useState({} as IFine);
  const [atletica, setAtletica] = useState<SelectProps>({} as SelectProps);
  const [penalty, setPenalty] = useState<SelectProps>({} as SelectProps);

  useEffect(() => {
    if (fine.id) {
      setTempFine(fine);
      setAtletica({
        value: fine.atletica[0].id || fine.atletica[0]._id,
        label: fine.atletica[0].name,
      });
      setPenalty({
        value: fine.penalty[0].id || fine.penalty[0]._id,
        label: fine.penalty[0].name,
      });
    }
  }, [fine]);

  const handleChangeAtletica = useCallback((value: any) => {
    setAtletica(value);
  }, []);

  const handleChangePenalty = useCallback((value: any) => {
    setPenalty(value);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
            atletica_id: Yup.string().required('Atlética obrigatória'),
            penalty_id: Yup.string().required('Penalidade obrigatória'),
            description: Yup.string().required('Descrição obrigatória'),
            date: Yup.date().nullable().min(new Date(1900, 0, 1), 'Data obrigatória').required('Data obrigatória'),
        });
  
        await schema.validate(data, {
          abortEarly: false,
        });
  
        useFines.updateFine({
          id: tempFine.id,
          ...data,
          date: formatISO(data.date),
          atletica_id: atletica.value,
          penalty_id: penalty.value,
        });
        toggleShowModalOpen();
        toast.success('Sucesso ao editar Penalidade');
        setAtletica({} as SelectProps);
        setPenalty({} as SelectProps);
        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao editar Penalidade');
      }
    },
    [atletica.value, penalty.value, tempFine.id, toggleShowModalOpen, useFines],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Penalidade</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={tempFine}>
            <FormContainer line="3fr 1fr">
              <Select
                name="atletica_id"
                placeholder="Selecione uma atlética"
                label="Atlética:"
                options={atleticas.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                value={atletica}
                onChange={handleChangeAtletica}
              />
              <DatePicker
                defaultDate={new Date()}
                placeholderText="Data"
                name="date"
                dateFormat="dd/MM/yyyy"
                label="Data:"
              />
            </FormContainer>
            <Select
              name="penalty_id"
              placeholder="Selecione uma penalidade"
              label="Penalidade:"
              options={penalties.map(item => ({
                label: item.name,
                value: item.id,
              }))}
              value={penalty}
              onChange={handleChangePenalty}
            />
            <TextArea
              name="description"
              placeholder="Descrição"
              label="Descrição:"
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
