/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { FiCheckCircle, FiPlusCircle } from 'react-icons/fi';
import { Container } from './styles';
import { toast } from 'react-toastify';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: any;
  uploadPhoto: (picture: any) => Promise<string>;
  image?: string;
}

const ImagePicker: React.FC<InputProps> = ({
  containerStyle = {},
  uploadPhoto,
  image,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const defaultPhoto = require('../../assets/default_photo.png');

  const handlePhoto = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        if(e.target.files[0].size <= 3 * 1024 * 1024) {
          setLoading(true);
          uploadPhoto(e.target.files[0]).then(() => {
            setLoading(false);
          });
        } else {
          toast.error('Arquivo muito grande. (máx 3Mb)')
        }
      }
    },
    [uploadPhoto],
  );

  return (
    <Container
      style={containerStyle}
      isErrored={!!false}
      isFilled={!!image}
      isFocused={false}
    >
      {!image ? <FiPlusCircle size={28} /> : <FiCheckCircle size={28} />}
      <label htmlFor="photo">
        <input onChange={handlePhoto} type="file" id="photo" {...rest} />
      </label>
      {loading ? (
        <div className="loading">
          <MoonLoader color="#3B1467" loading />
        </div>
      ) : (
        <img
          src={!image ? defaultPhoto : image}
          style={{ opacity: !image ? 0.1 : 1 }}
          alt="icon"
        />
      )}
    </Container>
  );
};

export default ImagePicker;
