import { useCallback, useMemo, useState } from 'react';
import { FiEdit, FiPlus, /* FiTrash2 */ } from 'react-icons/fi';
import { Column } from 'react-table';
import { useRecoilValue } from 'recoil';
import { Atleticas } from '../../Atoms';
import { Avatar } from '../../components/Avatar';
import { ModalAtletica } from './components/ModalAtletica';
import { ModalAtleticaEdit } from './components/ModalAtleticaEdit';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IAtletica } from '../../types';

import { Container, IconContainer, Header, AddNew } from './styles';

function PageAtleticas() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showAtleticaModal, setShowAtleticaModal] = useState(false);
  const [showAtleticaModalEdit, setShowAtleticaModalEdit] = useState(false);
  const [showAtleticaModalDelete, setShowAtleticaModalDelete] = useState(false);
  const [editAtletica, setEditAtletica] = useState({} as IAtletica);
  const [deleteAtletica, /* setDeleteAtletica */] = useState('');
  const { useAtleticas } = useData();
  const atleticas = useRecoilValue(Atleticas);

  const toggleShowModalOpenEdit = useCallback(() => {
    setShowAtleticaModalEdit(!showAtleticaModalEdit);
  }, [showAtleticaModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowAtleticaModalDelete(!showAtleticaModalDelete);
  }, [showAtleticaModalDelete]);

  const handleEditAtletica = useCallback(
    (atleticaEdit: IAtletica) => {
      toggleShowModalOpenEdit();
      setEditAtletica(atleticaEdit);
    },
    [toggleShowModalOpenEdit],
  );

  /* const handleDeleteAtletica = useCallback(
    (atleticasId: string) => {
      toggleShowModalOpenDelete();
      setDeleteAtletica(atleticasId);
    },
    [toggleShowModalOpenDelete],
  ); */

  const delAtletica = useCallback(() => {
    useAtleticas.removeAtletica(deleteAtletica);
    toggleShowModalOpenDelete();
  }, [useAtleticas, deleteAtletica, toggleShowModalOpenDelete]);

  const columns: Column<any>[] = useMemo(
    () =>
      isAtletica
        ? [
            {
              Header: 'Avatar',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Avatar
                      name={row.original.nickname}
                      avatar={row.original.avatar}
                    />
                  </div>
                );
              },
            },
            {
              Header: 'Atletica',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.name}
                  </div>
                );
              },
            },
            {
              Header: 'E-mail',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.email}
                  </div>
                );
              },
            },
            {
              Header: 'Cidade',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.city}
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'Avatar',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Avatar
                      name={row.original.nickname}
                      avatar={row.original.avatar}
                    />
                  </div>
                );
              },
            },
            {
              Header: 'Atletica',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.name}
                  </div>
                );
              },
            },
            {
              Header: 'E-mail',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.email}
                  </div>
                );
              },
            },
            {
              Header: 'Cidade',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.city}
                  </div>
                );
              },
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditAtletica(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    {/* <IconContainer
                      type="del"
                      onClick={() => handleDeleteAtletica(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer> */}
                  </div>
                );
              },
            },
          ],
    [isAtletica, handleEditAtletica, widthScreen],
  );

  const toggleShowModalOpen = useCallback(() => {
    setShowAtleticaModal(!showAtleticaModal);
  }, [showAtleticaModal]);

  return (
    <Container>
      <Header>
        <h1>Atleticas</h1>
        {!isAtletica && (
          <AddNew onClick={toggleShowModalOpen}>
            <FiPlus />
            New
          </AddNew>
        )}
      </Header>
      <Table columns={columns} data={atleticas} />

      <ModalAtletica
        showModalOpen={showAtleticaModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalAtleticaEdit
        showModalOpen={showAtleticaModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        atletica={editAtletica}
      />
      <ModalWaring
        showModalOpen={showAtleticaModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar atletica"
        message="Tem certeza que deseja deletar esse atletica?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delAtletica}
      />
    </Container>
  );
}

export default PageAtleticas;
