import { IAdmin } from "../types";

export default function sortAdmins(admins: IAdmin[]): IAdmin[] {

  const sortedAdmins = admins.slice().sort((a, b) => {
    if(a.responsibility === 'presidente' && b.responsibility !== 'presidente') return 1;
    if(a.responsibility !== 'presidente' && b.responsibility === 'presidente') return -1;
    return 0;
  }).slice().sort((a, b) => {
    if(a.responsibility === 'vice-presidente' && b.responsibility !== 'vice-presidente') return 1;
    if(a.responsibility !== 'vice-presidente' && b.responsibility === 'vice-presidente') return -1;
    return 0;
  }).slice().sort((a, b) => {
    if(a.responsibility === 'tesouraria' && b.responsibility !== 'tesouraria') return 1;
    if(a.responsibility !== 'tesouraria' && b.responsibility === 'tesouraria') return -1;
    return 0;
  }).slice().sort((a, b) => {
    if(a.responsibility === 'esportes' && b.responsibility !== 'esportes') return 1;
    if(a.responsibility !== 'esportes' && b.responsibility === 'esportes') return -1;
    return 0;
  }).slice().sort((a, b) => {
    if(a.responsibility === 'secretaria' && b.responsibility !== 'secretaria') return 1;
    if(a.responsibility !== 'secretaria' && b.responsibility === 'secretaria') return -1;
    return 0;
  }).slice().sort((a, b) => {
    if(a.responsibility === 'eventos' && b.responsibility !== 'eventos') return 1;
    if(a.responsibility !== 'eventos' && b.responsibility === 'eventos') return -1;
    return 0;
  })

  return sortedAdmins
}
