import React, { useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FiDownload, FiEdit, FiSettings, FiTrash2 } from 'react-icons/fi';
import { IAtletica, ICategory, IIndividuals } from '../../../types';
import ModalEditCategory from '../components/ModalEditCategoria';

import { Container, Header, Body } from './styles';
import { useNavigate } from 'react-router-dom';
import { individualPlayoffClass } from '../../../utils/individualPlayoffClass';
import { ModalWaring } from '../../../components/ModalWaring';
import { useData } from '../../../hooks/context';

interface CategoryProps {
  individual: IIndividuals;
  category: ICategory;
  atleticas: IAtletica[];
  isAtletica: boolean;
}
function Category({
  individual,
  category,
  atleticas,
  isAtletica,
}: CategoryProps) {
  const navigate = useNavigate();
  const { useIndividual } = useData();
  const getAtletica = useCallback((id: string) => {
    const aaa = atleticas.find(item => item.id === id);
    return aaa || undefined;
  }, [atleticas])

  const [classification, setClassification] = useState([] as string[]);
  const [showModalEditCategory, setShowModalEditCategory] = useState(false);
  const [data, setData] = useState([] as any);
  const [showCategoryModalDelete, setShowCategoryModalDelete] = useState(false);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowCategoryModalDelete(!showCategoryModalDelete)
  }, [showCategoryModalDelete])

  useEffect(() => {
    let dataAux: any = [];
    classification?.forEach((cat, index) => {
      dataAux.push({
        ranking: index + 1,
        atleta: category.options[0].value[index],
        atletica: getAtletica(cat)?.nickname,
        resultado: category?.options[1]?.value[index]
      })
    })
    setData(dataAux)
  }, [category, getAtletica, classification]);

  useEffect(() => {
    if(category.hasMatch) {
      setClassification(individualPlayoffClass(category, individual).map(item => item.atletica.id))
    } else {
      setClassification(category.classification)
    }
  }, [category, individual])

  const toggleShowModalEditCategory = useCallback(() => {
      setShowModalEditCategory(!showModalEditCategory)
  }, [showModalEditCategory]);

  const delCategory = useCallback(async () => {
    useIndividual.deleteCategory({...category, individual_id: individual.id})
    toggleShowModalOpenDelete()
  }, [category, toggleShowModalOpenDelete, useIndividual, individual.id]);

  const goToMatches = useCallback(() => {
    navigate(`/individuais/${individual.id}/${category.id}`)
  }, [navigate, individual, category])

  return (
    <Container>
      <Header>
        {!isAtletica && (
          <div className="buttons">
            <FiEdit onClick={toggleShowModalEditCategory} />
            {category.hasMatch && (
              <FiSettings onClick={goToMatches} />
            )}
            <FiTrash2 onClick={toggleShowModalOpenDelete} />
            <CSVLink  data={data} filename={`${category.name} - ${individual.name} ${individual.type}`}>
              <FiDownload color='white' />
            </CSVLink>
          </div>
        )}
        <div className="title">{category.name}</div>
        <div className="head">
          <div className="class">P.</div>
          <div className="aaa">Atléticas</div>
          <div className="pts">Pts</div>
          <div className="name">Atleta</div>
          {category?.options.length > 1 && <div className="extras">{category?.options[1]?.label}</div>}
        </div>
      </Header>
      <Body>
        {Array(32)
          .fill(0)
          .map((item, index) => (
            <div>
              <div className="class">{`${index + 1}º`}</div>
              <div className="aaa">
                {classification?.length > index ? (
                  <div>
                    {getAtletica(classification[index])?.avatar && (
                      <img
                        src={
                          getAtletica(classification[index])?.avatar
                        }
                        alt="icon"
                      />
                    )}
                    <div>
                      {getAtletica(classification[index])?.nickname ||
                        ' - '}
                    </div>
                  </div>
                ) : (
                  '-'
                )}
              </div>
              <div className="pts">{category.points[index] || '-'}</div>
              {category?.options.length > 0 && (
                <div className="name">
                  {category.options[0].value[index] || '-'}
                </div>
              )}
              {category?.options.length > 1 && (
                <div className="extras">
                  {category?.options[1]?.value[index] || '-'}
                </div>
              )}
            </div>
          ))}
      </Body>
      <ModalEditCategory
        individual={individual}
        showModalOpen={showModalEditCategory}
        toggleShowModalOpen={toggleShowModalEditCategory}
        category={category}
      />
      <ModalWaring
        showModalOpen={showCategoryModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar categoria"
        message="Tem certeza que deseja deletar essa categoria?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delCategory}
      />
    </Container>
  );
}

export default Category;
