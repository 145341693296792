import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import TextArea from '../../../../components/TextArea';
import { IPenalty } from '../../../../types';
import FormContainer from '../../../../components/FormContainer';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import getValidationError from '../../../../utils/getValidationError';

interface ModalPenaltyProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  editPenalty: IPenalty;
}

export function ModalPenaltyEdit({
  showModalOpen,
  toggleShowModalOpen,
  editPenalty,
}: ModalPenaltyProps) {
  const formRef = useRef<FormHandles>(null);
  const { usePenalties, loading } = useData();
  const [penaltyTemp, setPenaltyTemp] = useState<IPenalty>({} as IPenalty);

  useEffect(() => {
    setPenaltyTemp(editPenalty);
  }, [editPenalty]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatória'),
            point: Yup
            .number()
            .required("O campo é obrigatório.")
            .positive("O campo deve ser positivo.")
            .integer("O campo deve ser um número inteiro."),
            law: Yup.string().required('Artigo obrigatória'),
        });
  
        await schema.validate(data, {
          abortEarly: false,
        });
        usePenalties.updatePenalty({
          ...data,
          id: penaltyTemp.id,
        });
        toast.success('Sucesso ao criar Artigo');
        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Artigo');
      }
    },
    [penaltyTemp.id, toggleShowModalOpen, usePenalties],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Estatuto</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form initialData={penaltyTemp} onSubmit={handleSubmit}>
            <FormContainer line="4fr 1fr">
              <Input name="name" placeholder="Nome" label="Nome:" />
              <Input
                name="point"
                placeholder="Ex.: 12"
                label="Pontos:"
                type="numeric"
              />
            </FormContainer>
            <TextArea name="law" placeholder="Artigo" label="Artigo:" />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
