/* eslint-disable no-shadow */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import api from '../services/api';
import { IAdmin } from '../types';

interface SignInCredentials {
  email: string;
  password: string;
  loginAs: 'C.O.' | 'Atléticas' | 'caster';
}

interface AuthContextState {
  admin: IAdmin;
  signIn(credations: SignInCredentials): Promise<void>;
  signOut(): void;
  updateAdmin(admin: IAdmin): Promise<any>;
}

interface AuthState {
  token: string;
  admin: IAdmin;
  refreshToken: string;
}

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState,
);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [data, setData] = useState<AuthState>({
    admin: localStorage.getItem('@AdminControl:admin'),
    token: localStorage.getItem('@AdminControl:token'),
    refreshToken: localStorage.getItem('@AdminControl:refreshToken'),
  } as unknown as AuthState);
  useEffect(() => {
    const admin = localStorage.getItem('@AdminControl:admin');
    const token = localStorage.getItem('@AdminControl:token');
    const refreshToken = localStorage.getItem('@AdminControl:refreshToken');

    if (token) {
      if (admin && JSON.parse(admin).responsibility === 'atletica') {
        api
          .get('/profile')
          .then(response => {
            const admin = response.data;
            api.defaults.headers.common.authorization = `Bearer ${token}`;
            setData({
              admin,
              token,
              refreshToken: refreshToken || '',
            });
          })
          .catch(err => {
            localStorage.removeItem('@AdminControl:token');
            localStorage.removeItem('@AdminControl:admin');
            localStorage.removeItem('@AdminControl:refreshToken');

            setData({} as AuthState);
          });
      } else if(admin && JSON.parse(admin).responsibility === 'caster') {
        api
          .get('/caster/me')
          .then(response => {
            const admin = response.data;
            api.defaults.headers.common.authorization = `Bearer ${token}`;
            setData({
              admin,
              token,
              refreshToken: refreshToken || '',
            });
          })
          .catch(err => {
            localStorage.removeItem('@AdminControl:token');
            localStorage.removeItem('@AdminControl:admin');
            localStorage.removeItem('@AdminControl:refreshToken');

            setData({} as AuthState);
          });
      } else {
        api
          .get('/admins/me')
          .then(response => {
            const admin = response.data;
            api.defaults.headers.common.authorization = `Bearer ${token}`;
            setData({
              admin,
              token,
              refreshToken: refreshToken || '',
            });
          })
          .catch(err => {
            localStorage.removeItem('@AdminControl:token');
            localStorage.removeItem('@AdminControl:admin');
            localStorage.removeItem('@AdminControl:refreshToken');

            setData({} as AuthState);
          });
      }
    }
  }, []);

  const signIn = useCallback(
    async ({ email, password, loginAs }: SignInCredentials) => {
      let url = '';
      switch (loginAs) {
        case 'C.O.':
          url = '/sessionsadm/admin'
          break;
        case 'Atléticas':
          url = '/sessions/user'
          break;
        case 'caster':
          url = '/sessions-caster/admin'
          break;
        default:
          break;
      }
      const response = await api.post(url, {
        email,
        password,
      });

      const { token, refreshToken } = response.data;

      let admin;

        switch (loginAs) {
          case 'C.O.':
            admin = response.data.admin
            break;
          case 'Atléticas':
            admin = response.data.atletica
            break;
          case 'caster':
            admin = response.data.caster
            break;
          default:
            admin = response.data.admin
            break;
        }

      localStorage.setItem('@AdminControl:token', token);
      localStorage.setItem('@AdminControl:admin', JSON.stringify(admin));
      localStorage.setItem('@AdminControl:refreshToken', refreshToken);

      api.defaults.headers.common.authorization = `Bearer ${token}`;

      setData({ token, admin, refreshToken });
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@AdminControl:token');
    localStorage.removeItem('@AdminControl:admin');
    localStorage.removeItem('@AdminControl:refreshToken');

    setData({} as AuthState);
  }, []);

  const updateAdmin = useCallback(
    async (admin: IAdmin) => {
      try {
        const checkExistToken = api.defaults.headers.common.authorization;
        if(!checkExistToken){
          return 'Ocorreu um erro'
        } else {
          const [, hasToken] = checkExistToken?.toString().split(' ');
          if(hasToken){
            let url = '';
            switch (data.admin?.responsibility) {
              case 'atletica':
                url = '/profile'
                break;
              case 'caster':
                url = '/caster'
                break;
              default:
                url = '/admins'
                break;
            }
  
            const response = await api.put(url, admin);
    
            setData({
              token: data.token,
              admin,
              refreshToken: data.refreshToken,
            });
    
            localStorage.setItem('@AdminControl:admin', JSON.stringify(admin));
    
            return response.data;
          } else {
            return 'Ocorreu um erro'
          }
        }
        
      } catch (e: any) {
        return e?.response?.data?.message;
      }
    },
    [setData, data],
  );
  return (
    <AuthContext.Provider
      value={{ admin: data.admin, signIn, signOut, updateAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
