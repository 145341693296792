import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiArrowLeft, FiAward, FiClipboard, FiDownload, FiRepeat } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { ICategory, IIndividualMatch, IIndividuals } from '../../types';

import {
  Container,
  Header,
  AddNew,
  Content,
  Oitavas,
  Quartas,
  Semi,
  Final,
  Playoff
} from './styles';

import athletics from '../../assets/sports-icons/athletics.svg';
import basketball from '../../assets/sports-icons/basketball.svg';
import beachVolleyball from '../../assets/sports-icons/beach-volleyball.svg';
import football from '../../assets/sports-icons/football.svg';
import futsal from '../../assets/sports-icons/futsal.svg';
import handball from '../../assets/sports-icons/handball.svg';
import judo from '../../assets/sports-icons/judo.svg';
import swimming from '../../assets/sports-icons/swimming.svg';
import tableTennis from '../../assets/sports-icons/table-tennis.svg';
import tennis from '../../assets/sports-icons/tennis.svg';
import volleyball from '../../assets/sports-icons/volleyball.svg';
import chess from '../../assets/sports-icons/chess.svg';
import logo from '../../assets/logo_s.svg';
import MatchBox from './components/MatchBox';
import ModalMatchBox from './components/ModalMatchBox';
import ModalSorteio from './components/ModalSorteio';
import ModalRanking from './components/ModalRanking';
import capitalize from '../../utils/capitalize';
import csgo from '../../assets/sports-icons/csgo.svg';
import clash from '../../assets/sports-icons/clash-royale.svg';
import fifa from '../../assets/sports-icons/fifa.svg';
import valorant from '../../assets/sports-icons/valorant.svg';
import lol from '../../assets/sports-icons/lol.svg';
import { downloadImage } from '../../utils/downloadImage';
import { toast } from 'react-toastify';

const icons = [
  [athletics, 'athletics'],
  [basketball, 'basketball'],
  [beachVolleyball, 'beachVolleyball'],
  [football, 'football'],
  [futsal, 'futsal'],
  [handball, 'handball'],
  [judo, 'judo'],
  [swimming, 'swimming'],
  [tableTennis, 'tableTennis'],
  [tennis, 'tennis'],
  [volleyball, 'volleyball'],
  [chess, 'chess'],
  [csgo, 'csgo'],
  [clash, 'clash'],
  [fifa, 'fifa'],
  [valorant, 'valorant'],
  [lol, 'lol'],
];

function IndividualPlayoffPage() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const navigate = useNavigate();
  const { id, categoryId } = useParams();
  const { useIndividual } = useData();
  const { individuals } = useIndividual;

  const [individual, setIndividual] = useState({} as IIndividuals);
  const [category, setCategory] = useState({} as ICategory);
  const [showModal, setShowModal] = useState(false);
  const [showModalSorteio, setShowModalSorteio] = useState(false);
  const [editMatch, setEditMatch] = useState({} as IIndividualMatch);
  const [showModalSelectModalidade, setShowModalSelectModalidade] =
    useState(false);
  const [showModalRanking, setShowModalRanking] = useState(false);

  const toggleShowModalRanking = useCallback(() => {
    setShowModalRanking(!showModalRanking);
  }, [showModalRanking]);

  const toggleShowModalSelectModalidade = useCallback(() => {
    setShowModalSelectModalidade(!showModalSelectModalidade);
  }, [showModalSelectModalidade]);

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const toggleShowModalSorteio = useCallback(() => {
    setShowModalSorteio(!showModalSorteio);
  }, [showModalSorteio]);

  useEffect(() => {
    if (individuals.length > 0) {
      const findIndividual = individuals.find(item => item.id === id);
      if (findIndividual) {
        setIndividual(findIndividual);
        const findCategory = findIndividual?.categories?.find(item => item.id === categoryId);
        if (findCategory) {
          setCategory(findCategory);
        } else {
          navigate('/individuais');
        }
      } else {
        navigate('/individuais');
      }
    }
  }, [navigate, individuals, categoryId, id]);

  function getIcons(value: string) {
    const icon = icons.find((item: any[]) => item[1] === value);
    return icon ? icon[0] : undefined;
  }

  const handleEditMatch = useCallback(
    (editMatch: IIndividualMatch) => {
      setEditMatch(editMatch);
      toggleShowModal();
    },
    [toggleShowModal],
  );

  const handleDownload = useCallback((saveAs: 'jpg' | 'pdf') => {
    const ele = document.getElementById('playoff-content');
    const el = document.getElementById('playoff-box');
    if(ele && el){
      downloadImage({
        ele: el,
        name: `${individual.name}-${capitalize(individual.type)}: ${category.name}`,
        saveAs: saveAs,
        orientation: 'landscape',
        options: {
          width: 2560,
          height: 1440,
          onclone: (d, e) => {
            e.style.overflow = 'visible';
            e.style.transform = `scale(${ele.scrollWidth / 2560}, ${ele.scrollHeight / 1440})`;
            const check = d.getElementById('playoff-content')
            if(check){
              check.style.overflow = 'visible'
            }
          }
        }
      })
    }
  }, [individual, category.name])

  const handleDownloadDoc = useCallback((doc: string | undefined, match: string) => {
    if(!doc) {
      toast.error('Partida sem súmula')
    } else {
      const link = document.createElement('a');
      link.href = doc;
      link.target = "_blank"
      link.setAttribute(
        'download',
        `${individual.name} ${individual.type} - ${match}`
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    }
  }, [individual])

  return (
    <Container id='playoff-box'>
      {category.id && category?.matches ? (
        <>
          <Header>
            <div>
              <div>
                <img src={getIcons(individual.icon) || logo} alt="icon" />
                <h1>{`${individual.name} ${capitalize(individual.type)}`}</h1>
              </div>
              <div id="playoff-options" data-html2canvas-ignore="true">
                {!isAtletica && (
                  <AddNew onClick={toggleShowModalSorteio}>
                    <FiClipboard />
                    {widthScreen > 820 && 'Sorteio'}
                  </AddNew>
                )}
                <AddNew onClick={toggleShowModalRanking}>
                  <FiAward />
                  {widthScreen > 820 && 'Ver classificação'}
                </AddNew>
                <AddNew onClick={toggleShowModalSelectModalidade}>
                  <FiRepeat />
                  {widthScreen > 820 && 'Selecionar outra modalidade'}
                </AddNew>
                <AddNew onClick={() => handleDownload('jpg')}>
                  <FiDownload />
                  {widthScreen > 820 && 'JPG'}
                </AddNew>
                <AddNew onClick={() => handleDownload('pdf')}>
                  <FiDownload />
                  {widthScreen > 820 && 'PDF'}
                </AddNew>
              </div>
            </div>
            <div className="button" onClick={() => navigate(`/individuais/${individual.id}`)} data-html2canvas-ignore="true">
              <FiArrowLeft />
              {widthScreen > 820 && 'Voltar'}
            </div>
          </Header>
          <Content id="playoff-content">
            <div>
              <div className="step-title">Play Off</div>
              <Playoff>
                {category.matches
                  .filter(item => item.number <= 8)
                  .map(qual => (
                    <MatchBox
                      onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Playoff>
            </div>
            <div>
              <div className="step-title">Oitavas</div>
              <Oitavas>
                {category.matches
                  .filter(item => item.number <= 20 && item.number >= 17)
                  .map(qual => (
                    <MatchBox
                      onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Oitavas>
            </div>
            <div>
              <div className="step-title" />
              <div
                style={{
                  maxWidth: 8,
                  height: 1,
                  backgroundColor: 'var(--purple02)',
                  marginTop: '9rem',
                }}
              />
            </div>
            <div>
              <div className="step-title">Quartas</div>
              <Quartas>
                {category.matches
                  .filter(item => item.number === 25 || item.number === 26)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Quartas>
            </div>
            <div>
              <div className="step-title">Semi</div>
              <Semi>
                {category.matches
                  .filter(item => item.number === 29)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Semi>
            </div>
            <div>
              <div className="step-title">Final</div>
              <Final>
                {category.matches
                  .filter(item => item.number === 31)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Final>
            </div>
            <div>
              <div className="step-title">Semi</div>
              <Semi>
                {category.matches
                  .filter(item => item.number === 30)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Semi>
            </div>
            <div>
              <div className="step-title">Quartas</div>
              <Quartas>
                {category.matches
                  .filter(item => item.number === 28 || item.number === 27)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Quartas>
            </div>
            <div>
              <div className="step-title">Oitavas</div>
              <Oitavas>
                {category.matches
                  .filter(item => item.number > 20 && item.number <= 24)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (!isAtletica && handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Oitavas>
            </div>
            <div>
              <div className="step-title">Play Off</div>
              <Playoff>
                {category.matches
                  .filter(item => item.number >= 9 && item.number <= 16)
                  .map(qual => (
                    <MatchBox
                      onChange={() => (!isAtletica && handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Playoff>
            </div>
          </Content>
        </>
      ) : (
        <div />
      )}
      <ModalMatchBox
        showModalOpen={showModal}
        toggleShowModalOpen={toggleShowModal}
        category={category}
        individual={individual}
        match={editMatch}
      />
      <ModalSorteio
        showModalOpen={showModalSorteio}
        toggleShowModalOpen={toggleShowModalSorteio}
        individual={individual}
        category={category}
      />
      <ModalRanking
        showModalOpen={showModalRanking}
        toggleShowModalOpen={toggleShowModalRanking}
        category={category}
        individual={individual}
      />
    </Container>
  );
}

export default IndividualPlayoffPage;
