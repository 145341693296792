/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiDownload, FiMaximize2, FiPlus } from 'react-icons/fi';
import Select from '../../components/Select';
import { Calendar,  EventPropGetter,  dateFnsLocalizer } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import {
  Container,
  Header,
  SubHeader,
  Content,
} from './styles';
import Button from '../../components/Button';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { addMinutes, differenceInMinutes, format, formatISO, getDay, isAfter, isBefore, parse, setHours, setMinutes, startOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useData } from '../../hooks/context';
import { IMatch, IQualifier, SelectValue } from '../../types';
import { Avatar } from '../../components/Avatar';
import DatePicker from '../../components/DatePicker';
import ModalMatchBox from '../Playoff/components/ModalMatchBox';
import { getRestriction } from '../../utils/getRestriction';
import { downloadImage } from '../../utils/downloadImage';
import Chips from '../../components/Chips';
import { toast } from 'react-toastify';
import { useEdition } from '../../hooks/edition';
import { store } from '../../types/store';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

const locales = {
  'pt-BR': ptBR,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})
const DnDCalendar = withDragAndDrop(Calendar);

const getTypeColor = (type: string, isSelected?: boolean, isPick?: boolean) => {
  let selected = isSelected ? '500' : isPick ? '600' : '300'
  switch (type) {
    case 'Feminino':
      return `bg-red-${selected}`
    case 'Masculino':
      return `bg-blue-${selected}`
    default:
      return `bg-gray-${selected}`
  }
}

const getTypeLetter = (type: string) => {
  switch (type) {
    case 'Feminino':
      return 'F'
    case 'Masculino':
      return 'M'
    default:
      return ''
  }
}

function Scheduler() {
  const { admin } = useAuth();
  const { edition } = useEdition();
  const { useQualifier, useAtleticas, usePlace } = useData();
  const { places } = usePlace;
  const { qualifiers, updateOneMatch } = useQualifier;
  const { atleticas } = useAtleticas;
  const isAtletica = admin?.responsibility === 'atletica';

  const widthScreen = window.innerWidth;

  const [showFilters, setShowFilters] = useState(true);
  const [selectedEtapa, setSelectedEtapa] = useState(
    { label: 'Oitavas', value: 'Oitavas' });
  const [selectedType, setSelectedType] = useState(
    { label: 'Todos', value: 'all' });
  const [selectedStep, setSelectedStep] = useState(
    { label: '15 min', value: 15 });
  const [allMatches, setAllMatches] = useState<any[]>([]);
  const [draggedEvent, setDraggedEvent] = useState<any>();
  const [date, setDate] = useState<Date | null>(new Date());
  const [selectedMatch, setSelectedMatch] = useState<IMatch>({} as IMatch);
  const [restrictions, setRestrictions] = useState<string[]>([]);
  const [pickAtletica, setPickAtletica] = useState<string>('');
  const [modalidadesNames, setModalidadesNames] = useState<string[]>([]);
  const [selectedModalidades, setSelectedModalidades] = useState<string[]>([]);
  const [expand, setExpand] = useState(false);
  const [allForeignMatches, setAllForeignMatches] = useState<any[]>([]);
  const ref = useRef(false);

  const [showModal, setShowModal] = useState(false);

  const getForeignMatches = useCallback(async (edition: string) => {
    const { data: qualifiers } = await api.get('/qualifier', {headers: { 'x-edition': edition }})
    let matches: IMatch[] = [];
    qualifiers.forEach((qualifier: IQualifier) => {
      const fixQualifier = {
        ...qualifier,
        matches: qualifier.matches.map(match => ({
          ...match,
          qualifier: {} as IQualifier
        }))
      }
      let allMatchesQuali = qualifier.matches.map(match => ({
        ...match,
        qualifier: fixQualifier,
        foreign: true
      }))
      allMatchesQuali = allMatchesQuali.filter(match => match?.atletica1?.length > 0 && match?.atletica2?.length > 0)
      matches = [...matches, ...allMatchesQuali]
    })
    setAllForeignMatches(matches)
  }, [])

  useEffect(() => {
    if(process.env.REACT_APP_JOGOS === 'IM') {
      const foreignEdition = store.editions.IM.filter(ed => ed.value !== edition)
      if(foreignEdition.length > 0) {
        getForeignMatches(foreignEdition[0].value)
      }
    }
  }, [edition, getForeignMatches])

  useEffect(() => {
    if(qualifiers.length > 0 && !ref.current) {
      let names = qualifiers.map(qualifier => qualifier.name)
      names = names.filter((item, index) => names.indexOf(item) === index)
      setModalidadesNames(names)
      setSelectedModalidades(names)
      const allDates = qualifiers.reduce((acc: any[], curr) => {
        const dates = curr.matches.map(match => match?.startDate)
        return [...acc, ...dates]
      }, [])
      .filter(item => item && isAfter(new Date(item), new Date()))
      .sort((a, b) => isBefore(new Date(a), new Date(b)) ? -1 : 1)
      setDate(allDates.length > 0 ? new Date(allDates[0]) : new Date())
      ref.current = true;
    }
  }, [qualifiers, ref])

  useEffect(() => {
    let matches: IMatch[] = [];
    qualifiers.forEach(qualifier => {
      const fixQualifier = {
        ...qualifier,
        matches: qualifier.matches.map(match => ({
          ...match,
          qualifier: {} as IQualifier
        }))
      }
      let allMatchesQuali = qualifier.matches.map(match => ({
        ...match,
        qualifier: fixQualifier,
        foreign: false
      }))
      allMatchesQuali = allMatchesQuali.filter(match => match?.atletica1?.length > 0 && match?.atletica2?.length > 0)
      matches = [...matches, ...allMatchesQuali]
    })
    matches = matches.filter(match => getEtapa(match.number) === selectedEtapa.value);
    matches = matches.filter(match => selectedModalidades.includes(match.qualifier.name))
    if(selectedType.value !== 'all') {
      matches = matches.filter(match => match.qualifier.type === selectedType.value)
    }
    setAllMatches(matches)
  }, [qualifiers, selectedEtapa, selectedType, selectedModalidades])

  const handleDragStart = useCallback((event: string) => setDraggedEvent(event), [])

  const onDropFromOutside = useCallback(
    ({ start, end, allDay: isAllDay, resource }: any) => {
      if (isAtletica) return;
      if (draggedEvent === 'undroppable') {
        setDraggedEvent(null)
        return
      }

      const match_id = draggedEvent
      setDraggedEvent(null)

      const match = allMatches.find(match => match.id === match_id)
      if (!match || match.foreign) {
        return
      }
      const newMatch = {
        ...match,
        startDate: start,
        place_id: resource,
        qualifier_id: match.qualifier.id
      }
      updateOneMatch(newMatch)
    },
    [draggedEvent, setDraggedEvent, allMatches, updateOneMatch, isAtletica]
  )

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  

  const toggleShowFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleDownload = useCallback(async (saveAs: 'jpg' | 'pdf') => {
    setExpand(true)
    const ele = document.getElementById('scheduler-box');
    const el = document.getElementById('content-box');
    if(ele && el){ 
      downloadImage({
        ele: ele,
        name: `Horarios-${format(date || new Date(), 'dd-MM-yyyy')}`,
        saveAs: saveAs,
        orientation: 'landscape',
        options: {
          width: ele.scrollWidth,
          height: ele.scrollHeight,
          
          onclone: (d, e) => {
            e.style.overflow = 'visible';
            const check = d.getElementById('content-box');
            if(check){
              check.style.overflow = 'visible'
            }
          }
        }
      })
    }
    setExpand(false)
  }, [date])

  const onEventResize = (data: any) => {
    console.log(data)
  };

  const onEventDrop = useCallback(({event, start, end, resourceId}: any) => {
    if(event.foreign || isAtletica) return;
    const match_id = event.id;

    const match = allMatches.find(match => match.id === match_id)
    if (!match) {
      return
    }
    const newMatch = {
      ...match,
      startDate: start,
      place_id: resourceId,
      qualifier_id: match.qualifier.id
    }
    updateOneMatch(newMatch)
  }, [allMatches, updateOneMatch, isAtletica])

  const handleEventPropGetter: EventPropGetter<any> = useCallback((event, start: Date, end: Date, isSelected: boolean) => {
    let styles = {
    };
    if(event.qualifier.type === 'Feminino') {
      styles = {
        ...styles,
        ...{
          backgroundColor: (isSelected || restrictions.includes(event.id)) ? '#f43f5e' : '#fda4af',
          border: `${(event.atletica_id1 === pickAtletica || event.atletica_id2 === pickAtletica) ? '2px solid #dc2626' : 'none'}`,
          color: 'white',
        }
      }
    } else {
      styles = {
        ...styles,
        ...{
          backgroundColor: (isSelected || restrictions.includes(event.id)) ? '#60a5fa' : '#93c5fd',
          border: `${(event.atletica_id1 === pickAtletica || event.atletica_id2 === pickAtletica) ? '2px solid #2563eb' : 'none'}`,
          color: 'white',
        }
      }
    }
    return {
      style: {
        ...styles,
        borderRadius: '4px',
        padding: '4px',
        cursor: (event.foreign) ? 'not-allowed' : 'pointer',
        opacity: (event.foreign) ? 0.5 : 1
      },
    }
  }, [restrictions, pickAtletica])

  const handleSelectEvent = useCallback((event: any, e: React.SyntheticEvent<HTMLElement>) => {
    if(event.foreign || isAtletica) return;
    const restrictions = getRestriction(event, atleticas, qualifiers);
    setRestrictions(restrictions)
  }, [atleticas, qualifiers, isAtletica])

  const handleDoubleClickEvent = useCallback((event: any, e: React.SyntheticEvent<HTMLElement>) => {
    if(event.foreign || isAtletica) return;
    const match = allMatches.find(match => match.id === event.id)
    setSelectedMatch(match)
    toggleShowModal()
  }, [allMatches, toggleShowModal, isAtletica])

  const handlePickAtletica = useCallback((atletica_id: string) => {
    if(pickAtletica === atletica_id) {
      setPickAtletica('')
    } else {
      setPickAtletica(atletica_id)
    }
  }, [pickAtletica]);

  const handleChangeModalidades = useCallback((value: SelectValue[]) => {
    setSelectedModalidades(value.map(v => v.value));
  }, []);

  const handleExpand = useCallback(() => {
    setExpand(true);
    toast.info('Precione Esc para sair do modo de tela cheia')
  }, [])

  document.addEventListener('keydown', (e) => {
    e.key === 'Escape' && setExpand(false)
  })

  const handleDateChange = useCallback((date: Date | [Date | null, Date | null] | null) => {
    if(Array.isArray(date)) {
      setDate(date[0] || new Date())
    } else {
      setDate(date)
    }
  }, [])

  function getEndMatch (startDate: Date, duration: number | null) {
    if(!duration) {
      return addMinutes(startDate, 90)
    }
    const untilMidnight = differenceInMinutes(setMinutes(setHours(new Date(startDate), 23), 59), new Date(startDate))
    if(duration > untilMidnight) {
      return addMinutes(startDate, untilMidnight)
    }
    return addMinutes(startDate, duration)
  }
  
  return (
    <Container>
      <Header>
        <h1>Criação de Horarios</h1>
        <SubHeader>
          {widthScreen <= 820 && (
            <div>
              <b>Filtros:</b>
              <div onClick={toggleShowFilters}>
                {showFilters ? <FiChevronDown /> : <FiChevronUp />}
              </div>
            </div>
          )}
          {showFilters && (
            <Form onSubmit={() => {}}>
              <div className='flex flex-row items-center justify-between gap-6'>
                <Select
                  label="Etapa:"
                  name="etapa"
                  value={selectedEtapa}
                  width={'200px'}
                  options={[
                    { label: 'Oitavas', value: 'Oitavas' },
                    { label: 'Quartas', value: 'Quartas' },
                    { label: 'Semi', value: 'Semi' },
                    { label: 'Final', value: 'Final' },
                  ]}
                  onChange={e => setSelectedEtapa(e)}
                />
                <Select
                  label="Tipo:"
                  name="type"
                  value={selectedType}
                  width={'260px'}
                  options={[
                    { label: 'Todos', value: 'all' },
                    { label: 'Masculino', value: 'Masculino' },
                    { label: 'Feminino', value: 'Feminino' },
                  ]}
                  onChange={e => setSelectedType(e)}
                />

                <DatePicker
                  label="Data"
                  defaultDate={new Date()}
                  containerInputStyle={{ width: '160px' }}
                  locale={ptBR}
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                  onChange={handleDateChange}
                  value={date}
                />
                <Select
                  label="Step:"
                  name="step"
                  width={'200px'}
                  value={selectedStep}
                  options={[
                    { label: '5 min', value: 5 },
                    { label: '10 min', value: 10 },
                    { label: '15 min', value: 15 },
                    { label: '20 min', value: 20 },
                    { label: '30 min', value: 30 },
                  ]}
                  onChange={e => setSelectedStep(e)}
                />
                {modalidadesNames.length > 0 && (
                  <Chips
                    name='modalidades'
                    label="Filtrar Modalidades:"
                    containerStyle={{ height: '36px'}}
                    options={modalidadesNames.map(name => ({ label: name, value: name })) || []}
                    value={selectedModalidades.map(name => ({ label: name, value: name })) || []}
                    onChange={handleChangeModalidades}
                    height="100%"
                  />
                )}
                <Button
                  buttonStyle='primary'
                  theme='solid'
                  style={{
                    marginTop: 18,
                    width: '100px'
                  }}
                  onClick={() => handleDownload('jpg')}
                >
                  <FiDownload
                    style={{ marginRight: '8px'}}
                  />
                  JPG
                </Button>
                <Button
                  buttonStyle='primary'
                  theme='solid'
                  style={{
                    marginTop: 18,
                    width: '100px'
                  }}
                  onClick={() => handleDownload('pdf')}
                >
                  <FiDownload
                    style={{ marginRight: '8px'}}
                  />
                  PDF
                </Button>
                <div className='p-4 flex gap-2 text-ea-purple-400 underline flex-row flex-nowrap items-center justify-center cursor-pointer' onClick={handleExpand}>
                  Expandir
                  <FiMaximize2 />
                </div>
              </div>
            </Form>
          )}
        </SubHeader>
      </Header>
      <Content id="content-box">
        {allMatches.filter(item => !item?.startDate  || !(item?.place_id)).length > 0 && (
          <div className="flex w-full border border-ea-purple-900 rounded-lg flex-row items-start justify-start overflow-y gap-4 p-2 flex-wrap max-h-32 overflow-y-auto">
            {allMatches.filter(item => !item?.startDate || !(item?.place_id)).map(match => (
              <Box
                key={match.id}
                handleDragStart={handleDragStart}
                {...match}
              />
            ))}
          </div>
        )}
        <div
          id='scheduler-box'
          className={expand ? 'absolute bg-white rounded-lg p-4' : ''}
          style={expand ? { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '98%', height: '98%'} : {}}
        >
          <DnDCalendar
            defaultDate={date || new Date()}
            date={date || new Date()}
            scrollToTime={setHours(new Date(), 9)}
            defaultView="day"
            views={['day']}
            min={setMinutes(setHours(new Date(), 6), 0)}
            events={[...allMatches, ...allForeignMatches]?.map(m => ({
              ...m,
              title: <Event handlePickAtletica={handlePickAtletica} pickAtletica={pickAtletica} step={selectedStep.value} {...m}/>,
              start: new Date(m.startDate),
              end: getEndMatch(new Date(m.startDate), m?.qualifier?.duration ? m?.qualifier?.duration[getEtapa(m.number)] : 90),
              resourceId: m.place_id
            }))}
            eventPropGetter={handleEventPropGetter}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            style={{ height: "100%", width: '100%' }}
            onDropFromOutside={onDropFromOutside}
            resources={places.filter(place => place.isPriority)}
            resourceTitleAccessor={(resource: any) => resource.name}
            resourceIdAccessor={(resource: any) => resource.id}
            onRangeChange={(range: any) => console.log('ramgeChange', range)}
            onDoubleClickEvent={handleDoubleClickEvent}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={(slotInfo: any) => console.log('slotInfo', slotInfo)}
            selectable
            toolbar={false}
            step={selectedStep?.value || 15}
            formats={{
              timeGutterFormat: 'HH:mm',
              eventTimeRangeFormat: (range) => `${format(range.start, 'HH:mm')} - ${format(range.end, 'HH:mm')}`,
            }}
          />
        </div>
      </Content>
      {selectedMatch?.id && (
        <ModalMatchBox
          match={selectedMatch}
          qualifier={selectedMatch?.qualifier}
          showModalOpen={showModal}
          toggleShowModalOpen={toggleShowModal}
        />
      )}
    </Container>
  );
}

export default Scheduler;

type BoxProps = IMatch & {handleDragStart: (id: string) => void}

const Box = ({qualifier, atletica1, atletica2, id, handleDragStart, ...rest }: BoxProps) => {

  return (
    <div
      draggable={true}
      onDragStart={() => handleDragStart(id)}
      className={`${getTypeColor(qualifier?.type, false)} flex flex-col items-center justify-start w-32 rounded-lg p-2 text-sm font-semibold cursor-grab`}
      style={{
        backgroundColor: qualifier?.type === 'Feminino' ? '#fda4af' : '#93c5fd',
      }}
    >
      <p className='line-clamp-1'>{`${qualifier.name} ${getTypeLetter(qualifier?.type)}`}</p>
      <div className='flex h-full flex-col items-center justify-between text-sm'>
        <div className='flex flex-row items-center justify-center gap-1'>
          {atletica1?.length > 0 && (
            <Avatar
              name={atletica1[0]?.nickname}
              avatar={atletica1[0]?.avatar}
              height='16px'
              width='16px'
            />
          )}
          <p className='line-clamp-1'>{atletica1?.length > 0 ? atletica1[0].nickname : 'Aguardando...'}</p>
        </div>
        <p>x</p>
        <div className='flex flex-row items-center justify-center gap-1'>
          {atletica2?.length > 0 && (
            <Avatar
              name={atletica2[0]?.nickname}
              avatar={atletica2[0]?.avatar}
              height='16px'
              width='16px'
            />
          )}
          <p className='line-clamp-1'>{atletica2?.length > 0 ? atletica2[0].nickname : 'Aguardando...'}</p>
        </div>
      </div>
    </div>
  )
} 

type IEvent = {
  handlePickAtletica: (atletica_id: string) => void;
  pickAtletica: string;
  foreign?: boolean;
  step: number;
} & IMatch;

const Event = ({qualifier, atletica1, atletica2, handlePickAtletica, pickAtletica, step, id, startDate, number, place}: IEvent) => {
  const gap = (n: number) => {
    switch (n) {
      case 30:
      case 20:
        return 'gap-1'
      case 15:
        return 'gap-2'
      case 10:
      case 5:
        return 'gap-4'
      default:
        return 'gap-1'
    }
  }

  const handleGoogleCalendar = useCallback(() => {
    console.log('google calendar')
    const rootUrl = 'http://www.google.com/calendar/event';

    const options = {
      action: 'TEMPLATE',
      dates: `${formatISO(new Date(startDate), { format: 'basic'})}/${
        formatISO(addMinutes(new Date(startDate), qualifier?.duration ? qualifier?.duration[getEtapa(number)] : 90 || 90), { format: 'basic'})}`,
        text: `[IM - ${qualifier.name} ${getTypeLetter(qualifier.type)}] ${atletica1[0].nickname} x ${atletica2[0].nickname}`,
        location: `${place[0]?.name || ''} - Foz do Iguaçu - PR`,
        details: `Jogo de ${qualifier.name} ${qualifier.type} entre ${atletica1[0].nickname} e ${atletica2[0].nickname}`,
    };

    const qs = new URLSearchParams(options);

    let url = `${rootUrl}?${qs}`;
    window.open(url, '_blank');
  }, [qualifier, atletica1, atletica2, startDate, number, place])
  
  return (
    <div>
      <ContextMenuTrigger  id={`box-${id}`}>
        <div className="flex flex-col items-center justify-between flex-1 bg-transparent">
          <p className="line-clamp-1 text-base font-bold">{`${qualifier.name} ${getTypeLetter(qualifier.type)}`}</p>
          <div className={`flex h-full flex-col items-center justify-between text-sm w-full relative ${gap(step)}`}>
            <p className='absolute opacity-90' style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>x</p>
            <div className={`flex flex-row items-center justify-center gap-1 w-5/6 p-1 rounded ${pickAtletica === atletica1[0].id ? getTypeColor(qualifier.type, false, true) : ''}`} onClick={() => handlePickAtletica(atletica1[0].id)}>
              <Avatar
                name={atletica1[0].nickname}
                avatar={atletica1[0].avatar}
                height='18px'
                width='18px'
              />
              <p className='line-clamp-1'>{atletica1[0].nickname}</p>
            </div>
            <div className={`flex flex-row items-center justify-center gap-1 w-5/6 p-1 rounded ${pickAtletica === atletica2[0].id ? getTypeColor(qualifier.type, false, true) : ''}`} onClick={() => handlePickAtletica(atletica2[0].id)}>
              <Avatar
                name={atletica2[0].nickname}
                avatar={atletica2[0].avatar}
                height='18px'
                width='18px'
              />
              <p className='line-clamp-1'>{atletica2[0].nickname}</p>
            </div>
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id={`box-${id}`} className='bg-gray-700 p-4 rounded-lg z-50' >
        <MenuItem  onClick={handleGoogleCalendar} className='flex flex-row items-center text-base'>
          <FiPlus className='mr-2' />
          Google Calendário
        </MenuItem>
      </ContextMenu>
    </div>
  )
}

function getEtapa(number: number) {
  if (number <= 8) {
    return 'Oitavas';
  }
  if (number <= 12 && number > 8) {
    return 'Quartas';
  }
  if (number <= 14 && number > 12) {
    return 'Semi';
  }
  return 'Final';
}