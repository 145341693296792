/* eslint-disable react/jsx-no-bind */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Chips from '../../components/Chips';
import ImagePicker from '../../components/ImagePicker';
import Input from '../../components/Input';
import Points from '../../components/Points';
import { useData } from '../../hooks/context';
import { ISettings, SelectValue } from '../../types';
import { uploadPhotoAdmin } from '../../utils/uploadPhotoAdmin';

import {
  Container,
  Content,
  // ImagePicker
} from './styles';
import Checkbox from '../../components/Checkbox';
import FormContainer from '../../components/FormContainer';

function Settings() {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { useSettings, useAtleticas } = useData();
  const { settings, updateSettings } = useSettings;
  const [settingsTemp, setSettingTemp] = useState({} as ISettings);
  const [image, setImage] = useState('');
  const { atleticas } = useAtleticas;
  const [atleticaTemp, setAtleticaTemp] = useState<SelectValue[]>([]);

  const initPoints = useMemo(() => [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], []);

  const handleChangeAtletica = useCallback((value: SelectValue[]) => {
    setAtleticaTemp(value);
  }, []);

  useEffect(() => {
    if (settings) {
      setImage(settings.avatar);
      setSettingTemp({
        ...settings,
        standard_points: settings?.standard_points || initPoints
      });
      setAtleticaTemp(settings?.atletica_ids?.map(aaa => ({
        value: aaa,
        label: atleticas?.find(item => item.id === aaa)?.name || '',
      })))
    }
  }, [settings, atleticas, initPoints]);

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setImage(response);
        return response;
      }
      return '';
    });
  }

  const handleChange = useCallback(
    (value: any, item: string) => {
      setSettingTemp({
        ...settingsTemp,
        [item]: value,
      });
    },
    [settingsTemp],
  );

  const handleChangePoints = useCallback(
    (value: number, index: number) => {
      const { standard_points } = settingsTemp;
      standard_points[index] = value;
      setSettingTemp({
        ...settingsTemp,
        standard_points,
      });
    },
    [settingsTemp],
  );

  const handleSubmit = useCallback(
    async (data: ISettings) => {
      try {
        formRef.current?.setErrors({});

        updateSettings({
          ...settingsTemp,
          atletica_ids: atleticaTemp.map(item => item.value),
          avatar: image,
        });

        navigate('../');

        toast.success('Configurações atualizadas com sucesso.');
      } catch (err) {
        toast.error('Ocorreu um erro.');
      }
    },
    [updateSettings, navigate, settingsTemp, image, atleticaTemp],
  );

  const handleChangeChecked = useCallback(
    (value: string) => {
      if (settingsTemp?.showClassification?.includes(value)) {
        const filteredShowClassification =
          settingsTemp.showClassification.filter(item => item !== value);
        setSettingTemp({
          ...settingsTemp,
          showClassification: filteredShowClassification,
        });
      } else {
        const updatedShowClassification = [
          ...settingsTemp?.showClassification,
          value,
        ];
        setSettingTemp({
          ...settingsTemp,
          showClassification: updatedShowClassification,
        });
      }
    },
    [settingsTemp],
  );

  return (
    <Container>
      <h1>Configurações</h1>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={settingsTemp}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Content>
            <FormContainer line="3fr 1fr">
              <Input
                label="Nome dos Jogos:"
                name="title"
                placeholder="Ex: JOIA FRONTEIRA"
                onChange={value => handleChange(value.target.value, 'title')}
              />
              <Input
                label="Ano:"
                name="year"
                placeholder="Ex: 2022"
                onChange={value => handleChange(value.target.value, 'year')}
              />
            </FormContainer>
            <FormContainer line="3fr 1fr">
              <Input
                label="Nome da Liga:"
                name="owner"
                placeholder="Ex.: Liga das Atléticas do Oeste do Paraná"
                onChange={value => handleChange(value.target.value, 'owner')}
              />
              <Input
                label="Sigla dos Liga:"
                name="ownerAb"
                placeholder="Ex.: L.A.O.P."
                onChange={value => handleChange(value.target.value, 'ownerAb')}
              />
            </FormContainer>
            <Chips
              name="type"
              label="Tipo de modalidade"
              placeholder="Ex.: Masculino"
              value={settingsTemp.type}
              options={[
                { value: 'Feminino', label: 'Feminino' },
                { value: 'Masculino', label: 'Masculino' },
                { value: 'Misto', label: 'Misto' },
              ]}
              onChange={(value: any) => handleChange(value, 'type')}
            />
            <Chips
              classNameContainer="mt-4"
              name="atleticas"
              label="Atléticas participantes:"
              options={atleticas.map(aaa => ({
                value: aaa.id,
                label: aaa.name,
              }))}
              value={atleticaTemp}
              onChange={handleChangeAtletica}
            />
            <div style={{ justifyContent: 'start', display: 'flex' }}>
              <div
                className="select-all-aaa"
                onClick={() =>
                  handleChangeAtletica(
                    atleticas.map(aaa => ({ value: aaa.id, label: aaa.name })),
                  )
                }
              >
                Selecionar todas as atléticas
              </div>
            </div>
            <div style={{ marginTop: 16 }}>Pontuação padrão:</div>
            <Points
              value={settingsTemp?.standard_points || initPoints}
              onChange={(value, index) => handleChangePoints(value, index)}
            />
            <div style={{ marginTop: 16 }}>Mostrar classificação para:</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Checkbox
                name="vice-presidente"
                value="vice-presidente"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes(
                  'vice-presidente',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div style={{ whiteSpace: 'nowrap' }}>Vice-presidente</div>
              </Checkbox>
              <Checkbox
                name="esportes"
                value="esportes"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes('esportes')}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div>Esportes</div>
              </Checkbox>
              <Checkbox
                name="tesouraria"
                value="tesouraria"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes(
                  'tesouraria',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div>Tesouraria</div>
              </Checkbox>
              <Checkbox
                name="secretaria"
                value="secretaria"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes(
                  'secretaria',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div>Secretaria</div>
              </Checkbox>
              <Checkbox
                name="alojamento"
                value="alojamento"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes(
                  'alojamento',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div>Alojamento</div>
              </Checkbox>
              <Checkbox
                name="eventos"
                value="eventos"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes(
                  'eventos',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div>Eventos</div>
              </Checkbox>

              <Checkbox
                name="atleticas"
                value="atleticas"
                containerStyle={{ marginTop: 8 }}
                checked={settingsTemp?.showClassification?.includes(
                  'atleticas',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div>Atléticas</div>
              </Checkbox>
            </div>
          </Content>
          <div className="image">
            <div>Logo:</div>
            <ImagePicker uploadPhoto={handlePhoto} image={image} />
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 570,
            minWidth: 400,
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" buttonStyle="primary">
            Salvar
          </Button>
          <Button buttonStyle="danger">Cancelar</Button>
        </div>
      </Form>
    </Container>
  );
}

export default Settings;
