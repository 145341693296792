import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import SignIn from '../pages/SignIn';

import { routes } from '../config/global';
import Settings from '../pages/Settings';
import PlayoffPage from '../pages/Playoff';
import ModalidadePage from '../pages/Modalidade';
import AdminProfile from '../pages/AdminProfile';
import AtleticaProfile from '../pages/AtleticaProfile';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SignInCaster from '../pages/SignInCaster';
import IndividualPlayoffPage from '../pages/IndividualPlayoff';

const Routes: React.FC = () => {
  
  return (
    <Switch>
      <Route path="/login" element={SignIn()} />
      <Route path="/forgot-password" element={ForgotPassword()} />
      <Route path="/admin/reset-password" element={ResetPassword()} />
      <Route path="/atletica/reset-password" element={ResetPassword()} />
      <Route path="/login-caster" element={SignInCaster()} />

      {/* CO routes */}
      <Route path="/" element={<PrivateRoute />}>
        {routes.map((option, index) => (
          <>
            <Route
              path={option.path}
              element={option.component()}
              key={`${option.path}-${option.title}-${index}`}
            />
            {option?.subMenu?.map((subOption, i) => (
              <Route
                path={subOption.path}
                element={subOption.component()}
                key={`${subOption.path}-${subOption.title}-${i}`}
              />
            ))}
          </>
        ))}
        <Route path="/settings" element={<Settings />} />
        <Route path="/admin" element={<AdminProfile />} />
        <Route path="/profile" element={<AtleticaProfile />} />
        <Route path="/qualifiers/:id" element={<PlayoffPage />} />
        <Route path="/individuais/:id" element={<ModalidadePage />} />
        <Route path="/individuais/:id/:categoryId" element={<IndividualPlayoffPage />} />
      </Route>
    </Switch>
  )
};

export default Routes;
