import React from 'react';
import { Navigate as Redirect, Outlet } from 'react-router-dom';
import { AdminMenu } from '../components/AdminMenu';
import { LoaderPage } from '../components/LoaderPage';

import { useAuth } from '../hooks/auth';

function PrivateRoute() {
  const { admin } = useAuth();

  return admin ? (
    <AdminMenu>
      <Outlet />
      <LoaderPage />
    </AdminMenu>
  ) : (
    <Redirect to="/login" />
  );
}

export default PrivateRoute;
