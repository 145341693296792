/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-bind */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ImagePicker from '../../components/ImagePicker';
import Input from '../../components/Input';
import { IAdmin } from '../../types';
import { uploadPhotoAdmin } from '../../utils/uploadPhotoAdmin';

import { Container, Content } from './styles';
import { useAuth } from '../../hooks/auth';

function AdminProfile() {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { admin, updateAdmin } = useAuth();
  const [profileTemp, setProfileTemp] = useState({} as IAdmin);
  const [image, setImage] = useState('');

  useEffect(() => {
    if (admin) {
      setImage(admin.avatar);
      setProfileTemp(admin);
    }
  }, [admin]);

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setImage(response);
        return response;
      }
      return '';
    });
  }

  const handleChange = useCallback(
    (value: any, item: string) => {
      setProfileTemp({
        ...profileTemp,
        [item]: value,
      });
    },
    [profileTemp],
  );

  const handleSubmit = useCallback(
    async (data: IAdmin) => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        password: Yup.string().min(6, 'No mínimo 6 digitos'),
        password_confirmation: Yup.string()
            .min(6, 'No mínimo 6 digitos')
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      updateAdmin({
        ...profileTemp,
        ...data,
        avatar: image,
      }).then(response => {
        if (response?.email) {
          toast.success('Profile atualizado com sucesso.');
        } else {
          toast.error(response || 'Ocorreu um erro');
        }
      });

      navigate('../');
    },
    [updateAdmin, navigate, profileTemp, image],
  );

  return (
    <Container>
      <h1>Perfil</h1>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={profileTemp}>
        <div className="content">
          <Content>
            <Input
              label="Nome:"
              name="name"
              placeholder="Nome"
              onChange={value => handleChange(value.target.value, 'name')}
            />
            <Input
              label="E-mail:"
              name="email"
              placeholder="Email"
              disabled
              onChange={value => handleChange(value.target.value, 'name')}
            />
            <Input
              label="Senha atual:"
              name="old_password"
              placeholder="Senha atual"
              type="password"
              onChange={value =>
                handleChange(value.target.value, 'old_password')
              }
            />
            <Input
              label="Nova senha:"
              name="password"
              placeholder="Nova senha"
              type="password"
              onChange={value => handleChange(value.target.value, 'password')}
            />
            <Input
              label="Confirmação da senha:"
              name="confirmation_password"
              placeholder="Confirmação da senha"
              type="password"
              onChange={value =>
                handleChange(value.target.value, 'confirmation_password')
              }
            />
          </Content>
          <div className="image">
            <div>Foto:</div>
            <ImagePicker uploadPhoto={handlePhoto} image={image} />
          </div>
        </div>
        <div className="buttons">
          <Button type="submit" buttonStyle="primary">
            Salvar
          </Button>
          <Button buttonStyle="danger">Cancelar</Button>
        </div>
      </Form>
    </Container>
  );
}

export default AdminProfile;
