/* eslint-disable no-param-reassign */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { IMatch, IQualifier } from '../../../../types';

import { Container, Header, Content } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import Select from '../../../../components/Select';
import { useData } from '../../../../hooks/context';

import Button from '../../../../components/Button';

interface ModalSorteioProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  qualifier: IQualifier;
}

interface SelectProps {
  value: any;
  label: string;
}

const sorteio = [
  [1, 16],
  [8, 9],
  [5, 12],
  [4, 13],
  [2, 15],
  [7, 10],
  [6, 11],
  [3, 14]
]

function ModalSorteio({
  showModalOpen,
  toggleShowModalOpen,
  qualifier,
}: ModalSorteioProps) {
  const formRef = useRef<FormHandles>(null);
  const { useQualifier } = useData();

  const [atleticasOrder, setAtleticasOrder] = useState<
    Array<SelectProps | undefined>
  >(Array(16).fill(undefined));

  useEffect(() => {
    if(qualifier.matches){
      const matches = qualifier?.matches.slice(0, 8)
      let atleticas: Array<SelectProps | undefined> = Array(16).fill(undefined);
      matches.forEach((match, index) => {
        atleticas[sorteio[index][0] - 1] = match?.atletica_id1 ? {label: match.atletica1[0]?.name, value: match.atletica_id1} : undefined
        atleticas[sorteio[index][1] - 1] = match?.atletica_id2 ? {label: match.atletica2[0]?.name, value: match.atletica_id2} : undefined
      })
      setAtleticasOrder(atleticas)
    }
  }, [qualifier])

  const handleSubmit = useCallback(
    async (data: IMatch) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const allAtleticas = atleticasOrder.map(
          item => item?.value || undefined,
        );

        useQualifier.handleDraftMatch(allAtleticas, qualifier.id);

        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Place');
      }
    },
    [atleticasOrder, qualifier.id, toggleShowModalOpen, useQualifier],
  );

  const handleChangeSelect = useCallback(
    (value: any, index: number) => {
      const updateAtleticas = produce(atleticasOrder, draft => {
        draft[index] = value;
        return draft;
      });
      setAtleticasOrder(updateAtleticas);
    },
    [atleticasOrder],
  );

  const getOptions = useCallback(() => {
    const aaa = qualifier.atleticas.map(aaa => ({
      value: aaa.id,
      label: aaa.name,
    }));
    return aaa.filter(
      item => !atleticasOrder.find(a => a?.value === item.value),
    );
  }, [atleticasOrder, qualifier]);


  const handleSetAtleticasAuto = useCallback(() => {
    const newAtleticasOrder: Array<SelectProps | undefined> = [...atleticasOrder];
    let freeNumbers = newAtleticasOrder.map((item, index) => !item ? index : undefined).filter(item => item !== undefined);
    const allNumbersDraw: Array<number> = [];
    const aaa = qualifier.atleticas.map(aaa => ({
      value: aaa.id,
      label: aaa.name,
    })).filter(a => !atleticasOrder.find(aa => aa?.value === a.value));
    const aaaLength = aaa.length
    let leftAAA = aaaLength;

    while (leftAAA > 0) {
      const numberDraw = Math.floor(Math.random() * aaaLength);
      if (!allNumbersDraw.includes(numberDraw)) {
        allNumbersDraw.push(numberDraw);
        if(freeNumbers[0] !== undefined){
          newAtleticasOrder[freeNumbers[0]] = aaa[numberDraw];
          leftAAA -= 1;
          freeNumbers.shift();
        }
      }
    }
    
    if(aaaLength < 16){
      const leftOverAAA = 16 - newAtleticasOrder.length;
      for(var i = 0; i < leftOverAAA ; i++){
        newAtleticasOrder.push({label: '', value: undefined})
      }
    }

    setAtleticasOrder(newAtleticasOrder);
  }, [qualifier, atleticasOrder]);

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      {qualifier.id && (
        <Container>
          <Header>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <h2>Sorteio</h2>
              <div
                style={{
                  marginLeft: '1rem',
                  color: 'var(--purple02)',
                  fontSize: '1.25rem',
                  textDecoration: 'underline',
                }}
                onClick={handleSetAtleticasAuto}
              >
                gerar automaticamente
              </div>
            </div>
            <div onClick={toggleShowModalOpen}>
              <FiX />
            </div>
          </Header>
          <Content>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <>
                <div className="selects">
                  {atleticasOrder.map((item, index) => (
                    <Select
                      labelPosition="left"
                      label={`Atlética #${index + 1}`}
                      name={`atletica-${index}`}
                      options={getOptions()}
                      value={item?.value ? item : undefined}
                      onChange={value => handleChangeSelect(value, index)}
                      placeholder="Selecione"
                    />
                  ))}
                </div>
                <div className="buttons">
                  <Button
                    buttonStyle="primary"
                    theme="hollow"
                    onClick={toggleShowModalOpen}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" buttonStyle="primary" theme="solid">
                    Salvar
                  </Button>
                </div>
              </>
            </Form>
          </Content>
        </Container>
      )}
    </Modal>
  );
}

export default ModalSorteio;
