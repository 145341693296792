import { Container } from './styles';

interface FormContainerProps {
  children: any;
  line: string;
}

function FormContainer({ children, line }: FormContainerProps) {
  return <Container line={line}>{children}</Container>;
}

export default FormContainer;
