import styled from 'styled-components';

interface FormContainerProps {
  line: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1.5rem;
  border-radius: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem 0 3rem;
  padding-right: 0.5rem;
  overflow-y: auto;

  > form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    gap: 1rem;

    .select-all-aaa {
      font-size: 1rem;
      margin: 0;
      text-decoration: underline;
      color: var(--purple02);

      cursor: pointer;
    }

    > .buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 1.5rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    cursor: pointer;
  }
`;

export const FormContainer = styled.div<FormContainerProps>`
  display: grid;
  grid-template-columns: ${props => props.line};
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  > div {
    margin-top: 0;
  }
`;
