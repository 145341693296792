import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertError, Container, ContainerInput } from './styles';
import { FiAlertCircle } from 'react-icons/fi';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'value' | 'selectsRange'> {
  name: string;
  defaultDate: any;
  label?: string;
  onChange?: (date: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<any, Event> | undefined) => void;
  value?: Date | null;
  containerStyle?: any;
  containerInputStyle?: any;
  selectsRange?: boolean;
}

const DatePicker: React.FC<Props> = ({ name, defaultDate, label, onChange, value, containerStyle = {}, containerInputStyle = {}, selectsRange=false, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const datepickerRef = useRef<any>(null);
  const { fieldName, registerField, error } = useField(name);
  const [date, setDate] = useState(defaultDate || null);
  
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!datepickerRef.current?.props.selected);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <ContainerInput style={containerInputStyle}>
      {label && <div className="label">{label}</div>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <ReactDatePicker
          ref={datepickerRef}
          selected={value || date}
          onChange={onChange || setDate}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          selectsRange={selectsRange}
          {...rest}
        />
        {error && (
          <AlertError title={error}>
            <FiAlertCircle color="#C53038" size={20} />
          </AlertError>
        )}
      </Container>
    </ContainerInput>
  );
};
export default DatePicker;
