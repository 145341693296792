/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
import React from 'react';
import { Container, ContainerInput } from './styles';

interface InputProps {
  containerStyle?: any;
  value: number[];
  onChange: (value: number, index: number) => void;
}

const Points: React.FC<InputProps> = ({
  containerStyle = {},
  value,
  onChange,
}) => {
  const handleChange = (value: number, index: number) => {
    onChange(value, index);
  };

  return (
    <ContainerInput>
      {Array(16)
        .fill(1)
        .map((item, index) => (
          <Container
            style={containerStyle}
            isErrored={false}
            isFilled={false}
            isFocused={false}
            key={index + 1}
          >
            <p>{`${index + 1}º:`}</p>
            <input
              defaultValue={0}
              value={value[index]}
              type="number"
              onChange={e => handleChange(parseInt(e.target.value), index)}
            />
          </Container>
        ))}
    </ContainerInput>
  );
};

export default Points;
