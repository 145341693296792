import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 3rem;

  table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 1rem;

    thead tr th {
      border-bottom: 2px solid var(--purple02);
      border-collapse: separate;
      border-spacing: 5px 5px;
      font-weight: 600;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
    }

    tbody tr {
      border-radius: 1rem;
      -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.42);
    }

    tbody tr td {
      border-radius: 1rem;
      padding: 1rem 0.5rem;
      vertical-align: center;
      text-align: center;
    }
  }

  @media (max-width: 820px) {
    tr {
      display: block;
      margin-bottom: 1rem;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tbody {
      margin-bottom: 0.25em;
      display: block;
    }
    td {
      padding: 0.5rem 0.25rem;
      display: flex;
      font-size: 1.25rem;
      text-align: right;
      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }
`;
