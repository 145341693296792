import { Form } from '@unform/web';
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useData } from '../../../../hooks/context';
import { IAdmin } from '../../../../types';
import { store } from '../../../../types/store';
import capitalize from '../../../../utils/capitalize';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';

import {
    Container,
    Content,
    Header,
} from './styles'

interface ModalOrgProps {
    showModalOpen: boolean;
    toggleShowModalOpen: () => void;
    admin: IAdmin;
}

interface SelectProps {
    value: any;
    label: string;
}

export function ModalOrgEdit({ showModalOpen, toggleShowModalOpen, admin }: ModalOrgProps) {

    const [responsibility, setResponsibility] = useState<SelectProps>({} as SelectProps);
    const { useAdmins, loading } = useData();

    useEffect(() => {
        setResponsibility({
            value: admin?.responsibility,
            label: capitalize(admin?.responsibility)
        })
    }, [admin])

    const handleChange = useCallback((value: any) => {
        setResponsibility(value)
    }, [])

    const handleSubmit = useCallback(() => {
        useAdmins.updateAdmin({
            ...admin,
            responsibility: responsibility.value
        });
        toggleShowModalOpen();
    }, [responsibility, useAdmins, toggleShowModalOpen, admin])

    return (
        <Modal
            isOpen={showModalOpen}
            setIsOpen={toggleShowModalOpen}
        >
            <Container>
                <Header>
                    <h2>C.O.</h2>
                    <div onClick={toggleShowModalOpen}>
                        <FiX />
                    </div>
                </Header>
                <Content>
                    <Form initialData={admin} onSubmit={handleSubmit}>
                        <Input
                            name="name"
                            placeholder='Nome'
                            label='Nome:'
                            disabled
                        />
                        <Select
                            name='responsibility'
                            placeholder='Selecione o cargo'
                            label='Cargo:'
                            options={store.responsibility.map(item => ({ label: capitalize(item), value: item }))}
                            value={responsibility}
                            onChange={handleChange}
                        />
                        <Input
                            name="email"
                            placeholder='E-mail'
                            label='E-mail:'
                            disabled
                        />
                        <div className='buttons'>
                            <Button
                                buttonStyle='primary'
                                theme='hollow'
                                onClick={toggleShowModalOpen}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                buttonStyle='primary'
                                theme='solid'
                                loading={loading}
                            >
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </Content>
            </Container>
        </Modal>
    )
}