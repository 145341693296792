import html2canvas, { Options } from "html2canvas";
import jsPDF from "jspdf";

interface DownloadImageOptions {
  ele: HTMLElement;
  name: string;
  saveAs: 'pdf' | 'jpg';
  options?: Partial<Options>;
  orientation?: 'landscape' | 'portrait';
}

export async function downloadImage({ele, name, saveAs, options = {}, orientation = 'landscape'}: DownloadImageOptions) {
    html2canvas(ele, {...options}).then(function(canvas) {
      if(saveAs === 'pdf'){
        const doc = new jsPDF({
          orientation,
          unit: 'px',
          format: [canvas.width, canvas.height]
        });
        doc.addImage(canvas, 'pdf', 0, 0, canvas.width, canvas.height);
        doc.save(`${name}.pdf`);
      } else {
        var a = document.createElement('a');
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = `${name}.jpg`;
        a.click()
        a.remove()
      }
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      console.log('downloaded')
    });
  }