import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAccommodation, IAtletica, IPlace } from "../../types";


export async function auxUpdateAccommodation(
  accommodation: IAccommodation,
  setAccommodations: SetterOrUpdater<IAccommodation[]>,
  state: IAccommodation[],
  atleticas: IAtletica[],
  places: IPlace[]
) {
  try {
    api.put(`/accommodation/${accommodation.id}`, accommodation).then(response => {
      const index = state.findIndex(item => item.id === accommodation.id);
      const updatedAccommodation = state.slice();
      const aaa = response?.data?.atletica_ids?.map((atle: string) => {
        const findAtleticaIndex = atleticas.findIndex(item => item.id === atle)
        const atletica = atleticas[findAtleticaIndex];
        return atletica
      })
      const findPenaltyIndex = places.findIndex(item => item.id === response.data.place_id)
      const place = places[findPenaltyIndex];
      const newAccommodation = {
        ...response.data,
        atletica: [...aaa],
        place: [place]
      }
      updatedAccommodation[index] = newAccommodation;
      setAccommodations(updatedAccommodation);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemoveAccommodation(accommodation_id: string, setAccommodations: SetterOrUpdater<IAccommodation[]>, state: IAccommodation[]) {
  try {
    api.delete(`/accommodation/${accommodation_id}`).then(response => {
      const filteredAccommodations = state.filter(accommodation => accommodation.id !== accommodation_id)
      setAccommodations(filteredAccommodations);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddAccommodation(
  accommodation: Omit<IAccommodation, 'id'>,
  setAccommodations: SetterOrUpdater<IAccommodation[]>,
  state: IAccommodation[],
  atleticas: IAtletica[],
  places: IPlace[]
) {
  try {
    api.post('/accommodation', accommodation).then(response => {
      const aaa = response?.data?.atletica_ids?.map((atle: string) => {
        const findAtleticaIndex = atleticas.findIndex(item => item.id === atle)
        const atletica = atleticas[findAtleticaIndex];
        return atletica
      })
      const findPenaltyIndex = places.findIndex(item => item.id === response.data.place_id)
      const place = places[findPenaltyIndex];
      const newAccommodation = {
        ...response.data,
        atletica: [...aaa],
        penaplacelty: [place]
      }
      setAccommodations([newAccommodation, ...state]);
    })
  } catch (e) {
    console.log(e)
  }
}