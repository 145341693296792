import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { ICaster } from "../../types";


export async function auxUpdateCaster(caster: ICaster, setCasters: SetterOrUpdater<ICaster[]>, state: ICaster[]) {
    try {
        api.put(`/caster/update/${caster.id}`, caster
        ).then(response => {
            const index = state.findIndex(item => item.id === caster.id);
            const updatedCasters = state.slice();
            updatedCasters[index] = response.data;
            setCasters(updatedCasters);
        })
    } catch (e) {
        console.log(e)
    }
}

export async function auxRemoveCaster(caster_id: string, setCasters: SetterOrUpdater<ICaster[]>, state: ICaster[]) {
    try {
        api.delete(`/caster/${caster_id}`).then(response => {
            const filteredCasters = state.filter(Caster => Caster.id !== caster_id)
            setCasters(filteredCasters);
        })
    } catch (e) {
        console.log(e)
    }
}

export async function auxAddCaster(caster: Omit<ICaster, 'id'>, setCasters: SetterOrUpdater<ICaster[]>, state: ICaster[]) {
    try {
        api.post('/caster', caster).then(response => {
            setCasters([...state, response.data]);
        })
    } catch (e) {
        console.log(e)
    }
}