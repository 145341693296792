/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiArrowLeft, FiAward, FiList, FiPlus, FiRepeat } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IIndividuals } from '../../types';

import { Container, Header, AddNew, Content } from './styles';

import athletics from '../../assets/sports-icons/athletics.svg';
import basketball from '../../assets/sports-icons/basketball.svg';
import beachVolleyball from '../../assets/sports-icons/beach-volleyball.svg';
import football from '../../assets/sports-icons/football.svg';
import futsal from '../../assets/sports-icons/futsal.svg';
import handball from '../../assets/sports-icons/handball.svg';
import judo from '../../assets/sports-icons/judo.svg';
import swimming from '../../assets/sports-icons/swimming.svg';
import tableTennis from '../../assets/sports-icons/table-tennis.svg';
import tennis from '../../assets/sports-icons/tennis.svg';
import volleyball from '../../assets/sports-icons/volleyball.svg';
import chess from '../../assets/sports-icons/chess.svg';
import logo from '../../assets/logo_s.svg';
import ModalCategory from './components/ModalCategoria';
import Category from './Category';
import SelectModalidade from '../../components/SelectModalidade';
import ModalRanking from './components/ModalRanking';
import ModalDesempate from './components/ModalDesmpate';
import Checkbox from '../../components/Checkbox';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import capitalize from '../../utils/capitalize';
import csgo from '../../assets/sports-icons/csgo.svg';
import clash from '../../assets/sports-icons/clash-royale.svg';
import fifa from '../../assets/sports-icons/fifa.svg';
import valorant from '../../assets/sports-icons/valorant.svg';
import lol from '../../assets/sports-icons/lol.svg';

const icons = [
  [athletics, 'athletics'],
  [basketball, 'basketball'],
  [beachVolleyball, 'beachVolleyball'],
  [football, 'football'],
  [futsal, 'futsal'],
  [handball, 'handball'],
  [judo, 'judo'],
  [swimming, 'swimming'],
  [tableTennis, 'tableTennis'],
  [tennis, 'tennis'],
  [volleyball, 'volleyball'],
  [chess, 'chess'],
  [csgo, 'csgo'],
  [clash, 'clash'],
  [fifa, 'fifa'],
  [valorant, 'valorant'],
  [lol, 'lol'],
];

function ModalidadePage() {
  const formRef = useRef<FormHandles>(null);
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { useIndividual, useAtleticas } = useData();
  const { individuals, updateIndividual,  } = useIndividual;

  const [individual, setIndividual] = useState({} as IIndividuals);
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModalSelectModalidade, setShowModalSelectModalidade] =
    useState(false);
  const [showModalRanking, setShowModalRanking] = useState(false);
  const [showDesempate, setShowDesempate] = useState(false);
  const [finishedTemp, setFinishedTemp] = useState<string[]>([])

  useEffect(() => {
    if(individual?.finished){
      setFinishedTemp(['finished'])
    }
  }, [individual])

  const toggleShowModalSelectModalidade = useCallback(() => {
    setShowModalSelectModalidade(!showModalSelectModalidade);
  }, [showModalSelectModalidade]);

  const toggleShowDesempate = useCallback(() => {
    setShowDesempate(!showDesempate);
  }, [showDesempate]);

  const toggleShowModalRanking = useCallback(() => {
    setShowModalRanking(!showModalRanking);
  }, [showModalRanking]);

  const toggleShowModalCategory = useCallback(() => {
    setShowModalCategory(!showModalCategory);
  }, [showModalCategory]);

  useEffect(() => {
    const pathname = location.pathname.split('/');
    const id = pathname[pathname.length - 1];
    if (individuals.length > 0) {
      const findIndividual = individuals.find(item => item.id === id);
      if (findIndividual) {
        setIndividual(findIndividual);
      } else {
        navigate('/individuais');
      }
    }
  }, [individuals, location.pathname, navigate]);

  function getIcons(value: string) {
    const icon = icons.find((item: any[]) => item[1] === value);
    return icon ? icon[0] : undefined;
  }

  const handleChangeChecked = useCallback(
    async (value: string) => {
      if (finishedTemp?.includes(value)) {
        setFinishedTemp([])
        updateIndividual({
          ...individual,
          finished: false
        })
      } else {
        setFinishedTemp(['finished'])
        updateIndividual({
          ...individual,
          finished: true
        })
      }
    },
    [finishedTemp, individual, updateIndividual],
  );

  return (
    <Container>
      {individual.id ? (
        <>
          <Header>
            <div>
              <div>
                <img src={getIcons(individual.icon) || logo} alt="icon" />
                <h1>{`${individual.name} ${capitalize(individual.type)}`}</h1>
              </div>
              <div>
                {!isAtletica && (
                  <>
                    <AddNew onClick={toggleShowModalCategory}>
                      <FiPlus />
                      {widthScreen > 820 && 'Categoria'}
                    </AddNew>
                    <AddNew onClick={toggleShowDesempate}>
                      <FiList />
                      {widthScreen > 820 && 'Crit. desempate'}
                    </AddNew>
                  </>
                )}
                <AddNew onClick={toggleShowModalRanking}>
                  <FiAward />
                  {widthScreen > 820 && 'Classificação'}
                </AddNew>
                <AddNew onClick={toggleShowModalSelectModalidade}>
                  <FiRepeat />
                  {widthScreen > 820 && 'Sel. individual'}
                </AddNew>
                {!isAtletica && (
                  <Form onSubmit={() => {}} ref={formRef}>
                    <Checkbox
                      name="finished"
                      value="finished"
                      containerStyle={{ marginTop: 8 }}
                      checked={finishedTemp?.includes(
                        'finished',
                      )}
                      onChange={e => handleChangeChecked(e.target.value)}
                    >
                      <div>Modalidade finalizada</div>
                    </Checkbox>
                  </Form>
                )}
              </div>
            </div>
            <div className="button" onClick={() => navigate('/individuais')}>
              <FiArrowLeft />
              {widthScreen > 820 && 'Voltar'}
            </div>
          </Header>
          <Content>
            {individual.categories?.map(category => (
              <Category
                individual={individual}
                category={category}
                key={category.id}
                atleticas={useAtleticas.atleticas}
                isAtletica={isAtletica}
              />
            ))}
          </Content>
          <ModalCategory
            individual={individual}
            showModalOpen={showModalCategory}
            toggleShowModalOpen={toggleShowModalCategory}
          />
          <ModalRanking
            individual={individual}
            showModalOpen={showModalRanking}
            toggleShowModalOpen={toggleShowModalRanking}
          />
          <ModalDesempate
            individual={individual}
            showModalOpen={showDesempate}
            toggleShowModalOpen={toggleShowDesempate}
          />
        </>
      ) : (
        <div />
      )}
      <SelectModalidade
        showModalOpen={showModalSelectModalidade}
        toggleShowModalOpen={toggleShowModalSelectModalidade}
        initialPage="Individual"
      />
    </Container>
  );
}

export default ModalidadePage;
