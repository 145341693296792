import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { INotification } from "../../types";

export async function auxAddNotification(
  notification: Omit<INotification, 'id'>,
  setNotifications: SetterOrUpdater<INotification[]>,
  state: INotification[]
) {
  try {
    api.post('/notification', notification).then(response => {
      setNotifications([response.data, ...state]);
    })
  } catch (e) {
    console.log(e)
  }
}