/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { Form } from '@unform/web';
import { differenceInMinutes, endOfDay, endOfYear, format, isAfter, isBefore, startOfDay, startOfYear } from 'date-fns';
import produce from 'immer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiDownload } from 'react-icons/fi';
import Select from '../../components/Select';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IMatch, IQualifier } from '../../types';
import ModalMatchBox from '../Playoff/components/ModalMatchBox';

import {
  Container,
  Header,
  SubHeader,
  Content,
  ScheduleBox,
  ScheduleLine,
  ScheduleHeader,
  IndBox,
  IndHeader,
  IndLine
} from './styles';
import Button from '../../components/Button';
import { downloadImage } from '../../utils/downloadImage';
import DatePicker from '../../components/DatePicker';
import { ptBR } from 'date-fns/locale';
import Checkbox from '../../components/Checkbox';

interface SelectProps {
  label: string;
  value: any;
}

function Schedule() {
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const { useAtleticas, useQualifier, useSettings, useIndividual } = useData();
  const { atleticas } = useAtleticas;
  const { qualifiers } = useQualifier;
  const { individuals } = useIndividual;
  const { settings } = useSettings;

  const widthScreen = window.innerWidth;

  const [isDownloading, setIsDownloading] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [selectedAtletica, setSelectedAtletica] = useState({
    label: 'Todas',
    value: 'all',
  });
  const [selectedRep, setSelectedRep] = useState({
    label: 'Todas',
    value: 'all',
  });
  const [selectedModalidade, setSelectedModalidade] = useState({
    label: 'Todas',
    value: 'all',
  });
  const [selectedType, setSelectedType] = useState({
    label: 'Todas',
    value: 'all',
  });
  const [selectedEtapa, setSelectedEtapa] = useState({
    label: 'Todas',
    value: 'all',
  });
  const [optionsAtletica, setOptionsAtletica] = useState<SelectProps[]>([]);
  const [optionsModalidades, setOptionsModalidades] = useState<SelectProps[]>(
    [],
  );
  const [optionsType, setOptionsType] = useState<SelectProps[]>([]);
  const [qualifiersTemp, setQualifiersTemp] = useState<IQualifier[]>([]);

  const [selectedMatch, setSelectedMatch] = useState<IMatch>({} as IMatch);
  const [selectedQualifier, setSelectedQualifier] = useState<IQualifier>(
    {} as IQualifier,
  );
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState<[Date | null, Date | null]>([startOfYear(new Date()), endOfYear(new Date())]);
  const [hiddenSeed, setHiddenSeed] = useState<string[]>(['hiddenSeed']);

  const handleChangeChecked = useCallback((value: string) => {
    if(hiddenSeed.includes(value)){
      setHiddenSeed(hiddenSeed.filter(item => item !== value))
    }else{
      setHiddenSeed([...hiddenSeed, value])
    }
  }, [hiddenSeed]);

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  useEffect(() => {
    const createOptions = atleticas?.map(aaa => ({
      label: aaa.nickname,
      value: aaa.id,
    }));
    setOptionsAtletica([{ label: 'Todas', value: 'all' }, ...createOptions]);
  }, [atleticas]);

  useEffect(() => {
    const createOptions = qualifiers?.map(qualifier => ({
      label: qualifier.name,
      value: qualifier.id,
    }));
    setOptionsModalidades([{ label: 'Todas', value: 'all' }, ...createOptions]);
  }, [qualifiers]);

  useEffect(() => {
    if (settings.type) {
      setOptionsType([{ label: 'Todas', value: 'all' }, ...settings?.type]);
    }
  }, [settings]);

  useEffect(() => {
    let qualiTemp = qualifiers.slice();

    const sorterdQualifier = qualiTemp.map(item => {
      const sortedMatches = sortedMatch(item.matches);
      return { ...item, matches: sortedMatches };
    });

    qualiTemp = sorterdQualifier;

    if (selectedAtletica.value !== 'all') {
      const filteredQualifiers = produce(qualiTemp, draft => {
        draft.forEach((item, index) => {
          const filteredMatches = item.matches.filter(
            match =>
              match.atletica_id1 === selectedAtletica.value ||
              match.atletica_id2 === selectedAtletica.value,
          );
          draft[index].matches = filteredMatches;
        });
        return draft;
      });
      qualiTemp = filteredQualifiers;
    }

    if (selectedRep.value !== 'all') {
      const filteredQualifiers = produce(qualiTemp, draft => {
        draft.forEach((item, index) => {
          const filteredMatches = item.matches.filter(
            match =>
              match.rep_id1 === selectedRep.value ||
              match.rep_id2 === selectedRep.value,
          );
          draft[index].matches = filteredMatches;
        });
        return draft;
      });
      qualiTemp = filteredQualifiers;
    }

    if (selectedModalidade.value !== 'all') {
      qualiTemp = qualiTemp.filter(
        item => item.id === selectedModalidade.value,
      );
    }

    if (selectedType.value !== 'all') {
      qualiTemp = qualiTemp.filter(item => item.type === selectedType.value);
    }

    if (selectedEtapa.value !== 'all') {
      const filteredQualifiers = produce(qualiTemp, draft => {
        draft.forEach((item, index) => {
          const filteredMatches = item.matches.filter(
            match => getEtapa(match.number) === selectedEtapa.value,
          );
          draft[index].matches = filteredMatches;
        });
        return draft;
      });

      qualiTemp = filteredQualifiers;
    }

    if(date.length === 2 && date[0] && date[1]){

      const filteredQualifiers = produce(qualiTemp, draft => {
        draft.forEach((item, index) => {
          const filteredMatches = item.matches.filter(
            match => isAfter(new Date(match.startDate), startOfDay(date[0] || new Date())) && isBefore(new Date(match.startDate), endOfDay(date[1] || new Date()))
          );
          draft[index].matches = filteredMatches;
        });
        return draft;
      });

      qualiTemp = filteredQualifiers
    }

    if(hiddenSeed.includes('hiddenSeed')){
      const filteredQualifiers = produce(qualiTemp, draft => {
        draft.forEach((item, index) => {
          const filteredMatches = item.matches.filter(
            match => match.atletica_id1 && match.atletica_id2
          );
          draft[index].matches = filteredMatches;
        });
        return draft;
      });

      qualiTemp = filteredQualifiers
    }

    setQualifiersTemp(qualiTemp);
  }, [
    selectedRep,
    qualifiers,
    selectedModalidade,
    selectedAtletica,
    selectedType,
    selectedEtapa,
    date,
    hiddenSeed
  ]);

  function getEtapa(number: number) {
    if (number <= 8) {
      return 'Oitavas';
    }
    if (number <= 12 && number > 8) {
      return 'Quartas';
    }
    if (number <= 14 && number > 12) {
      return 'Semi';
    }
    return 'Final';
  }

  function getScore(match: IMatch) {
    const { score1 } = fixScore(match);
    const { score2 } = fixScore(match);

    let point1 = 0;
    let point2 = 0;

    score1.forEach((score, index) => {
      const result = score - score2[index];
      if (result > 0) {
        point1 += 1;
      }
      if (result < 0) {
        point2 += 1;
      }
    });

    return {
      score1: point1,
      score2: point2,
    };
  }

  function fixScore(match: IMatch) {
    const count1 = match.score1.length;
    const count2 = match.score2.length;

    if (count1 === 0 && count2 === 0) {
      return {
        score1: [0],
        score2: [0],
      };
    }

    if (count1 > count2) {
      const offset = count1 - count2;
      return {
        score1: match.score1,
        score2: [...match.score2, ...Array(offset).fill(0)],
      };
    }

    if (count2 > count1) {
      const offset = count2 - count1;
      return {
        score1: [...match.score1, ...Array(offset).fill(0)],
        score2: match.score2,
      };
    }

    return {
      score1: match.score1,
      score2: match.score2,
    };
  }

  function sortedMatch(matches: IMatch[]) {
    const oitavas = matches.filter(match => match.number <= 8);
    const quartas = matches.filter(
      match => match.number > 8 && match.number <= 12,
    );
    const semi = matches.filter(
      match => match.number > 12 && match.number <= 14,
    );
    const final = matches.filter(match => match.number === 15);

    const oitavasFinal = oitavas.sort((a, b) => {
      return differenceInMinutes(new Date(a.startDate), new Date(b.startDate));
    });

    const quartasFinal = quartas.sort((a, b) => {
      return differenceInMinutes(new Date(a.startDate), new Date(b.startDate));
    });

    const semiFinal = semi.sort((a, b) => {
      return differenceInMinutes(new Date(a.startDate), new Date(b.startDate));
    });

    return [...oitavasFinal, ...quartasFinal, ...semiFinal, ...final];
  }

  const handleMatchBoxModal = useCallback(
    (match: IMatch, qualifier: IQualifier) => {
      setSelectedMatch(match);
      setSelectedQualifier(qualifier);
      toggleShowModal();
    },
    [toggleShowModal],
  );

  const toggleShowFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleDownload = useCallback(async (saveAs: 'jpg' | 'pdf') => {
    setIsDownloading(true);
    const boxIndividuals = document?.getElementById('');
    if(boxIndividuals){
      await downloadImage({
        ele: boxIndividuals,
        name: 'Individuais',
        saveAs,
        orientation: 'landscape'
      })
    }
    for(const quali of qualifiersTemp){
      const box = document?.getElementById(`box-${quali.id}`);
      if(box){        
        await downloadImage({
          ele: box,
          options: {
            logging: true,
            useCORS: true,
          },
          name: `${quali.name}-${quali.type}${selectedEtapa.value !== 'all' ? `-${selectedEtapa.label}` : ''}`,
          saveAs,
          orientation: 'landscape'
        })
      }
    }
    setIsDownloading(false)
  }, [qualifiersTemp, selectedEtapa]);

  const handleDateChange = useCallback((date: Date | [Date | null, Date | null] | null) => {
    if(Array.isArray(date)){
      setDate(date)
    }
  }, []);
  
  return (
    <Container>
      <Header>
        <h1>Programação</h1>
        <SubHeader>
          {widthScreen <= 820 && (
            <div>
              <b>Filtros:</b>
              <div onClick={toggleShowFilters}>
                {showFilters ? <FiChevronDown /> : <FiChevronUp />}
              </div>
            </div>
          )}
          {showFilters && (
            <Form onSubmit={() => {}}>
              <Select
                label="Atlética:"
                name="atletica"
                labelPosition={'top'}
                value={selectedAtletica}
                options={optionsAtletica}
                onChange={e => setSelectedAtletica(e)}
              />
              <Select
                label="Representação:"
                name="rep"
                labelPosition={'top'}
                value={selectedRep}
                options={optionsAtletica}
                onChange={e => setSelectedRep(e)}
              />
              <Select
                label="Modalidade:"
                name="modalidade"
                labelPosition={'top'}
                value={selectedModalidade}
                options={optionsModalidades}
                onChange={e => setSelectedModalidade(e)}
              />
              <Select
                label="Tipo:"
                name="type"
                labelPosition={'top'}
                value={selectedType}
                options={optionsType}
                onChange={e => setSelectedType(e)}
              />
              <Select
                label="Etapa:"
                name="etapa"
                labelPosition={'top'}
                value={selectedEtapa}
                options={[
                  { label: 'Todas', value: 'all' },
                  { label: 'Oitavas', value: 'Oitavas' },
                  { label: 'Quartas', value: 'Quartas' },
                  { label: 'Semi', value: 'Semi' },
                  { label: 'Final', value: 'Final' },
                ]}
                onChange={e => setSelectedEtapa(e)}
              />
              <DatePicker
                label="Data"
                defaultDate={new Date()}
                containerInputStyle={{ minWidth: '160px' }}
                locale={ptBR}
                name="date"
                dateFormat="dd/MM"
                onChange={handleDateChange}
                value={date[0]}
                startDate={date[0]}
                endDate={date[1]}
                selectsRange
              />
              <Checkbox
                name="hiddenSeed"
                value="hiddenSeed"
                containerStyle={{ marginTop: 8 }}
                checked={hiddenSeed?.includes(
                  'hiddenSeed',
                )}
                onChange={e => handleChangeChecked(e.target.value)}
              >
                <div className='whitespace-nowrap'>Esconder chapeu</div>
              </Checkbox>
              <Button
                buttonStyle='primary'
                theme='solid'
                style={{
                  marginTop: 0,
                  width: '100px'
                }}
                onClick={() => handleDownload('jpg')}
                loading={isDownloading}
              >
                <FiDownload
                  style={{ marginRight: '8px'}}
                />
                JPG
              </Button>
              <Button
                buttonStyle='primary'
                theme='solid'
                style={{
                  marginTop: 0,
                  width: '100px'
                }}
                onClick={() => handleDownload('pdf')}
                loading={isDownloading}
              >
                <FiDownload
                  style={{ marginRight: '8px'}}
                />
                PDF
              </Button>
            </Form>
          )}
        </SubHeader>
      </Header>
      <Content>
        {individuals.length > 0 && (
          <IndBox id='box-individuals'>
            <IndHeader>
              <header>Sem chaveamento</header>
              <div>
                <div className="mod">Modalidade</div>
                <div className="date">Data</div>
                <div className="hour">Horário</div>
                <div className="place">Local</div>
              </div>
            </IndHeader>
            {individuals.map((indiv: any) => (
              <IndLine>
                <div className="mod">{`${indiv.name} ${indiv.type}`}</div>
                <div id="date" className="date">
                  {indiv.startDate
                    ? format(new Date(indiv.startDate), 'dd/MM')
                    : ' - '}
                </div>
                <div id="hour" className="date">
                  {indiv.startDate
                    ? format(new Date(indiv.startDate), 'HH:mm')
                    : ' - '}
                </div>
                <div id="place" className="place">
                  {indiv?.place[0]?.name || ' - '}
                </div>
              </IndLine>
            ))}
          </IndBox>
        )}
        {qualifiersTemp.map(qualifier => (
          <ScheduleBox id={`box-${qualifier.id}`}>
            <header>{`${qualifier.name} ${qualifier.type}`}</header>
            <div>
              <ScheduleHeader>
                <div className="etapa">Etapa</div>
                <div className="match">Partida</div>
                <div className="date">Data</div>
                <div className="hour">Horário</div>
                <div className="place">Local</div>
                <div className="rep">Representação</div>
              </ScheduleHeader>
              {qualifier.matches.map(match => (
                <ScheduleLine
                  etapa={getEtapa(match.number)}
                  onClick={() =>
                    isAtletica ? {} : handleMatchBoxModal(match, qualifier)
                  }
                >
                  <div id="etapa" className="etapa">
                    {getEtapa(match.number)}
                  </div>
                  <div id="match" className="match">
                    {match?.atletica1 ? (
                      <div>
                        {match?.atletica1[0]?.avatar && (
                          <img src={match?.atletica1[0]?.avatar} alt="icon" />
                        )}
                        <div>{match?.atletica1[0]?.nickname || ' - '}</div>
                      </div>
                    ) : (
                      <div>{' - '}</div>
                    )}
                    {match?.atletica2 ? (
                      <div>
                        {match?.atletica2[0]?.avatar && (
                          <img src={match?.atletica2[0]?.avatar} alt="icon" />
                        )}
                        <div>{match?.atletica2[0]?.nickname || ' - '}</div>
                      </div>
                    ) : (
                      <div>{' - '}</div>
                    )}
                  </div>
                  <div id="score" className="score">
                    <div>
                      <div>{getScore(match).score1}</div>
                    </div>
                    <div>
                      <div>{getScore(match).score2}</div>
                    </div>
                  </div>
                  <div id="date" className="date">
                    {match.startDate
                      ? format(new Date(match.startDate), 'dd/MM')
                      : ' - '}
                  </div>
                  <div id="hour" className="date">
                    {match.startDate
                      ? format(new Date(match.startDate), 'HH:mm')
                      : ' - '}
                  </div>
                  <div id="place" className="place">
                    {match?.place[0]?.name}
                  </div>
                  <div id="rep" className="rep">
                    <div>
                      {match?.rep1 ? (
                        <div>{match?.rep1[0]?.nickname || ' - '}</div>
                      ) : (
                        <div>{' - '}</div>
                      )}
                    </div>
                    <div>
                      {match?.rep2 ? (
                        <div>{match?.rep2[0]?.nickname || ' - '}</div>
                      ) : (
                        <div>{' - '}</div>
                      )}
                    </div>
                  </div>
                </ScheduleLine>
              ))}
            </div>
          </ScheduleBox>
        ))}
      </Content>
      <ModalMatchBox
        match={selectedMatch}
        qualifier={selectedQualifier}
        showModalOpen={showModal}
        toggleShowModalOpen={toggleShowModal}
      />
    </Container>
  );
}

export default Schedule;
