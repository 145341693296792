/* eslint-disable jsx-a11y/no-static-element-interactions */
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FiEdit, FiTrash2, FiPlus, FiDownload } from 'react-icons/fi';
import { Column } from 'react-table';
import { useRecoilValue } from 'recoil';
import { Fines } from '../../../../Atoms';
import { Avatar } from '../../../../components/Avatar';
import { ModalWaring } from '../../../../components/ModalWaring';
import Table from '../../../../components/Table';
import { useAuth } from '../../../../hooks/auth';
import { useData } from '../../../../hooks/context';
import { IFine } from '../../../../types';
import { IconContainer, AddNew, Header } from '../../styles';
import { ModalFine } from '../ModalFine';
import { ModalFineEdit } from '../ModalFineEdit';


export function Fine() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showFineModal, setShowFineModal] = useState(false);
  const [showFineModalEdit, setShowFineModalEdit] = useState(false);
  const [showFineModalDelete, setShowFineModalDelete] = useState(false);
  const [editFine, setEditFine] = useState({} as IFine);
  const [deleteFine, setDeleteFine] = useState('');

  const { useFines } = useData();
  const fines = useRecoilValue(Fines);

  const toggleShowModalOpenFineEdit = useCallback(() => {
    setShowFineModalEdit(!showFineModalEdit);
  }, [showFineModalEdit]);

  const toggleShowModalOpenFineDelete = useCallback(() => {
    setShowFineModalDelete(!showFineModalDelete);
  }, [showFineModalDelete]);

  const delFine = useCallback(() => {
    useFines.removeFine(deleteFine);
    toggleShowModalOpenFineDelete();
  }, [deleteFine, toggleShowModalOpenFineDelete, useFines]);

  const toggleShowModalOpenFine = useCallback(() => {
    setShowFineModal(!showFineModal);
  }, [showFineModal]);

  const handleEditFine = useCallback(
    (fineEdit: IFine) => {
      setEditFine(fineEdit);
      toggleShowModalOpenFineEdit();
    },
    [toggleShowModalOpenFineEdit],
  );

  const handleDeleteFine = useCallback(
    (fineId: string) => {
      toggleShowModalOpenFineDelete();
      setDeleteFine(fineId);
    },
    [toggleShowModalOpenFineDelete],
  );

  const columnsFines: Column<any>[] = useMemo(
    () =>
      isAtletica
        ? [
            {
              Header: 'Avatar',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Avatar
                      name={row.original?.atletica[0]?.nickname}
                      avatar={row.original?.atletica[0]?.avatar}
                    />
                  </div>
                );
              },
            },
            {
              Header: 'Atlética',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.atletica[0]?.name}
                  </div>
                );
              },
              label: 'Atlética',
            },
            {
              Header: 'Penalidade',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.penalty[0]?.name}
                  </div>
                );
              },
              label: 'Penalidade',
            },
            {
              Header: 'Pontos',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.penalty[0]?.point}
                  </div>
                );
              },
              label: 'Pontos',
            },
            {
              Header: 'Data',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {format(new Date(row.original?.date), 'dd/MM')}
                  </div>
                );
              },
              label: 'Data',
            },
          ]
        : [
            {
              Header: 'Avatar',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Avatar
                      name={row.original?.atletica[0]?.nickname}
                      avatar={row.original?.atletica[0]?.avatar}
                    />
                  </div>
                );
              },
            },
            {
              Header: 'Atlética',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.atletica[0]?.name}
                  </div>
                );
              },
              label: 'Atlética',
            },
            {
              Header: 'Penalidade',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.penalty[0]?.name}
                  </div>
                );
              },
              label: 'Penalidade',
            },
            {
              Header: 'Pontos',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.penalty[0]?.point}
                  </div>
                );
              },
              label: 'Pontos',
            },
            {
              Header: 'Data',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {format(new Date(row.original?.date), 'dd/MM')}
                  </div>
                );
              },
              label: 'Data',
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditFine(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeleteFine(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [handleDeleteFine, handleEditFine, isAtletica, widthScreen],
  );

  const [data, setData] = useState([] as any)

  useEffect(() => {
    let dataAux: any = [];
    fines.forEach((fine: any) => {
      dataAux.push({
        atletica: fine.atletica[0].nickname,
        ponto: fine.penalty[0]?.point,
        penalidade: fine.penalty[0]?.name
      })
    })
    setData(dataAux)
  }, [fines])

  return (
    <>
      <Header>
        <div>
          <h1>Penalidades</h1>
          {!isAtletica && (
            <>
              <AddNew onClick={toggleShowModalOpenFine}>
                <FiPlus />
                New
              </AddNew>
              <CSVLink style={{textDecoration: 'none'}} data={data}>
                <AddNew >
                  <FiDownload />
                  CSV
                </AddNew>
              </CSVLink>
            </>
          )}
        </div>
      </Header>
      <Table columns={columnsFines} data={fines} />
      <ModalFine
        showModalOpen={showFineModal}
        toggleShowModalOpen={toggleShowModalOpenFine}
      />
      <ModalFineEdit
        showModalOpen={showFineModalEdit}
        toggleShowModalOpen={toggleShowModalOpenFineEdit}
        fine={editFine}
      />
      <ModalWaring
        showModalOpen={showFineModalDelete}
        toggleShowModalOpen={toggleShowModalOpenFineDelete}
        title="Deletar penalidade"
        message="Tem certeza que deseja deletar essa penalidade?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenFineDelete}
        handleRightButton={delFine}
      />
    </>
  );
}
