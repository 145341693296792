import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--color-background);
  border-radius: 1rem;
  border: 1px solid var(--purple02);
  padding: 0.5rem 1rem;
  max-width: 8rem;
  color: var(--purple02);
  display: flex;
  align-items: center;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${props =>
    props.isFocused &&
    css`
      color: var(--purple01);
      border-color: var(--purple01);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--purple01);
    `}

    ${props =>
    props.isErrored &&
    css`
      border-color: var(--error);
    `}

  > input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--purple02);

    &::placeholder {
      color: var(--color-placeholder);
    }
  }

  p {
    margin-right: 1.5rem;
    color: var(--purple03);
  }

  > .secret-button {
    height: 2rem;
    margin-left: 1.5rem;
    cursor: pointer;
  }
`;

export const AlertError = styled(Tooltip)`
  height: 2rem;
  margin-left: 1.5rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--error);
    color: #fff;

    &::before {
      border-color: var(--error) transparent;
    }
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.75rem;
`;
