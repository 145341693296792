/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useData } from '../../../../hooks/context';
import { uploadPhotoAdmin } from '../../../../utils/uploadPhotoAdmin';
import Button from '../../../../components/Button';
import ImagePicker from '../../../../components/ImagePicker';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { IAtletica } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import FormContainer from '../../../../components/FormContainer';

interface ModalAtleticaProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  atletica: IAtletica;
}

interface AtleticaData extends IAtletica {
  password_confirmation: string;
}

export function ModalAtleticaEdit({
  showModalOpen,
  toggleShowModalOpen,
  atletica,
}: ModalAtleticaProps) {
  const formRef = useRef<FormHandles>(null);
  const [atleticaTemp, setAtleticaTemp] = useState(atletica);
  const { useAtleticas, loading } = useData();

  useEffect(() => {
    setAtleticaTemp(atletica);
  }, [atletica]);

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setAtleticaTemp({
          ...atleticaTemp,
          avatar: response,
        });
        return response;
      }
      return '';
    });
  }
  const handleSubmit = useCallback(
    async (data: AtleticaData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Apelido obrigatório'),
          fullName: Yup.string().required('Nome completo obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        useAtleticas.updateAtletica({
          ...data,
          id: atleticaTemp.id,
          avatar: atleticaTemp.avatar,
        });
        toast.success('Atlética editada com sucesso!');
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao editar Atletica');
      }
    },
    [atleticaTemp, toggleShowModalOpen, useAtleticas],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Atlética</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={atleticaTemp}
          >
            <FormContainer line="1fr 3fr">
              <div style={{ paddingBottom: '1rem' }}>
                <ImagePicker
                  uploadPhoto={handlePhoto}
                  image={atleticaTemp.avatar}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  alignItems: 'start',
                }}
              >
                <Input
                  name="fullName"
                  placeholder="A. A. A. Exatas - Unioeste Foz do Iguaçu"
                  label="Nome completo:"
                />
                <Input name="name" placeholder="Extas Unioeste" label="Nome:" />
                <FormContainer line="1fr 1fr">
                  <Input name="nickname" placeholder="Rino" label="Apelido:" />
                  <Input
                    name="city"
                    placeholder="Foz do Iguaçu"
                    label="Cidade:"
                  />
                </FormContainer>
              </div>
            </FormContainer>
            <Input name="email" placeholder="E-mail" label="E-mail:" />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
