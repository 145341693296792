import { useState, useMemo, useCallback } from 'react';
import { FiEdit, FiTrash2, FiPlus } from 'react-icons/fi';
import { Column } from 'react-table';
import { useRecoilValue } from 'recoil';
import { Penalties } from '../../../../Atoms';
import { ModalWaring } from '../../../../components/ModalWaring';
import Table from '../../../../components/Table';
import { useAuth } from '../../../../hooks/auth';
import { useData } from '../../../../hooks/context';
import { IPenalty } from '../../../../types';
import { IconContainer, AddNew, Header } from '../../styles';
import { ModalPenalty } from '../ModalPenalty';
import { ModalPenaltyEdit } from '../ModalPenaltyEdit';

export function Penalty() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showPenaltyModal, setShowPenaltyModal] = useState(false);
  const [showPenaltyModalEdit, setShowPenaltyModalEdit] = useState(false);
  const [showPenaltyModalDelete, setShowPenaltyModalDelete] = useState(false);
  const [editPenalty, setEditPenalty] = useState({} as IPenalty);
  const [deletePenalty, setDeletePenalty] = useState('');

  const { usePenalties } = useData();
  const penalties = useRecoilValue(Penalties);

  const toggleShowModalOpenEdit = useCallback(() => {
    setShowPenaltyModalEdit(!showPenaltyModalEdit);
  }, [showPenaltyModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowPenaltyModalDelete(!showPenaltyModalDelete);
  }, [showPenaltyModalDelete]);

  const handleEditPenalty = useCallback(
    (PenaltyEdit: IPenalty) => {
      toggleShowModalOpenEdit();
      setEditPenalty(PenaltyEdit);
    },
    [toggleShowModalOpenEdit],
  );

  const handleDeletePenalty = useCallback(
    (PenaltiesId: string) => {
      toggleShowModalOpenDelete();
      setDeletePenalty(PenaltiesId);
    },
    [toggleShowModalOpenDelete],
  );

  const delPenalty = useCallback(() => {
    usePenalties.removePenalty(deletePenalty);
    toggleShowModalOpenDelete();
  }, [deletePenalty, toggleShowModalOpenDelete, usePenalties]);

  const toggleShowModalOpen = useCallback(() => {
    setShowPenaltyModal(!showPenaltyModal);
  }, [showPenaltyModal]);

  const columnsPenalties: Column<any>[] = useMemo(
    () =>
      isAtletica
        ? [
            {
              Header: 'Nome',
              accessor: 'name',
              label: 'Nome',
            },
            {
              Header: 'Artigo',
              accessor: 'law',
              label: 'Artigo',
            },
            {
              Header: 'Pontos',
              accessor: 'point',
              label: 'Pontos',
            },
          ]
        : [
            {
              Header: 'Nome',
              accessor: 'name',
              label: 'Nome',
            },
            {
              Header: 'Artigo',
              accessor: 'law',
              label: 'Artigo',
            },
            {
              Header: 'Pontos',
              accessor: 'point',
              label: 'Pontos',
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditPenalty(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeletePenalty(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [isAtletica, widthScreen, handleEditPenalty, handleDeletePenalty],
  );

  return (
    <>
      <Header>
        <div>
          <h1>Estatuto</h1>
          {!isAtletica && (
            <AddNew onClick={toggleShowModalOpen}>
              <FiPlus />
              New
            </AddNew>
          )}
        </div>
      </Header>
      <Table columns={columnsPenalties} data={penalties} />
      <ModalPenalty
        showModalOpen={showPenaltyModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalPenaltyEdit
        showModalOpen={showPenaltyModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        editPenalty={editPenalty}
      />
      <ModalWaring
        showModalOpen={showPenaltyModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar artigo"
        message="Tem certeza que deseja deletar esse artigo?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delPenalty}
      />
    </>
  );
}
