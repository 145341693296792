import { Form } from '@unform/web';
import { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { useData } from '../../../../hooks/context';
import { store } from '../../../../types/store';
import capitalize from '../../../../utils/capitalize';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';

import {
    Container,
    Content,
    Header,
} from './styles'
import { FormHandles } from '@unform/core';
import FormContainer from '../../../../components/FormContainer';

interface ModalOrgProps {
    showModalOpen: boolean;
    toggleShowModalOpen: () => void;
}

interface SelectProps {
    value: any;
    label: string;
}

export function ModalOrg({ showModalOpen, toggleShowModalOpen }: ModalOrgProps) {

    const formRef = useRef<FormHandles>(null);
    const [responsibility, setResponsibility] = useState<SelectProps>({} as SelectProps);
    const { useAdmins, loading } = useData();

    const handleChange = useCallback((value: any) => {
        setResponsibility(value)
    }, [])

    const handleSubmit = useCallback(async (data: any) => {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string().required('Email obrigatório').email('Insira um email válido'),
            password: Yup.string().min(6, 'No mínimo 6 digitos'),
            password_confirmation: Yup.string()
                .min(6, 'No mínimo 6 digitos')
                .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        useAdmins.addAdmin({
            ...data,
            responsibility: responsibility.value
        });
        formRef.current?.reset();
        toggleShowModalOpen();
    }, [responsibility, toggleShowModalOpen, useAdmins])

    return (
        <Modal
            isOpen={showModalOpen}
            setIsOpen={toggleShowModalOpen}
        >
            <Container>
                <Header>
                    <h2>C.O.</h2>
                    <div onClick={toggleShowModalOpen}>
                        <FiX />
                    </div>
                </Header>
                <Content>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Input
                            name="name"
                            placeholder='Nome'
                            label='Nome:'
                        />
                        <Select
                            name='responsibility'
                            placeholder='Selecione o cargo'
                            label='Cargo:'
                            options={store.responsibility.map(item => ({ label: capitalize(item), value: item }))}
                            value={responsibility}
                            onChange={handleChange}
                        />
                        <Input
                            name="email"
                            placeholder='E-mail'
                            label='E-mail:'
                        />
                        <FormContainer line="1fr 1fr">
                            <Input
                                name="password"
                                placeholder='Senha'
                                label='Senha:'
                            />
                            <Input
                                name="confirmation_password"
                                placeholder='Confirmação da Senha'
                                label='Confirmação da Senha:'
                            />
                        </FormContainer>
                        <div className='buttons'>
                            <Button
                                buttonStyle='primary'
                                theme='hollow'
                                onClick={toggleShowModalOpen}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                buttonStyle='primary'
                                theme='solid'
                                loading={loading}
                            >
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </Content>
            </Container>
        </Modal>
    )
}