import { useCallback, useMemo, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Column } from 'react-table'
import { useRecoilValue } from 'recoil';
import { Admins } from '../../Atoms';
import { Avatar } from '../../components/Avatar';
import { ModalOrg } from './components/ModalOrg';
import { ModalOrgEdit } from './components/ModalOrgEdit';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IAdmin } from '../../types';
import capitalize from '../../utils/capitalize';

import {
  Container,
  IconContainer,
  Header,
  AddNew
} from './styles';

function Org() {

  const widthScreen = window.innerWidth
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showOrgModalEdit, setShowOrgModalEdit] = useState(false);
  const [showOrgModalDelete, setShowOrgModalDelete] = useState(false);
  const [editAdmin, setEditAdmin] = useState({} as IAdmin);
  const [deleteAdmin, setDeleteAdmin] = useState('');
  const { admin } = useAuth();
  const isAdmin = useMemo(() => admin?.responsibility === 'admin' || admin?.responsibility === 'presidente', [admin])
  const { useAdmins } = useData();
  const admins: IAdmin[] = useRecoilValue(Admins);

  const toggleShowModalOpenEdit = useCallback(() => {
    setShowOrgModalEdit(!showOrgModalEdit)
  }, [showOrgModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowOrgModalDelete(!showOrgModalDelete)
  }, [showOrgModalDelete]);

  const handleEditAdmin = useCallback((adminEdit: IAdmin) => {
    toggleShowModalOpenEdit()
    setEditAdmin(adminEdit)
  }, [toggleShowModalOpenEdit])

  const handleDeleteAdmin = useCallback((adminsId: string) => {
    toggleShowModalOpenDelete()
    setDeleteAdmin(adminsId)
  }, [toggleShowModalOpenDelete])

  const delAdmin = useCallback(() => {
    useAdmins.removeAdmin(deleteAdmin)
    toggleShowModalOpenDelete()
  }, [toggleShowModalOpenDelete, useAdmins, deleteAdmin])

  const columns: Column<any>[] = useMemo(() => !isAdmin ? (
    [
      {
        Header: 'Avatar',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Avatar
                name={row.original.name}
                avatar={row.original.avatar}
              />
            </div>
          )
        },
      },
      {
        Header: 'Nome',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {row.original.name}
            </div>
          )
        },
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {row.original.email}
            </div>
          )
        },
      },
      {
        Header: 'Cargo',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {capitalize(row.original.responsibility)}
            </div>
          )
        },
      },
    ]
  ) : (
    [
      {
        Header: 'Avatar',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Avatar
                name={row.original.name}
                avatar={row.original.avatar}
              />
            </div>
  
          )
        },
      },
      {
        Header: 'Nome',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {row.original.name}
            </div>
          )
        },
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {row.original.email}
            </div>
          )
        },
      },
      {
        Header: 'Cargo',
        Cell: ({ row }) => {
          return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {capitalize(row.original.responsibility)}
            </div>
          )
        },
      },
      {
        Header: 'Opções',
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <IconContainer type='edit' onClick={() => handleEditAdmin(row.original)}>
                <FiEdit />
                {widthScreen <= 820 && 'Editar'}
              </IconContainer>
              <IconContainer type='del' onClick={() => handleDeleteAdmin(row.original.id)}>
                <FiTrash2 />
                {widthScreen <= 820 && 'Deletar'}
              </IconContainer>
            </div>
            
          )
        },
      },
    ]
  )
  , [isAdmin, widthScreen, handleEditAdmin, handleDeleteAdmin]);

  const toggleShowModalOpen = useCallback(() => {
    setShowOrgModal(!showOrgModal)
  }, [showOrgModal]);

  return (
    <Container>
      <Header>
        <h1>Comissão organizadora</h1>
        {isAdmin && (
          <AddNew onClick={toggleShowModalOpen}>
            <FiPlus />
            New
          </AddNew>
        )}
      </Header>
      <Table
        columns={columns}
        data={admins}
      />
      <ModalOrg
        showModalOpen={showOrgModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalOrgEdit
        showModalOpen={showOrgModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        admin={editAdmin}
      />
      <ModalWaring
        showModalOpen={showOrgModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar admin"
        message='Tem certeza que deseja deletar esse admin?'
        textLeftButton='Cancelar'
        textRightButton='Deletar'
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delAdmin}
      />
    </Container>
  );
};

export default Org;
