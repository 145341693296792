import { checkWinner } from "../Atoms/modules/match";
import { IMatch, IQualifier } from "../types";


export function qualifierClass (qualifier: IQualifier) {
    let allAtleticas = qualifier?.atleticas?.map(aaa => ({
        atletica: aaa,
        ...getMatches(aaa.id, qualifier.matches)
    }))

    const getWinnerNumber = checkWinner(qualifier.matches[14]);
    if(getWinnerNumber !== 0){

        const winner = qualifier.matches[14][`atletica_id${getWinnerNumber === 1 ? 1 : 2}`];
        const vice = qualifier.matches[14][`atletica_id${getWinnerNumber === 1 ? 2 : 1}`];

        const findWinnerIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === winner);
        const findViceIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === vice);

        const winnerMatches = allAtleticas[findWinnerIndex]?.matchList || []
        const viceMatches = allAtleticas[findViceIndex]?.matchList || []

        if(findWinnerIndex > -1) allAtleticas[findWinnerIndex].score = allAtleticas[findWinnerIndex].score + 1

        const third = qualifier.matches[winnerMatches[2] - 1]?.atletica_id1 === winner ? qualifier.matches[winnerMatches[2] - 1]?.atletica_id2 : qualifier.matches[winnerMatches[2] - 1]?.atletica_id1;
        const fourth = qualifier.matches[viceMatches[2] - 1]?.atletica_id1 === vice ? qualifier.matches[viceMatches[2] - 1]?.atletica_id2 : qualifier.matches[viceMatches[2] - 1]?.atletica_id1;

        const findThirdIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === third);
        const findFourthIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === fourth);

        const thirdMatches = allAtleticas[findThirdIndex]?.matchList || []
        const fourthMatches = allAtleticas[findFourthIndex]?.matchList || []

        if(findThirdIndex > -1) allAtleticas[findThirdIndex].score = allAtleticas[findThirdIndex].score + 1

        const fifth =  qualifier.matches[winnerMatches[1] - 1]?.atletica_id1 === winner ? qualifier.matches[winnerMatches[1] - 1]?.atletica_id2 : qualifier.matches[winnerMatches[1] - 1]?.atletica_id1;
        const sixth =  qualifier.matches[viceMatches[1] - 1]?.atletica_id1 === vice ? qualifier.matches[viceMatches[1] - 1]?.atletica_id2 : qualifier.matches[viceMatches[1] - 1]?.atletica_id1;
        const seventh =  qualifier.matches[thirdMatches[1] - 1]?.atletica_id1 === third ? qualifier.matches[thirdMatches[1] - 1]?.atletica_id2 : qualifier.matches[thirdMatches[1] - 1]?.atletica_id1;
        const eighth =  qualifier.matches[fourthMatches[1] - 1]?.atletica_id1 === fourth ? qualifier.matches[fourthMatches[1] - 1]?.atletica_id2 : qualifier.matches[fourthMatches[1] - 1]?.atletica_id1;


        const findFifthIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === fifth);
        const findSixthIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === sixth);
        const findSeventhIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === seventh);
        const findEighthIndex = allAtleticas.findIndex(aaa => aaa.atletica.id === eighth);

        if(findFifthIndex > -1) allAtleticas[findFifthIndex].score = allAtleticas[findFifthIndex].score + 3
        if(findSixthIndex > -1) allAtleticas[findSixthIndex].score = allAtleticas[findSixthIndex].score + 2
        if(findSeventhIndex > -1) allAtleticas[findSeventhIndex].score = allAtleticas[findSeventhIndex].score + 1

        const fifthMatches = findFifthIndex > -1 ? allAtleticas[findFifthIndex]?.matchList : [];
        const sixthMatches = findSixthIndex > -1 ? allAtleticas[findSixthIndex]?.matchList : [];
        const seventhMatches = findSeventhIndex > -1 ? allAtleticas[findSeventhIndex]?.matchList : [];
        const eighthMatches = findEighthIndex > -1 ? allAtleticas[findEighthIndex]?.matchList : [];

        let p9 = '';
        let p10 = '';
        let p11 = '';
        let p12 = '';
        let p13 = '';
        let p14 = '';
        let p15 = '';
        let p16 = '';
        
        if(winnerMatches.length > -1){
            p9 = qualifier?.matches[winnerMatches[0] - 1]?.atletica_id1 === winner ? qualifier?.matches[winnerMatches[0] - 1]?.atletica_id2 : qualifier?.matches[winnerMatches[0] - 1]?.atletica_id1;
        }
        if(viceMatches.length > -1){
            p10 =  qualifier?.matches[viceMatches[0] - 1]?.atletica_id1 === vice ? qualifier?.matches[viceMatches[0] - 1]?.atletica_id2 : qualifier?.matches[viceMatches[0] - 1]?.atletica_id1;
        }
        if(thirdMatches.length > -1){
            p11 =  qualifier?.matches[thirdMatches[0] - 1]?.atletica_id1 === third ? qualifier?.matches[thirdMatches[0] - 1]?.atletica_id2 : qualifier?.matches[thirdMatches[0] - 1]?.atletica_id1;
        }
        if(fourthMatches.length > -1){
            p12 =  qualifier?.matches[fourthMatches[0] - 1]?.atletica_id1 === fourth ? qualifier?.matches[fourthMatches[0] - 1]?.atletica_id2 : qualifier?.matches[fourthMatches[0] - 1]?.atletica_id1;
        }
        if(fifthMatches.length > -1){
            p13 =  qualifier?.matches[fifthMatches[0] - 1]?.atletica_id1 === fifth ? qualifier?.matches[fifthMatches[0] - 1]?.atletica_id2 : qualifier?.matches[fifthMatches[0] - 1]?.atletica_id1;
        }
        if(sixthMatches.length > -1){
            p14 =  qualifier?.matches[sixthMatches[0] - 1]?.atletica_id1 === sixth ? qualifier?.matches[sixthMatches[0] - 1]?.atletica_id2 : qualifier?.matches[sixthMatches[0] - 1]?.atletica_id1;
        }
        if(seventhMatches.length > -1){
            p15 =  qualifier?.matches[seventhMatches[0] - 1]?.atletica_id1 === seventh ? qualifier?.matches[seventhMatches[0] - 1]?.atletica_id2 : qualifier?.matches[seventhMatches[0] - 1]?.atletica_id1;
        }
        if(eighthMatches.length > -1){
            p16 =  qualifier?.matches[eighthMatches[0] - 1]?.atletica_id1 === eighth ? qualifier?.matches[eighthMatches[0] - 1]?.atletica_id2 : qualifier?.matches[eighthMatches[0] - 1]?.atletica_id1;
        }

        const findP9Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p9);
        const findP10Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p10);
        const findP11Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p11);
        const findP12Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p12);
        const findP13Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p13);
        const findP14Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p14);
        const findP15Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p15);
        const findP16Index = allAtleticas.findIndex(aaa => aaa.atletica.id === p16);

        if(findP9Index > -1) allAtleticas[findP9Index].score = allAtleticas[findP9Index].score + 8
        if(findP10Index > -1) allAtleticas[findP10Index].score = allAtleticas[findP10Index].score + 7
        if(findP11Index > -1) allAtleticas[findP11Index].score = allAtleticas[findP11Index].score + 6
        if(findP12Index > -1) allAtleticas[findP12Index].score = allAtleticas[findP12Index].score + 5
        if(findP13Index > -1) allAtleticas[findP13Index].score = allAtleticas[findP13Index].score + 4
        if(findP14Index > -1) allAtleticas[findP14Index].score = allAtleticas[findP14Index].score + 3
        if(findP15Index > -1) allAtleticas[findP15Index].score = allAtleticas[findP15Index].score + 2
        if(findP16Index > -1) allAtleticas[findP16Index].score = allAtleticas[findP16Index].score + 1

        const sortedAtleticas = allAtleticas.sort((a, b) => b.score - a.score)

        const formattedRank = sortedAtleticas.map((aaa, index) => (
            {
                ...aaa,
                point: qualifier.point.length >= (index + 1) ? qualifier.point[index] : 0
            }
        ))

        return formattedRank
    } else {
        return []
    }
}


function getMatches(id: string, matches: IMatch[]){
    let list: number[] = [];
    matches.forEach(m => {
        if(m.atletica_id1 === id || m.atletica_id2 === id){
            list.push(m.number)
        }
    })
    return {
        score: getBasePoint(list.length),
        matchList: list
    }
}

function getBasePoint(matchesLength: number){
    switch (matchesLength) {
        case 4:
            return 15
        case 3:
            return 13
        case 2:
            return 9   
        default:
            return 0
    }
}