import { useCallback, useMemo, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Column } from 'react-table';
import { useRecoilValue } from 'recoil';
import { Accommodation } from '../../Atoms';
import { Avatar } from '../../components/Avatar';
import { ModalAccommodation } from './components/ModalAccommodation';
import { ModalAccommodationEdit } from './components/ModalAccommodationEdit';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IAccommodation, IAtletica } from '../../types';

import { Container, IconContainer, Header, AddNew } from './styles';

function PageAccommodation() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showAccommodationModal, setShowAccommodationModal] = useState(false);
  const [showAccommodationModalEdit, setShowAccommodationModalEdit] = useState(false);
  const [showAccommodationModalDelete, setShowAccommodationModalDelete] = useState(false);
  const [editAccommodation, setEditAccommodation] = useState({} as IAccommodation);
  const [deleteAccommodation, setDeleteAccommodation] = useState('');
  const { useAccommodations } = useData();
  const accommodation = useRecoilValue(Accommodation);

  const toggleShowModalOpenEdit = useCallback(() => {
    setShowAccommodationModalEdit(!showAccommodationModalEdit);
  }, [showAccommodationModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowAccommodationModalDelete(!showAccommodationModalDelete);
  }, [showAccommodationModalDelete]);

  const handleEditAtletica = useCallback(
    (atleticaEdit: IAccommodation) => {
      toggleShowModalOpenEdit();
      setEditAccommodation(atleticaEdit);
    },
    [toggleShowModalOpenEdit],
  );

  const handleDeleteAtletica = useCallback(
    (accommodationId: string) => {
      toggleShowModalOpenDelete();
      setDeleteAccommodation(accommodationId);
    },
    [toggleShowModalOpenDelete],
  );

  const delAccommodation = useCallback(() => {
    useAccommodations.removeAccommodation(deleteAccommodation);
    toggleShowModalOpenDelete();
  }, [useAccommodations, deleteAccommodation, toggleShowModalOpenDelete]);

  const columns: Column<any>[] = useMemo(
    () =>
      isAtletica
        ? [
            {
              Header: 'Alojamento',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.place[0]?.name || ""}
                  </div>
                );
              },
            },
            {
              Header: 'Seguranças',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.security}
                  </div>
                );
              },
            },
            {
              Header: 'Banheiros',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.bathroom}
                  </div>
                );
              },
            },
            {
              Header: 'Salas',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.room}
                  </div>
                );
              },
            },
            {
              Header: 'Total',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.available}
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'Alojamento',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original?.place && (row.original?.place[0]?.name || "")}
                  </div>
                );
              },
            },
            {
              Header: 'Atléticas',
              Cell: ({ row }) => {
                return (
                  <div className='flex flex-col items-center w-full gap-2'>
                    {row.original.atleticas?.map((atletica: IAtletica) => (
                      <div className='flex flex-row flex-wrap gap-1 items-center'>
                        <Avatar
                          name={atletica.nickname}
                          avatar={atletica.avatar}
                          height='20px'
                          width='20px'
                        />
                        <div>{atletica.name}</div>
                      </div>
                    ))}
                  </div>
                  
                );
              },
            },
            {
              Header: 'Seguranças',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.security}
                  </div>
                );
              },
            },
            {
              Header: 'Banheiros',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.bathroom}
                  </div>
                );
              },
            },
            {
              Header: 'Salas',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.room}
                  </div>
                );
              },
            },
            {
              Header: 'Total',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.available}
                  </div>
                );
              },
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditAtletica(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeleteAtletica(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [isAtletica, handleEditAtletica, handleDeleteAtletica, widthScreen],
  );

  const toggleShowModalOpen = useCallback(() => {
    setShowAccommodationModal(!showAccommodationModal);
  }, [showAccommodationModal]);

  return (
    <Container>
      <Header>
        <h1>Alojamentos</h1>
        {!isAtletica && (
          <AddNew onClick={toggleShowModalOpen}>
            <FiPlus />
            New
          </AddNew>
        )}
      </Header>
      <Table columns={columns} data={accommodation} />

      <ModalAccommodation
        showModalOpen={showAccommodationModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalAccommodationEdit
        showModalOpen={showAccommodationModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        accommodation={editAccommodation}
      />
      <ModalWaring
        showModalOpen={showAccommodationModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar alojamento"
        message="Tem certeza que deseja deletar esse alojamento?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delAccommodation}
      />
    </Container>
  );
}

export default PageAccommodation;
