import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAtletica, IPlace, IQualifier } from "../../types";
import { produce } from 'immer'

export async function auxUpdateQualifier(
  qualifier: IQualifier,
  setQualifier: SetterOrUpdater<IQualifier[]>,
  state: IQualifier[],
  atleticas: IAtletica[],
) {
  try {
    api.put(`/qualifier/${qualifier.id}`, qualifier).then(response => {
      const index = state.findIndex(item => item.id === qualifier.id);
      const updatedQualifier = state.slice();
      const allAtleticas = response.data.atletica_ids.map((aaa_id: string) => {
        const aaa = atleticas.find(item => item.id === aaa_id);
        return aaa || {} as IAtletica
      })
      const newFine = {
        ...response.data,
        atleticas: allAtleticas,
      }
      updatedQualifier[index] = newFine;
      setQualifier(updatedQualifier);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemoveQualifier(qualifier_id: string, setQualifier: SetterOrUpdater<IQualifier[]>, state: IQualifier[]) {
  try {
    api.delete(`/qualifier/${qualifier_id}`).then(response => {
      const filteredQualifier = state.filter(qualifier => qualifier.id !== qualifier_id)
      setQualifier(filteredQualifier);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddQualifier(
  qualifier: Omit<IQualifier, 'id' | 'matches' | 'matches_ids' | 'atleticas'>,
  setQualifier: SetterOrUpdater<IQualifier[]>,
  state: IQualifier[],
  atleticas: IAtletica[],
  places: IPlace[],
) {
  try {
    api.post('/qualifier', qualifier).then(response => {
      const allAtleticas = response.data.atletica_ids.map((aaa_id: string) => {
        const aaa = atleticas.find(item => item.id === aaa_id);
        return aaa || {} as IAtletica
      })
      const newQualifier: IQualifier = {
        ...response.data,
        atleticas: allAtleticas,
      }

      const formattedQualifier = produce(newQualifier, (draft: IQualifier) => {
        const newMatches = draft.matches.map(item => {
          const findPlace = places.find(place => place.id === item.place_id);
          return {
            ...item,
            place: findPlace ? [findPlace] : []
          }
        })
        draft.matches = newMatches
      })
      setQualifier([formattedQualifier, ...state]);
    })
  } catch (e) {
    console.log(e)
  }
}