import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IPenalty } from "../../types";


export async function auxUpdatePenalty(penalty: IPenalty, setPenalties: SetterOrUpdater<IPenalty[]>, state: IPenalty[]) {
  try {
    api.put(`/penalty/${penalty.id}`, penalty).then(response => {
      const index = state.findIndex(item => item.id === penalty.id);
      const updatedPenalty = state.slice();
      updatedPenalty[index] = response.data;
      setPenalties(updatedPenalty);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemovePenalty(penalty_id: string, setPenalties: SetterOrUpdater<IPenalty[]>, state: IPenalty[]) {
  try {
    api.delete(`/penalty/${penalty_id}`).then(response => {
      const filteredPenalties = state.filter(penalty => penalty.id !== penalty_id)
      setPenalties(filteredPenalties);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddPenalty(penalty: Omit<IPenalty, 'id'>, setPenalties: SetterOrUpdater<IPenalty[]>, state: IPenalty[]) {
  try {
    api.post('/penalty', penalty).then(response => {
      setPenalties([...state, response.data]);
    })
  } catch (e) {
    console.log(e)
  }
}