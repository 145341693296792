import React, { useEffect, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Column } from 'react-table';
import Modal from '../../../../components/Modal';

import { Container, Header, Content } from './styles';
import Table from '../../../../components/Table';
import { IAtletica, IQualifier } from '../../../../types';
import { qualifierClass } from '../../../../utils/qualifierClass';

interface ModalRankingProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  qualifier: IQualifier;
}

interface RankProps {
  atletica: IAtletica;
  score: number;
  point?: number;
}

function ModalRanking({
  showModalOpen,
  toggleShowModalOpen,
  qualifier,
}: ModalRankingProps) {
  const [ranking, setRanking] = useState<RankProps[]>([]);

  useEffect(() => {
    if (qualifier?.matches) {
      setRanking(qualifierClass(qualifier));
    }
  }, [qualifier]);

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Atlética',
        Cell: ({ row }) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '1rem',
                marginLeft: '1rem',
              }}
            >
              {`${row.index + 1}º`}
              {row.original.atletica.avatar && (
                <img
                  style={{ width: '3rem', height: '3rem' }}
                  src={row.original.atletica.avatar}
                  alt="icon"
                />
              )}
              {row.original.atletica.name}
            </div>
          );
        },
      },
      {
        Header: 'Pontos',
        accessor: 'point',
        label: 'Pontos',
      },
    ],
    [],
  );

  return (
    <Modal
      isOpen={showModalOpen}
      setIsOpen={toggleShowModalOpen}
      maxWidth="480px"
    >
      <Container>
        <Header>
          <h2>Classificação final</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Table columns={columns} data={ranking} />
        </Content>
      </Container>
    </Modal>
  );
}

export default ModalRanking;
