import React from 'react';
import { Ranking } from './components/Ranking';

import { Container } from './styles';

function Classificacao() {

  return (
    <Container>
      <Ranking />
    </Container>
  );
}

export default Classificacao;
