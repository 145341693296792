import React from 'react';
import { Penalty } from './components/Penalty';

import { Container } from './styles';

function PageEstatuto() {

  return (
    <Container>
      <Penalty />
    </Container>
  );
}

export default PageEstatuto;
