import { useCallback, useState } from 'react';

import { Container, Box } from './styles';

import athletics from '../../assets/sports-icons/athletics.svg';
import basketball from '../../assets/sports-icons/basketball.svg';
import beachVolleyball from '../../assets/sports-icons/beach-volleyball.svg';
import football from '../../assets/sports-icons/football.svg';
import futsal from '../../assets/sports-icons/futsal.svg';
import handball from '../../assets/sports-icons/handball.svg';
import judo from '../../assets/sports-icons/judo.svg';
import swimming from '../../assets/sports-icons/swimming.svg';
import tableTennis from '../../assets/sports-icons/table-tennis.svg';
import tennis from '../../assets/sports-icons/tennis.svg';
import volleyball from '../../assets/sports-icons/volleyball.svg';
import chess from '../../assets/sports-icons/chess.svg';
import csgo from '../../assets/sports-icons/csgo.svg';
import clash from '../../assets/sports-icons/clash-royale.svg';
import fifa from '../../assets/sports-icons/fifa.svg';
import valorant from '../../assets/sports-icons/valorant.svg';
import lol from '../../assets/sports-icons/lol.svg';

const icons = [
  [athletics, 'athletics'],
  [basketball, 'basketball'],
  [beachVolleyball, 'beachVolleyball'],
  [football, 'football'],
  [futsal, 'futsal'],
  [handball, 'handball'],
  [judo, 'judo'],
  [swimming, 'swimming'],
  [tableTennis, 'tableTennis'],
  [tennis, 'tennis'],
  [volleyball, 'volleyball'],
  [chess, 'chess'],
  [csgo, 'csgo'],
  [clash, 'clash'],
  [fifa, 'fifa'],
  [valorant, 'valorant'],
  [lol, 'lol'],
];

interface SelectIconsProps {
  value?: string;
  onChange: (value: string) => void;
}

function SelectIcons({ onChange, value = '' }: SelectIconsProps) {
  const [valueTemp, setValueTemp] = useState(value);

  const handleChange = useCallback(
    (valueChange: string) => {
      onChange(valueChange);
      setValueTemp(valueChange);
    },
    [onChange],
  );

  return (
    <Container>
      {icons.map(item => (
        <Box
          key={item[1]}
          selected={item[1] === valueTemp}
          onClick={() => handleChange(item[1])}
        >
          <img src={item[0]} alt="icon" />
        </Box>
      ))}
    </Container>
  );
}

export default SelectIcons;
