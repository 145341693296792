import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 8rem);
  margin: 4rem auto;
  overflow-y: auto;

  .mapboxgl-popup-content {
    padding: 5px !important;
  }

  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
  gap: 1.5rem;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .show {
    color: var(--purple01);
    text-decoration: underline;
    cursor: pointer;
    
  }
`;

export const AddNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border: 2px solid var(--purple02);
  color: var(--purple02);
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;

  svg {
    margin-right: 0.5rem;
  }

  cursor: pointer;
`;

export const MapContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const FormContainer = styled.div`
  position: absolute;
  width: 35%;
  background: #fff;
  border-radius: 1rem;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  padding: 1.5rem 1rem;

  @media (max-width: 820px) {
    width: calc(100% - 2rem);
    left: 1rem;
  }
`;
