import { shade, lighten } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root{
    --color-background: #f9f9f9 ;
    --purple00: #240046;
    --purple01: #3B1467;
    --purple01-lighten-hover: ${lighten(0.04, '#3B1467')};
    --purple01-lighten: ${lighten(0.025, '#3B1467')};
    --purple01-shade: ${shade(0.2, '#3B1467')};
    --purple02: #522888;
    --purple03: #E5DEF0;
    --success: #8CFF99;
    --error: #DF7373;
    --waring: #FFC145;
    --success-shade: ${shade(0.2, '#8CFF99')};
    --error-shade: ${shade(0.2, '#DF7373')};
    --waring-shade: ${shade(0.2, '#FFC145')};
    --color-placeholder: #ccb;
    --color-button-text: #FFFFFF;

    font-size: 85%;

  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #636363;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #3d3d3d;
    }
    ::-webkit-scrollbar-track {
      background: #ffffff;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #e6e6e6;
    }
    ::-webkit-scrollbar-track:active {
      background: #e0e0e0;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  body {
    background: var(--color-background);
    color: var(--color-text-in-background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea {
    font-family: 'Poppins', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  @media( min-width: 700px){
      :root {
          font-size: 62.5%;
      }
  }


`;
